<template>
  <div class="menu-container align-items-center" :style="menuPosition">
    <div class="nav-wrapper">
      <img
        :style="menuItemPostion"
        class="little-m hoverable"
        src="../../static/images/Logo.png"
        alt="Spaces+"
        @click="navigateTo('home', 'mirk-navbar')"
      />
      <img
        :style="menuItemPostion"
        role="button"
        @click="toggleMenu"
        offset-hover-max="2"
        offset-hover-min="1"
        class="cross magnetic-button hoverable"
        src="/images/white_cross.png"
        alt="close button"
      />

    </div>
    <div class="projects-header-container" :style="menuOpacity">
      <div class="row ml-0 p-0">
        <div class="nav-container-menu col-10 p-0">
          <ul>
            <li
              :style="menuItemPostion"
              @click="navigateTo('home', 'features')"
              aria-label="features"
            >
              <div>
                <h3>Features</h3>
              </div>
            </li>
            <li
              :style="menuItemPostion"
              @click="navigateTo('home', 'packages')"
              aria-label="packages"
            >
              <div>
                <h3>Packages</h3>
              </div>
            </li>
            <li
              :style="menuItemPostion"
              @click="navigateTo('home', 'how-it-works')"
              aria-label="how-it-works"
            >
              <div>
                <h3>How It Works</h3>
              </div>
            </li>
            <li
              :style="menuItemPostion"
              @click="navigateTo('home', 'configurator')"
              aria-label="configurator"
            >
              <div>
                <h3>Find your Space</h3>
              </div>
            </li>
            <li
              :style="menuItemPostion"
              data-toggle="modal"
              data-target="#demoBooking"
            >
              <div>
                <h3>Book a Consultation</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-new",
  data() {
    return {
      dt: ""
    };
  },
  methods: {
    toggleMenu() {
      this.$store.state.showMenu = !this.$store.state.showMenu;
    },
    navigateTo(destination, data) {
      this.toggleMenu()
      // console.log("destination ", destination)
      // console.log("data ", data)
      this.$router.push({
        name: destination,
        params: {data: data}
      });
      let doc = document.getElementById(data)
      // console.log("doc ", doc)

      if(doc){
        // wait some time then scroll
        setTimeout(function() {
          const y = doc.getBoundingClientRect().top + window.scrollY;
          let height = window.screen.height;
          // offset % of the height
          let offsetHeight = height*.07
          window.scroll({
            top: y-offsetHeight
          });
        }.bind(this), 200)
      }
    }
  },
  mounted() {
    this.dt = new Date();
  },
  computed: {
    menuPosition() {
      // if (this.$store.state.showMenu) {
      //   return "transform: translateY(0)";
      // } else {
      //   return "transform: translateY(-100%)";
      // }

      if (this.$store.state.showMenu) {
        return "clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);display: block; opacity:1; visibility: visible; transform: matrix(1, 0, 0, 1, 0, 0);";
      } else {
        return "clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%); transition-delay: 1s";
      }
    },
    menuOpacity() {
      if (this.$store.state.showMenu) {
        return "opacity: 1";
      } else {
        return "opacity: 0";
      }
    },

    menuItemPostion() {
      if (this.$store.state.showMenu) {
        return "opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);";
      } else {
        return "opacity: 0; transform: translateY(-30px);";
      }
    }
  }
};
</script>

<style lang="less">
@import "../../static/styles/settings.less";

.menu-container {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: @spaces-plus-dark-blue;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.5s;
  overflow: hidden;
  max-height: 100vh;

  .nav-wrapper{
    width: 90%;
    max-width: 1600px;
    padding-top: 120px;
    margin: 0 auto;

    @media @mobile, @large-mobile, @iPad{
      padding-top: 40px;
    }
  }
  .little-m {
    transition: 0.3s;
    width: 140px;
    @media @mobile, @large-mobile {
      width: 140px;
    }
  }

  .cross {
    cursor: pointer;
    width: 25px;
    float: right;
    //margin-right: 5%;
    margin-right: 10px;
    margin-top: 15px;
    @media @mobile, @large-mobile {
      width: 15px;
      margin-top: 10px;
    }
  }

  .projects-header-container {
    display: block;
    /*margin: 10% 10% 5% 10%;*/
    box-sizing: border-box;
    width: 90%;
    max-width: 1600px;
    opacity: 0;
    -webkit-transition: opacity 2s ease;
    -moz-transition: opacity 2s ease;
    -o-transition: opacity 2s ease;
    transition: opacity 2s ease;
    margin: 0 auto;
    min-height: 83vh;
    /*margin-top: 7vh;*/
    @media @large-desktop {
      /*margin-top: 50px;*/
      /*margin-top: 17vh;*/

      display: flex;
      flex-direction: column;
      justify-content: center;
      /*padding: 0 100px;*/
      /*margin-right: auto;*/
      /*margin-left: auto;*/
    }

    @media @mobile, @large-mobile{
      margin-top: 0;
    }

    .nav-container-menu {
      display: block;
      margin-top: 60px;
      @media @large-desktop {
        margin-top: 80px;
      }

      ul {
        padding: 0;
        margin: 0;
        color: white;
      }

      ul > li {
        margin-bottom: 30px;
        list-style: none;
        cursor: pointer;
        color: @grey;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        transition-duration: .8s;

        @media @mobile, @large-mobile, @tablet {
          margin-bottom: 15px;
        }

        @media @large-desktop {
          margin-bottom: 30px;
        }

        h3 {
          color: white;
          margin-top: 0px;
          margin-bottom: 0px;
          -webkit-transition: color 0.2s ease;
          -moz-transition: color 0.2s ease;
          -o-transition: color 0.2s ease;
          transition: color 0.2s ease;

          @media @tablet {
            font-size: 35px;
          }
          @media @desktop {
            font-size: 45px;
          }
          @media @large-desktop {
            font-size: 45px;
          }

          &:hover {
            color: @spaces-plus-light-green;
          }

          @media @mobile {
            font-size: 24px;
            padding-top: 10px;
          }

          @media @large-mobile {
            font-size: 26px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }

        a {
          color: @grey;
          &:hover {
            text-decoration: none;
          }
        }

      }
    }
    ul > li:nth-child(1){
      transition-delay: .1s;
    }
    ul > li:nth-child(2){
      transition-delay: .2s;
    }
    ul > li:nth-child(3){
      transition-delay: .3s;
    }
    ul > li:nth-child(4){
      transition-delay: .4s;
    }
    ul > li:nth-child(5){
      transition-delay: .5s;
    }
    ul > li:nth-child(6){
      transition-delay: .6s;
    }
    .contact-info {
      margin-top: 60px;
      padding-left: 5vw;
      @media @mobile, @large-mobile {
        display: none;
      }
      @media @large-desktop {
        margin-top: 80px;
      }

      p {
        transition-duration: 0.8s;
        line-height: 23px;
        font-size: @content-font-size;
        font-family: "Lato-Regular"!important;

        .city-span {
          font-family: Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
        }
        @media @tablet {
          font-size: 1rem;
          line-height: 1.4rem;
        }
        font-size: 1.125rem;
        color: @black;
      }

      .got-a-project-wrapper {
        transition-duration: .8s;
        transition-delay: .4s;
        margin-top: 80px;
        @media @tablet {
          margin-top: 50px;
        }
        @media @large-desktop {
          margin-top: 8vh;
        }
        a:hover {
          text-decoration: none;
        }
        .lets-talk {
          font-family: Roboto, Helvetica, Arial, sans-serif;
          color: @black;
          font-size: 32px;
          @media @tablet {
            font-size: 22px;
          }
        }

        .arrow-right {
          color: @spaces-plus-light-green;
        }
      }
    }
    .menu-footer {
      transition: .8s;
      transition-delay: .7s;
      margin-top: 30px;
      @media @mobile, @large-mobile {
        display: none;
      }
      @media @tablet {
      }
      @media @large-desktop {
        margin-top: 3vh;
      }
    }
  }

  .year{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 13px;
    padding-left: 10px;
    @media @tablet {
      line-height: unset;
    }
  }

  .spaces-logo {
    padding: 15px 0px!important;
  }

  .spaces-logo-container-mobile {

    @media @large-desktop {
        display: none;
    }
    @media @desktop {
      display: none;
    }
    @media @tablet {
      display: none;
    }
    @media @iPad-pro {
      display: none;
    }
    @media @iPad {
      display: none;
    }

    @media @large-mobile {
      display: none;
    }
  }

}
</style>
