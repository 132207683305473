module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _vm._m(0)
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('section', {
    staticClass: "banner-text-container"
  }, [_c('div', {
    staticClass: "banner-text-wrapper"
  }, [_c('p', {
    staticClass: "banner-text"
  }, [_vm._v("Spaces+ is a platform that is secure, provides a seamless user experience, is rapidly deployed and can be used for regular events.")])])])
}]}