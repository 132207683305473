<template>
  <div class="process-wrapper">
    <div class="process-content">
      <h1>
        The Process.
      </h1>
      <p>
        Getting started with Spaces+ is easy!
      </p>
      <div class="process-logos">
        <img
          src="/images/process.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
      <div class="process-logos-mob">
        <img
          src="/images/process-mob.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'process',
    props: [""],
    data(){
      return{}
    },
    mounted () {

    }

}
</script>

<style lang="less" scoped>
@import "../../../static/styles/settings.less";

.process-wrapper{
  background: white;

  .process-content{
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 5% !important;

    @media @tablet {
    }

    @media @mobile, @large-mobile{
      width: 90%;
      padding-left: 0 !important;
    }

    @media @iPad, @small-desktop, @iPad-pro{
      width: 90%;
      padding-left: 0 !important;
    }
    h1{
      color: @spaces-plus-dark-blue;
      width: 100%;
      font-size: @title-font-size;
      line-height: @title-line-height;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 700;
      margin-top: 5vh;
      @media @mobile, @large-mobile, @tablet {
        font-size: @title-font-size-mobile;
        line-height: @title-line-height-mobile;
      }
    }

    p{
      color: @spaces-plus-dark-blue;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      max-width: 580px;
      @media @mobile, @large-mobile, @tablet {
        margin-bottom: 5vh;
      }
    }

    img{
      max-width: 100%;
      object-fit: cover;
      //margin-bottom: 10vh;
    }

    .process-logos{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @mobile, @large-mobile{
        display: none;
      }
    }
    .process-logos-mob{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @desktop, @large-desktop, @iPad, @tablet, @iPad-pro{
        display: none;
      }
    }
  }
}
</style>
