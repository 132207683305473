<template>
  <div class="cursor">
    <div class="cursor-ball cursor-ball-big">
      <svg height="20" width="20">
        <circle cx="10" cy="10" r="6" stroke-width="0" />
      </svg>
    </div>

    <!-- <div class="cursor-ball cursor-ball-small">
      <svg height="10" width="10">
        <circle cx="5" cy="5" r="4" stroke-width="0" />
      </svg>
    </div> -->
  </div>
</template>

<script>
import { TweenMax } from 'gsap/all';
import { setTimeout } from 'timers';
export default {
  name: "custom-cursor",
  mounted() {
    const bigBall = document.querySelector(".cursor-ball-big");
    // const smallBall = document.querySelector(".cursor-ball-small");
    const hoverables = document.querySelectorAll(".hoverable");
    const hoverable_links = document.getElementsByTagName('a');

    // console.log('hoverables', hoverables)
    // Listeners
    document.addEventListener("mousemove", onMouseMove);
    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].addEventListener("mouseover", onMouseHover);
      hoverables[i].addEventListener("mouseout", onMouseHoverOut);
    }
    for (let i = 0; i < hoverable_links.length; i++) {
      hoverable_links[i].addEventListener("mouseover", onMouseHover);
      hoverable_links[i].addEventListener("mouseout", onMouseHoverOut);
    }
    document.addEventListener("mousedown", onMouseHover);
    document.addEventListener("mouseup", onMouseHoverOut);

    document.addEventListener("mouseenter", e => {
      bigBall.style.opacity = 1;
      // smallBall.style.opacity = 1;
    });

    document.addEventListener("mouseleave", e => {
      bigBall.style.opacity = 0;
      // smallBall.style.opacity = 0;
    });

    // Move the cursor
    function onMouseMove(e) {
      bigBall.style.opacity = 1;
      TweenMax.to(bigBall, 0, {
        x: e.clientX - 10,
        // y: e.clientY - 35
        y: e.clientY - 15
      });
      // TweenMax.to(smallBall, 0.1, {
      //   x: e.clientX - 5,
      //   y: e.clientY -15
      // });

    }

    // Hover an element
    function onMouseHover() {
      TweenMax.to(bigBall, 0.3, {
        scale: 3
      });
    }
    function onMouseHoverOut() {
      TweenMax.to(bigBall, 0.3, {
        scale: 1
      });
    }

        // magnetic button

    let magneticButtons = document.querySelectorAll('.magnetic-button');

    this.magneticButton(magneticButtons)

  },
  methods:{
    magneticButton (elements) {
      elements.forEach(element => {
        let isMagnetic = false;
        var isTransformed;
        var offsetHoverMax = element.getAttribute("offset-hover-max") || 0.7;
        var offsetHoverMin = element.getAttribute("offset-hover-min") || 0.5;

        document.addEventListener("mousemove", e => {
          var mouse = {
                x: e.clientX,
                y: e.clientY - window.scrollY
              };
          var maxDistance = isMagnetic ? offsetHoverMax : offsetHoverMin;
          var width = element.offsetWidth;
          var height = element.offsetHeight;
          var offset = element.getBoundingClientRect();
          var center = {
              x: offset.left + width / 2,
              y: offset.top + height / 2
          };
          var dx = mouse.x - center.x;
          var dy = mouse.y - center.y;

          isTransformed = false;

          if(Math.sqrt(dx * dx + dy * dy) < width * maxDistance) {
              isTransformed = true;
              if(!isMagnetic) isMagnetic = true;
              onHover(dx, dy);

          }

          if(!isTransformed && isMagnetic) {
              onLeave();
              isMagnetic = false;
          }

        });


        function onHover(x,y) {
          TweenMax.to(element, 0.4, {
                x: x * 0.8,
                y: y * 0.8,
                //scale: .9,
                rotation: x * 0.05,
                ease: Power2.easeOut
              });
        }

        function onLeave() {
          TweenMax.to(element, 0.7, {
            x: 0,
            y: 0,
            scale: 1,
            rotation: 0,
            ease: Elastic.easeOut.config(1.2, 0.4)
          });
        };

      })
    }


  }
};
</script>

<style scoped lang="less">
  @import "../../static/styles/settings.less";
.cursor {
  pointer-events: none;
}
.cursor-ball {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 999999999;
  opacity: 0;
  transition: opacity 0.5s ease;
  @media @mobile, @large-mobile, @tablet {
    display: none;
  }
}
.cursor-ball circle {
  fill: #f7f8fa;
  /* fill: #FF5676; */
}

/* .cursor-ball-big circle{
  fill: #f7f8fa;
  stroke: #FF5676;
  fill-opacity: 0.5;
} */

</style>
