import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  footerOffset: 0,
  showMenu: false,
  loading: false,
  isMobile: true,
  isNavigatingBack: false
}

const mutations = {
  SET_FOOTER_OFFSET (state, height) {
    state.footerOffset = height
  },
  SET_LOADING (state, bool) {
    state.loading = bool
  },
  SET_NAVIGATING_BACK (state, bool) {
    state.isNavigatingBack = bool
  },
  SET_DEVICE_TYPE (state, type) {
    state.isMobile = type
  }
}

const actions = {
  incrementAsync ({ commit }) {
    setTimeout(() => {
      commit('INCREMENT')
    }, 200)
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions
})

export default store
