<template>
  <div>
    <transition
      :name="transition"
      :mode="mode"
      v-if="shouldApply"
    >
      <slot></slot>
    </transition>
    <div v-if="shouldApply" class="overlay-top"></div>
    <div v-if="shouldApply" class="overlay-right"></div>
    <div v-if="shouldApply" class="overlay-bottom"></div>
    <div v-if="shouldApply" class="overlay-left"></div>
  </div>
</template>

<script>
  // Had to download this component from: https://github.com/Orlandster/vue-page-transition
  // and customize it a bit
  export default {
    name: 'VuePageTransition',
    props: ['name', 'mode', 'shouldApply'],
    data () {
      return {
        transition: null,
      }
    },
    created () {
      if(this.shouldApply){
        this.$router.beforeEach((to, from, next) => {
          this.transition = to.meta.transition
            ? to.meta.transition
            : this.$props.name
          // console.log('this.transition', this.transition)
          next()
        })
      }
    },
  }
</script>

<style>
  :root {
    --overlay-bg: white;
    --transition-duration: .35s;
  }
</style>

<style lang="less" scoped>
  // overlay up
  .overlay-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100vw;
    background: var(--overlay-bg);
    transition-duration: var(--transition-duration);
  }

  .overlay-up-enter ~ .overlay-bottom,
  .overlay-up-leave-to ~ .overlay-bottom {
    height: 0;
  }

  .overlay-up-enter-active ~ .overlay-bottom,
  .overlay-up-leave-active ~ .overlay-bottom {
    height: 100vh;
  }

  .overlay-up-enter-active ~ .overlay-bottom {
    transition-timing-function: ease-in;
  }

  .overlay-up-leave-active ~ .overlay-bottom {
    transition-timing-function: ease-out;
  }

  .overlay-up-enter-active,
  .overlay-up-leave-active {
    transition-duration: var(--transition-duration);
  }

  // fade in
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
