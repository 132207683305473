<template>
  <div offset-hover-max="2" offset-hover-min="1" class="hamburger magnetic-button">
    <svg width="26px" height="17px" viewBox="0 0 26 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="home" transform="translate(-1369.000000, -116.000000)" fill="#333333">
                <g id="Header" transform="translate(-5.000000, -9.000000)">
                    <g id="MENU" transform="translate(1374.000000, 125.000000)">
                        <polygon id="Fill-1" points="0 4 26 4 26 0 0 0"></polygon>
                        <polygon id="Fill-2" points="0 17 26 17 26 13 0 13"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  </div>
</template>

<script>
  export default {
    methods: {

    },
  }
</script>
<style lang="less" scoped>
  @import "../../static/styles/settings.less";
  .hamburger {
    z-index: 12;
    cursor: pointer;
  }
  g {
    fill: @navy;
  }
</style>
