<template>
  <!-- contact form -->
  <form class="section section--menu form contact-form-only">
    <div v-bind:class="emailData ? 'row custom-row configurator-form' : 'row custom-row'">
      <div v-if="!emailData" class="col-md-12 input-field">
        <h1>Contact.</h1>
      </div>
      <div v-if="emailData" class="col-md-12 input-field configurator-form-msg">
        <p class="text-center">Thank you for answering these questions! If you would like to provide your name and email address we'll be in touch as soon as possible to arrange a time to discuss your project and relevant pricing information.</p>
      </div>
      <div v-else class="col-md-12 input-field">
        <p>Tell us about your project <br/>and we’ll be in touch.</p>
      </div>
      <div class="col-md-12 col-lg-8 name input-field">
        <input v-model="enquiry.name" class="contact-input-form" placeholder="Name" type="text" name="" value=""/>
      </div>
      <div class="col-md-12 col-lg-8 email input-field">
        <input v-model="enquiry.email" class="contact-input-form" placeholder="Email" type="email" name="" value=""/>
      </div>
      <div v-if="!emailData" class="col-md-12 col-lg-8 input-field">
        <textarea v-model="enquiry.message" rows="4" class="contact-input-form" placeholder="Message" name="" value=""></textarea>
      </div>
    </div>
    <div v-if="emailData" class="col-12 send-container--menu justify-content-center align-items-center">
      <div class="row justify-content-center align-items-center">
        <button class="btn-take-a-look custom-button hoverable" @click="e => submitForm(e)">
          <span class="text">
            SEND
          </span>
        </button>
      </div>
      <div class="snackBar row justify-content-center align-items-center" v-if="snackbarStatus">
        <p class="thank-you-message" style="margin-top: 20px" v-html="snackbarText"></p>
      </div>
      <div v-if="errorMessage" class="row error-msg-container error-msg-container--menu justify-content-center align-items-center">
        <p class="error-msg" v-html="errorMessage" style="margin-top: 20px"></p>
      </div>
    </div>
    <div v-else class="row send-container--menu">
      <div class="col-sm-12 col-md-4">
        <button class="btn-take-a-look custom-button hoverable" @click="e => submitForm(e)">
          <span class="text">
            SEND
          </span>
        </button>
      </div>
      <div class="snackBar col-md-12 col-lg-8" v-if="snackbarStatus">
        <p class="thank-you-message" v-html="snackbarText"></p>
      </div>
      <div v-if="errorMessage" class="col-md-12 col-lg-8 error-msg-container error-msg-container--menu">
        <p class="error-msg" v-html="errorMessage"></p>
      </div>
    </div>
  </form>
</template>

<script>

import {submitContact} from "../../api";

export default {
  name: "ContactForm",
  props: ["emailData"],
  data () {
  return {
    dt: "",
    isMobilePhone: true,
    enquiry: {
      name: null,
      email: null,
      message: null
    },
    emailAddress: null,
    errorMessage: null,
    errorMessageDemo: null,
    errorMessage2: ' ',
    updates: [],
    currentData: {},
    navigate: false,
    snackbarStatus: false,
    demoSnackbarStatus: false,
    snackbarText: "Thanks for your enquiry, someone from the Spaces+ team will be intouch shortly."
    }
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    /* CONVERSION - GOOGLE ADS */
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', 'AW-654851161');
  },
  methods: {
    formatMessage(){
      this.enquiry.message = ''
      let message = this.enquiry.message;

      message += 'Questions data: ';
      this.emailData.forEach((question, index) => {
        let questionNumber= ++index;
        console.log("question ", question)
        message += (questionNumber + ') Question: ');
        message += question.question + ' ';
        message += ('- Answer: ');
        message += question.answer + ' ';
        if(question.feature.length > 0){
          message += ('- Feature: ');
          message += question.feature + '. ';
        }
      })

      console.log({message})
      return message
    },
    gtag(){
      /* CONVERSION - GOOGLE ADS */
      // console.log("gtag ", arguments)
      dataLayer.push(arguments);
    },
    gtag_report_conversion(url) {
      /* CONVERSION - GOOGLE ADS */
      // console.log("gtag_report_conversion ", url)
      var callback = function () {
        // console.log("callback")
        // if (typeof(url) != 'undefined') { window.location = url; }
      };
      this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/p73gCLnB-P8BENn4oLgC', 'event_callback': callback });
      return false;
    },
    submitForm(e) {
      try {
        e.preventDefault();
        this.enquiry.message = this.emailData ? this.formatMessage() : this.enquiry.message
        if (!this.validateEmailFormat(this.enquiry.email)) {
          this.errorMessage = 'Please enter correct email address.'
          return
        }

        if (this.enquiry.name == null || this.enquiry.message == null ) {
            this.errorMessage = 'Please complete all fields'
            return
        }

        this.gtag_report_conversion(`https://spacesplus.co/contact`)

        const body = {
          name: this.enquiry.name,
          message: this.emailData ? this.formatMessage() : this.enquiry.message,
          email_address: this.enquiry.email,
          subject: this.emailData ? 'Configurator - Spaces+' : 'Get Started - Spaces+'
        }

        console.log(body)
        submitContact(body).then(res => {
          if(res && res.id){
            this.enquiry.name = null
            this.enquiry.email = null
            this.enquiry.message = null
            this.errorMessage = ''
            this.snackbarStatus = true
          } else {
            this.errorMessage = "Ops! Something went wrong, please try again."
          }
        }).catch(e => {
          this.errorMessage = "Ops! Something went wrong, please try again."
        })
      } catch(e) {
        this.errorMessage = "Ops! Something went wrong, please try again."
      }
    },
    validateEmailFormat(email){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
};
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
.contact-form-only{
  padding-right: 0 !important;
  .custom-col {
    padding-left: 0;
    margin-bottom: 40px;
    @media @mobile {
      margin-bottom: 10px;
    }
  }
  .footer-container-contact {
    @media @mobile {
      padding: 15px !important;
    }
    .col-6 {
      @media @mobile, @large-mobile, @tablet {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    .mobile {
      @media @mobile {
        padding-bottom: 10px;
        flex: 0 0 100% !important;
      }
    }
    .stay-in-loop {
      padding-left: 0;
      @media @mobile {
        padding-top: 35px;
      }
      @media @tablet {
        padding-top: 20px;
      }
    }

    .footer-bottom {
      margin-top: 50px;

      .col-8 {
        @media @mobile, @large-mobile {
          max-width: 100%;
          flex: 0 0 100% !important;
          padding-bottom: 35px;
        }
      }
    }
  }

  .opaque {
    opacity: 0;
    transform: translateY(200px);
  }

  a:hover {
    text-decoration: none;
  }
  .footer-container-new {
    width: 92%;
    background-color: white;
    box-sizing: border-box;
    padding: 30px 0 30px 0;
    margin: 0 auto;

    @media @iPad {
      width: 90%;
    }
    @media @mobile, @large-mobile {
    }
  }

  #addresses {
    margin: 0 auto;
  }

  .address-header {
    font-size: 14px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 23px;
  }

  .address {
    font-size: @content-font-size;
    line-height: 23px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: @font-grey;
    @media @mobile, @large-mobile {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .footer-info-description {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: @black;
    margin-bottom: 5px;
    @media @mobile, @large-mobile {
      font-size: 12px;
    }
  }
  .footer-info-description-text {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: @black;
    @media @mobile, @large-mobile {
      font-size: 12px;
    }
  }
  .footer-info-header {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    line-height: 23px;
    @media @mobile, @large-mobile {
      font-size: 14px;
    }
  }

  #social-media {
    display: flex;
    .item {
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 13px;
      margin-right: 15px;
      color: @font-grey;
      .link {
        color: @font-grey;
      }
    }
  }

  .copyright {
    color: @font-grey;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;

    @media @mobile, @large-mobile {
      padding-left: 15px !important;
      justify-content: center;
    }
  }
  .upperCase {
    text-transform: uppercase;
  }

  .col-5 {
    @media @mobile, @large-mobile, @tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .mirk-home-link {
    p {
      font-size: 1.2vw;
      font-weight: bold;
      color: black;
      text-decoration: underline;
    }
  }

  .contact-form-container {
    padding-left: 5%;
    background-color: #F3F3F3;
    .wrapper {
      width: 90%;
      max-width: 1600px;
      margin: 0px auto;

      @media @mobile, @large-mobile, @tablet {
        width: 100%;
        margin: 0px auto !important;
      }
    }

    .section {
      margin: 100px auto;
      //padding: 150px 0px 50px 0px;
      max-width: 700px;
      @media @mobile, @large-mobile, @tablet {
        margin: 70px auto !important;
        padding: 75px 0px 75px 0px;
      }
    }

    .form {
      max-width: 700px;
      margin: 0 auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 90%;
        margin: 0px auto !important;
      }
    }
    .contact-input {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @grey;
      border: 2px solid @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      transition: all .2s ease;
      &:focus {
        color: @black;
        border: 2px solid @spaces-plus-light-green;
      }
      min-height: 65px;
    }
    .contact-input-form {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;;
      font-size: 18px;
      line-height: 38px;
      transition: all .2s ease;
      border: 0;
      &:focus {
        color: @black;
        border-bottom: 1px solid @spaces-plus-light-green;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @contact-grey;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: @contact-grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: @contact-grey;
    }
    .error-msg-container {
      display: flex;
      align-items: center;
      min-height: 50px;

      .error-msg {
        color: @spaces-plus-light-green;
        line-height: 24px;
        font-size: @content-font-size;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
      }
    }
    .mirk-address {
      @media @mobile, @large-mobile {
        display: none !important;
      }

      p {
        line-height: 32px;
        font-family: Roboto, Helvetica, Arial, sans-serif;

        @media @tablet {
          font-size: 1rem;
          line-height: 1.4rem;
        }
        font-size: 1.125rem;
        color: @black;
      }
    }
    .btn-submit {
      position: relative;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: 48px;
      cursor: pointer;
      .text {
        position: relative;
        color: @black;
        transition: color 450ms linear 184ms;
        z-index: 1;
        &:before {
          content: "";
          background: @light-green;
          opacity: 0.23;
          display: block;
          width: 100%;
          height: 13px;
          position: absolute;
          bottom: -5px;
          left: -0.15em;
          right: -0.15em;
          transition: 300ms transform cubic-bezier(0.165, 0.84, 0.44, 1);
          transform-origin: 0% 100%;
          transform: scale(1, 0) translateZ(0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background-repeat: repeat-x;
          z-index: -1;
        }
      }

      &:hover {
        a {
          text-decoration: none;
        }
        .text {
          &:before {
            transform: scale(1.15, 0.99999999) translateZ(0);
          }
        }
        .angle-right {
          transition: 0.5s;
          transform: translateX(10px);
        }
      }
    }


  }

  .custom-row {
    margin: 0px !important;
    //background: white;
  }

  .input-field {
    padding: 10px 0px;

    h1{
      font-weight: bold;
    }
  }

  .configurator-form-msg{

    p{
      font-size: 20px;
      line-height: 23px;
    }
  }

  .contact-address-container {
    padding-left: 5%;
    background-color: #000000;
    padding-top: 120px;
    padding-bottom: 50px;
    color: white;

    @media @large-mobile, @mobile {
      padding-top: 75px;
    }
  }

  .social-link-container {
    display: flex;
    justify-content: space-between;
    padding-top: 200px;
    width: 90%;
    flex-wrap: wrap;


    a {
      color: #BABABA;
      font-size: @content-font-size;
      line-height: @content-line-height;
      font-family: Roboto, Helvetica, Arial, sans-serif;

      @media @mobile, @large-mobile {
        font-size: 15px;
      }
    }

    @media @iPad-pro {
      padding-top: 230px;
    }

    @media @iPad {
      div {
        width: 100%;
      }
    }

    @media @mobile, @large-mobile {
      padding-top: 0px;
      width: 100%;
      justify-content: space-around;
    }
  }

  .custom-button{
    box-sizing: border-box;
    padding: 0px 20px;
    min-width: 150px;
    border-radius: 8px;
    border: 1.5px solid @spaces-plus-light-green;
    color: @spaces-plus-light-green;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    background-color: transparent;

    .text {
      margin-bottom: 0;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      .text {
        &:before {
          transform: scale(1.15, 0.99999999) translateZ(0);
        }
      }
      .angle-right {
        transition: 0.5s;
        transform: translateX(10px);
      }
    }
  }

  .booking-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;

    @media @mobile, @large-mobile {
      width: unset;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-size: 42px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      color: #D9342A;
    }

    .input-container {
      background-color: #F3F3F3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 70px 30px;

      .contact-input-form {
        width: 100%;
        font-size: @content-font-size;
      }
    }
  }

  .booking-close-btn-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }

  .modal-booking-container {
    height: 98.5vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #booking-demo {
    padding-right: 0px !important;
    z-index: 999 !important;
  }

  .thank-you-message {
    color: #696969;
    font-size: 18px;
    line-height: 24px;
    @media @mobile, @large-mobile{
      margin-top: 10px;
    }
  }
  #demoBooking {
    padding-right: 0px !important;
    background: black;
    overflow: hidden !important;
    max-height: 100vh;
    z-index: 99999 !important;
  }

  .send-container {
    padding: 5vh 0px 2vh;
    display: flex;
    align-items: center;
  }

  #contact {
    margin-top: 60px !important;
  }

  .menu-logo {
    padding: 50px 0px 20px;
  }

  .little-m {
    transition: 0.3s;
    width: 200px;
    @media @mobile, @large-mobile {
      width: 220px;
    }
  }

  .contact-address-container--menu{
    background: white;
  }

  .modal-wrapper{
    height: 100vh;
  }

  .close-btn--menu {
    margin: 50px 50px 0px 0px;
  }
  .cross {
    cursor: pointer;
    width: 25px;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
    @media @mobile, @large-mobile {
      width: 15px;
      margin-top: 18px;
    }
  }
  .contact-address-container--menu {
    padding-left: 10vw;
  }

  .custom-button{
    box-sizing: border-box;
    padding: 0px 20px;
    min-width: 150px;
    border-radius: 8px;
    border: 1.5px solid @spaces-plus-dark-blue;
    color: @spaces-plus-dark-blue;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    background-color: transparent;

    .text {
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px !important;
      line-height: 24px !important;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      .text {
        &:before {
          transform: scale(1.15, 0.99999999) translateZ(0);
        }
      }
      .angle-right {
        transition: 0.5s;
        transform: translateX(10px);
      }
    }
  }
}

.configurator-form{

  .input-field{
    margin: 0 auto;
  }
  .contact-input-form {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 15px;
    background-color: @white;
    color: @spaces-plus-dark-blue !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;;
    font-size: 18px;
    line-height: 38px;
    transition: all .2s ease;
    border: 0;
    border-bottom: 1px solid @spaces-plus-dark-blue;
    &:focus {
      color: @black;
      border-bottom: 1px solid @spaces-plus-light-green;
    }
  }
}
</style>

