<template lang="html" class="feat-template">
  <!-- MODAL -->
  <div class="modal book-a-demo" :id="`demoBooking`" tabindex="-1" role="dialog" aria-labelledby="videoModal1Label" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-close-btn-container">

        </div>
        <div class="modal-body modal-booking-container">
          <form class="section form">
            <div class="booking-container">
              <div class="col-md-12 input-field header">
                <div>
                  <h3 class="title">Book a Consultation</h3>
                </div>
                <div class="booking-close-btn-circle" data-dismiss="modal">
                  <img
                    src="/images/black_cross.png"
                    alt="close icon"
                  />
                </div>
              </div>
              <div class="contact-form-container input-container">

                <div class="col-md-12 name input-field">
                  <input v-model="demo.name" class="contact-input-form" placeholder="Name" type="text" name="" value=""/>
                </div>
                <div class="col-md-12 email input-field">
                  <input v-model="demo.email" class="contact-input-form" placeholder="Email" type="email" name="" value=""/>
                </div>
                <div class="col-md-12 input-field">
                  <input v-model="demo.message" class="contact-input-form" placeholder="Preferred day and time" type="text" name="" value=""/>
                </div>
                <div class="row send-container">
                  <div class="col-md-4 col-sm-12">
                    <button class="custom-button book-a-demo hoverable" @click="e => submitFormDemo(e)">
                      <span class="text">
                        SEND
                      </span>
                    </button>
                  </div>
                  <div class="snackBar col-md-8" v-if="demoSnackbarStatus">
                    <p class="thank-you-message" v-html="snackbarText"></p>
                  </div>
                  <div class="col-md-12 col-lg-8 error-msg-container">
                    <p class="error-msg" v-html="errorMessageDemo"></p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {submitContact} from "../../api";

export default {
  components: {},
    data(){
      return {
        dt: "",
        isMobilePhone: true,
        enquiry: {
          name: null,
          email: null,
          message: null
        },
        demo: {
          name: null,
          email: null,
          message: null
        },
        emailAddress: null,
        errorMessage: null,
        errorMessageDemo: null,
        errorMessage2: ' ',
        updates: [],
        currentData: {},
        navigate: false,
        snackbarStatus: false,
        demoSnackbarStatus: false,
        snackbarText: "Thanks for your enquiry, someone from the Spaces+ team will be intouch shortly."
      }
  },
  mounted() {

  },
  methods: {
    toggleMenu() {
      this.$store.state.showMenu = !this.$store.state.showMenu;
    },
    validateEmailFormat(email){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    submitFormDemo(e) {
      e.preventDefault();
      // console.log("e: ", this.demo.email)
      try {
        if (!this.validateEmailFormat(this.demo.email)) {
          this.errorMessageDemo = 'Please enter correct email address.'
          return
        }

        if (this.demo.name == null || this.demo.message == null ) {
          this.errorMessageDemo = 'Please complete all fields'
          return
        }

        const body = {
          name: this.demo.name,
          message: this.demo.message,
          email_address: this.demo.email,
          subject: 'Demo - Spaces+'
        }

        submitContact(body).then(res => {
          if(res && res.id){
            this.demo.name = null
            this.demo.email = null
            this.demo.message = null
            this.errorMessageDemo = null
            this.demoSnackbarStatus = true
          } else {
            this.errorMessageDemo = "Ops! Something went wrong, please try again."
          }
        }).catch(e => {
          this.errorMessageDemo = "Ops! Something went wrong, please try again."
        })
      } catch(e) {
        this.demo.errorMessageDemo = e.message;
        this.demo.errorMessageJSON = JSON.stringify(e);
        this.errorMessageDemo = "Ops! Something went wrong, please try again."
      }
    },
  }
}
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
.book-a-demo{
  .feat-fade-left {
    opacity: 0;
    transform: translateX(-100px);
  }

  .feat-fade-left-two-two {
    opacity: 0;
    transform: translateX(-100px);
  }

  .feat-fade-up-left {
    opacity: 0;
    transform: translateY(100px);
  }

  .feat-fade-up-right {
    opacity: 0;
    transform: translateY(100px);
  }

  .pointer {
    cursor: pointer;
  }

  .featured-container {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 7;

    @media @iPad-pro, @iPad {
      height: 580px;
    }

    @media @mobile, @large-mobile {
      height: 630px;
    }

    .featured-content {
      background: @spaces-plus-dark-blue;
      height: 120%;
      min-height: 760px;
      @media @iPad-pro, @iPad {
        height: 100%;
      }
      @media @mobile, @large-mobile, @tablet  {
        height: 100%;
      }

      .featured-container {
        height: 760px;
        width: 90%;
        max-width: 1600px;
        margin: 0 auto;
        padding-left: 5% !important;

        @media @iPad-pro, @iPad {
          height: 580px;
          width: 93vw !important;
        }
        @media @mobile, @large-mobile {
          height: 630px;
          width: 90%;
          padding-left: 0 !important;
        }
      }

      .booking-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .project-description{
        font-family: Roboto, Helvetica, Arial, sans-serif;
        color: @white;
        font-size: @content-font-size;
        line-height: @content-line-height;
        padding: 10px 0px;
        max-width: 500px;
        @media @mobile, @large-mobile, @tablet  {
          font-size: @content-font-size;
          font-family: Roboto, Helvetica, Arial, sans-serif;
          line-height: @content-line-height;
          padding: 0px;
        }
      }

      .project-name{
        padding: 0;
        margin: 0 0 30px;
        color: @white;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: @title-font-size;
        line-height: @title-line-height;
        font-weight: 700;
        @media @mobile, @large-mobile, @tablet  {
          font-size: @title-font-size-mobile;
          line-height: @title-line-height-mobile;
          padding-bottom: 25px;
        }
      }

      .hr-white {
        width: 70px;
        margin: 0px 0px 20px 0px;
        height: 7px;
        background-color: @white;
        border: 0;
        @media @mobile, @large-mobile, @tablet  {
          height: 3px;
          width: 41px;
          margin-bottom: 10px;
        }
      }
      .section-head {
        text-align: left;
        color: #8BCC55;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;

        @media @mobile, @large-mobile, @tablet  {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        @media @large-desktop {
          // font-size: 48px;
          line-height: 48px;
        }
      }

      .proj-button-container {
        margin: 0px;
        padding:0;
        .view-all-button {
          // height: 45px;
          width: 150px;
          color: @white;
          font-family: Roboto, Helvetica, Arial, sans-serif;
          background-color: #000000;
          text-align: center;
          font-size: 13px;
          font-weight: 900;
          line-height: 20px;
          padding: 10px;
          transition: all 0.5s;
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          cursor: pointer;
          margin-top: 20px;
          position: relative;
          text-decoration: none;
          display: inline-block;

          border-radius: 8px;
          border: 1px solid @spaces-plus-dark-blue;

          &:hover svg {
            fill: @white;
            transform: translateX(5px);
          }

          g {
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
          }

          &:hover g {
            fill: @white;
          }

          &:hover {
            color: @black;
          }

          @media @mobile, @large-mobile, @tablet  {
            font-size: 12px;
            width: 125px;
            padding: 5px;
            margin: 0;
          }

          span > svg {
            position: absolute;
            display: block;
            right: 10%;
            top: 35%;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;

            g {
              fill: @navy;
            }
          }
        }
      }
    }

  }

  // modal
  .modal-lg {
    max-width: 100vw;
    height: 100vh;
    z-index: 9999999 !important;
  }

  .modal-close-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
    background-color: black;
    padding-top: 5px;

    @media @mobile, @large-mobile {
      padding-top: 30px;
    }

    .close {
      padding: 10px;
    }
  }

  .modal-video-container {
    height: 92vh;
    background-color: black;
    z-index: 1000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-backdrop {
    opacity: 1 !important;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0 !important;
    width: 100vw !important;
    background-color: rgb(0, 0, 0);
  }

  .custom-button{
    height: 58px;
    width: 150px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid @spaces-plus-dark-blue;
    background: @spaces-plus-dark-blue;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    span {
      margin-bottom: 0;
      font-size: 18px !important;
      line-height: 24px !important;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      .text {
        &:before {
          transform: scale(1.15, 0.99999999) translateZ(0);
        }
      }
      .angle-right {
        transition: 0.5s;
        transform: translateX(10px);
      }
    }
  }
  .custom-button-spaces{
    box-sizing: border-box;
    padding: 0px 20px;
    min-width: 150px;
    margin-top: 10px;
    border-radius: 8px;
    background: white !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center !important;
    max-width: 190px;
    height: 70px;
    span {
      color: @spaces-plus-dark-blue !important;
      margin-bottom: 0 !important;
      font-size: @content-font-size;
      line-height: @content-line-height;
      font-weight: 400;
      font-family: Roboto, Helvetica, Arial, sans-serif;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      .text {
        &:before {
          transform: scale(1.15, 0.99999999) translateZ(0);
        }
      }
      .angle-right {
        transition: 0.5s;
        transform: translateX(10px);
      }
    }
  }


  .contact-form-container {
    // width: 100%;
    // margin: 0px auto;
    padding-left: 5%;
    // margin-top: 5%;
    background-color: #F3F3F3;
    .wrapper {
      width: 90%;
      max-width: 1600px;
      margin: 0px auto;

      @media @mobile, @large-mobile, @tablet {
        width: 100%;
        margin: 0px auto !important;
      }
    }

    .section {
      margin: 100px auto;
      padding: 150px 0px 50px 0px;
      max-width: 700px;
      @media @mobile, @large-mobile, @tablet {
        margin: 70px auto !important;
        padding: 75px 0px 75px 0px;
      }
    }
    .contact-input {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @grey;
      border: 2px solid @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      transition: all .2s ease;
      &:focus {
        color: @black;
        border: 2px solid @spaces-plus-dark-blue;
      }
      min-height: 65px;
    }
    .contact-input-form {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @white;
      color: @spaces-plus-dark-blue !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 38px;
      transition: all .2s ease;
      border: 0;
      // border-bottom: 1px solid @black;
      border-bottom: 1px solid white;
      &:focus {
        color: @black;
        border-bottom: 1px solid @spaces-plus-dark-blue;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @spaces-plus-dark-blue;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: @spaces-plus-dark-blue;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: @spaces-plus-dark-blue;
    }
    .error-msg-container {
      display: flex;
      align-items: center;
      min-height: 50px;

      .error-msg {
        color: @spaces-plus-dark-blue;
        line-height: 24px;
        font-size: @content-font-size;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
      }

      @media @mobile, @large-mobile{
        padding-left: 15px;
        margin-top: 10px;
      }
    }

    .mirk-address {
      @media @mobile, @large-mobile {
        display: none !important;
      }

      p {
        line-height: 32px;
        font-size: 22px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        color:@font-grey;

        @media @tablet {
          font-size: 1rem;
          line-height: 1.4rem;
        }
        //font-size: 1.125rem;
        //color: @black;
      }
    }
  }

  .booking-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;

    @media @mobile, @large-mobile {
      width: 90vw;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 5px 0;
    }

    .title {
      font-size: 42px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      color: #72BF00;
      font-weight: 300;
      @media @mobile, @large-mobile{
        font-size: @title-font-size-mobile;
      }
    }

    .input-container {
      background-color: #F3F3F3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 70px 30px;

      @media @mobile, @large-mobile{
        padding: 70px 10px 30px;
      }
      .contact-input-form {
        width: 100%;
        font-size: @content-font-size;
      }

    }
    .form {
      max-width: 700px;
      margin: 0 auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 90vw;
        min-width: 90vw;
        margin: 0px auto !important;
      }
    }
  }
  @media @mobile, @large-mobile {
    .col-md-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .booking-close-btn-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }

  .modal-booking-container {
    height: 100vh;
    background-color: black;
    // z-index: 1000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #booking-demo {
    padding-right: 0px !important;
  }

  .thank-you-message {
    color: #696969;
    font-size: 18px;
    line-height: 24px;
    @media @mobile, @large-mobile{
      margin-top: 10px;
    }
  }
  #demoBooking {
    padding-right: 0px !important;
    background: black;
    overflow: hidden !important;
    max-height: 100vh;
    z-index: 99999 !important;


    .section {
      max-width: 700px;
      margin: 0 auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 90vw;
        min-width: 80vw;
        margin: 0 auto !important;
      }
    }
  }

  .send-container {
    padding: 5vh 0px 2vh;
    display: flex;
    align-items: center;
  }
}

.modal-backdrop.show{
  display: none;
}

.modal-body{
  border: none !important;
}
</style>
