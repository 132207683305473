module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "menu-container align-items-center",
    style: (_vm.menuPosition)
  }, [_c('div', {
    staticClass: "nav-wrapper"
  }, [_c('img', {
    staticClass: "little-m hoverable",
    style: (_vm.menuItemPostion),
    attrs: {
      "src": require("../../static/images/Logo.png"),
      "alt": "Spaces+"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'mirk-navbar')
      }
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "cross magnetic-button hoverable",
    style: (_vm.menuItemPostion),
    attrs: {
      "role": "button",
      "offset-hover-max": "2",
      "offset-hover-min": "1",
      "src": "/images/white_cross.png",
      "alt": "close button"
    },
    on: {
      "click": _vm.toggleMenu
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "projects-header-container",
    style: (_vm.menuOpacity)
  }, [_c('div', {
    staticClass: "row ml-0 p-0"
  }, [_c('div', {
    staticClass: "nav-container-menu col-10 p-0"
  }, [_c('ul', [_c('li', {
    style: (_vm.menuItemPostion),
    attrs: {
      "aria-label": "features"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'features')
      }
    }
  }, [_vm._m(0)]), _vm._v(" "), _c('li', {
    style: (_vm.menuItemPostion),
    attrs: {
      "aria-label": "packages"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'packages')
      }
    }
  }, [_vm._m(1)]), _vm._v(" "), _c('li', {
    style: (_vm.menuItemPostion),
    attrs: {
      "aria-label": "how-it-works"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'how-it-works')
      }
    }
  }, [_vm._m(2)]), _vm._v(" "), _c('li', {
    style: (_vm.menuItemPostion),
    attrs: {
      "aria-label": "configurator"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'configurator')
      }
    }
  }, [_vm._m(3)]), _vm._v(" "), _c('li', {
    style: (_vm.menuItemPostion),
    attrs: {
      "data-toggle": "modal",
      "data-target": "#demoBooking"
    }
  }, [_vm._m(4)])])])])])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('h3', [_vm._v("Features")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('h3', [_vm._v("Packages")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('h3', [_vm._v("How It Works")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('h3', [_vm._v("Find your Space")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('h3', [_vm._v("Book a Consultation")])])
}]}