<template lang="html">
  <section class="banner-text-container">
    <div class="banner-text-wrapper">
      <p class="banner-text">Spaces+ is a platform that is secure, provides a seamless user experience, is rapidly deployed and can be used for regular events.</p>
    </div>
  </section>

</template>

<script>
export default {
  name: 'TextOnlyBanner'
}

</script>

<style lang="less">
@import "../../../static/styles/settings.less";

.banner-text-container {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: @spaces-plus-light-green;
  position: relative;
  margin-top:80px;
  justify-content: center;

  @media @mobile, @large-mobile {
    margin-top: 50px;
  }
  p{
    @media @mobile, @large-mobile {
      line-height: 20px;
      margin-bottom: 0.3em;
    }
  }

  @media @tablet {

  }
  .banner-text-wrapper{
    max-width: 980px;
    min-height: 400px;
    padding: 100px 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      a {  text-decoration: none;}
    }

    @media @mobile{
      padding: 70px 0;
    }
  }

  .banner-text{
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    //padding-left: 5% !important;
    color: @white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 33px;
    font-weight: 500;
    line-height: 45px;
    text-align: center;
    @media @mobile, @large-mobile {
      font-size: 18px;
      line-height: @content-line-height;
      padding-left: 0% !important;
    }

  }
}

</style>
