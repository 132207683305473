module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('footer', {
    staticClass: "footer-container-new"
  }, [_c('div', {
    staticClass: "row footer-bottom"
  }, [_c('div', {
    staticClass: "logos-container col-sm-12 col-md-10 col-lg-10"
  }, [_c('div', {
    staticClass: "logos-images"
  }, [_vm._m(0), _vm._v(" "), (_vm.dt) ? _c('div', {
    staticClass: "item copyright"
  }, [_c('span', {
    staticClass: "item",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("© " + _vm._s(_vm.dt.getFullYear()) + " Spaces+")])]) : _c('div', {
    staticClass: "item copyright"
  }, [_c('span', {
    staticClass: "item",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("© Spaces+")])])])]), _vm._v(" "), _c('router-link', {
    staticClass: "privacy-policy-link col-sm-12 col-md-2 col-lg-2 align-content-end",
    attrs: {
      "to": {
        name: 'privacy-policy'
      }
    }
  }, [_c('span', {
    staticClass: "item",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("Privacy Policy")])])], 1)])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "spaces-image",
    attrs: {
      "src": "/images/Spaces+Dark.png",
      "alt": "Spaces Interactive"
    }
  })])
}]}