<template>
  <!-- <footer class="footer-container-contact"> -->
    <div id="contact" class="row custom-row">
      <div class="col-sm-12 col-md-6 contact-form-container">
        <!-- contact form -->
        <!-- prevents the rest of the content from showing, still haven't figured it out -->

        <ContactForm/>
      </div>
      <div class="col-sm-12 col-md-6 contact-address-container">
        <!-- contact information -->
        <p>hello@spacesplus.co</p>
        <br />
        <p>
          SYDNEY
        </p>
        <p>
          Level 2, 11 York Street
          <br />Sydney 2000
        </p>
        <p>+612 8231 6469</p>
        <br />
        <p>
          SURRY HILLS
        </p>
        <p>
          Suite 73, 61 Marlborough Street
          <br />Surry Hills 2010
        </p>
        <p>+612 8231 6469</p>
        <br />
        <p>
          MELBOURNE
        </p>
        <p>
          101 Moray Street
          <br />South Melbourne 3205
        </p>
        <p>+613 7064 0501</p>

        <br />
        <br />
        <button
          class="custom-button book-a-demo"
          data-toggle="modal"
          data-target="#demoBooking"
          @click="gtag_report_conversion(`https://spacesplus.co/book-a-demo`)"
        >
          <p class="text">Book a Consultation </p>
        </button>
      </div>

      <!-- <Modal /> -->
    </div>
  <!-- </footer> -->
</template>

<script>

import ContactForm from "./ContactForm";
export default {
  name: "Footer",
  components: {ContactForm},
  data () {
  return {
      dt: "",
      isMobilePhone: true,
        enquiry: {
          name: null,
          email: null,
          message: null
        },
        demo: {
          name: null,
          email: null,
          message: null
        },
        emailAddress: null,
        errorMessage: null,
        errorMessageDemo: null,
        errorMessage2: ' ',
        updates: [],
        currentData: {},
        navigate: false,
        snackbarStatus: false,
        demoSnackbarStatus: false,
        snackbarText: "Thanks for your enquiry, someone from the Spaces+ team will be intouch shortly."
    }
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    this.dt = new Date();
    /* CONVERSION - GOOGLE ADS */
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', 'AW-654851161');
  },
  methods: {
    gtag(){
      /* CONVERSION - GOOGLE ADS */
      // // console.log("gtag ", arguments)
      dataLayer.push(arguments);
    },
    gtag_report_conversion(url) {
      /* CONVERSION - GOOGLE ADS */
      // console.log("gtag_report_conversion ", url)
      var callback = function () {
        // console.log("callback")
        // if (typeof(url) != 'undefined') { window.location = url; }
      };
      this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/fSRZCIq24f8BENn4oLgC', 'event_callback': callback });
      return false;
    },
  },
};
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
.custom-col {
  // max-width: 70%;
  padding-left: 0;
  margin-bottom: 40px;
  @media @mobile {
    margin-bottom: 10px;
  }
}
.footer-container-contact {
  @media @mobile {
    // width: 90% !important;
    padding: 15px !important;
  }
  .col-6 {
    @media @mobile, @large-mobile, @tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .mobile {
    @media @mobile {
      padding-bottom: 10px;
      flex: 0 0 100% !important;
    }
  }
  .stay-in-loop {
    padding-left: 0;
    @media @mobile {
      padding-top: 35px;
    }
    @media @tablet {
      padding-top: 20px;
    }
  }

  .footer-bottom {
    margin-top: 50px;
    // padding-left: 15px;

    @media @mobile {
      // margin-top: 100px;
    }

    .col-8 {
      @media @mobile, @large-mobile {
        max-width: 100%;
        flex: 0 0 100% !important;
        padding-bottom: 35px;
      }
    }
  }
}

.opaque {
  opacity: 0;
  transform: translateY(200px);
}

a:hover {
  text-decoration: none;
}
.footer-container-new {
  width: 92%;
  // max-width: 1600px;
  // width: 64%;
  // margin: 25%;
  background-color: white;
  box-sizing: border-box;
  padding: 30px 0 30px 0;
  margin: 0 auto;

  @media @iPad {
    width: 90%;
  }
  @media @mobile, @large-mobile {
    // margin: 100px auto;
  }
}

#addresses {
  margin: 0 auto;
}

.address-header {
  font-size: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 23px;
}

.address {
  font-size: 19px;
  line-height: 23px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: @font-grey;
  @media @mobile, @large-mobile {
    font-size: 14px;
    line-height: 21px;
  }
}

.footer-info-description {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: @black;
  margin-bottom: 5px;
  @media @mobile, @large-mobile {
    font-size: 12px;
  }
}
.footer-info-description-text {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: @black;
  @media @mobile, @large-mobile {
    font-size: 12px;
  }
}
.footer-info-header {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 23px;
  @media @mobile, @large-mobile {
    font-size: 14px;
  }
}

#social-media {
  display: flex;
  .item {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    margin-right: 15px;
    color: @font-grey;
    .link {
      color: @font-grey;
    }
  }
}

.copyright {
  color: @font-grey;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;

  @media @mobile, @large-mobile {
    padding-left: 15px !important;
    justify-content: center;
  }
}
.upperCase {
  text-transform: uppercase;
}

.col-5 {
  @media @mobile, @large-mobile, @tablet {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.mirk-home-link {
  p {
    font-size: 1.2vw;
    font-weight: bold;
    color: black;
    text-decoration: underline;
  }
}

.contact-form-container {
    padding-left: 5% !important;
    background-color: #E0F0C7;
    .wrapper {
      width: 90%;
      max-width: 1600px;
      margin: 0px auto;

      @media @mobile, @large-mobile, @tablet {
        width: 100%;
      }
    }

    .section {
        margin: 100px auto;
        padding: 120px 0px 120px 0px;
        max-width: 700px;
        @media @mobile, @large-mobile, @tablet {
          margin: 70px auto !important;
          padding: 75px 0px 75px 0px;
        }
    }

    .form {
      max-width: 700px;
      margin: 0 auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 90%;
        margin: 0px auto !important;
        margin-left: 0 !important;
      }
    }
    .contact-input {
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 15px;
        background-color: @grey;
        border: 2px solid @white;
        color: @grey-projects !important;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 30px;
        transition: all .2s ease;
        &:focus {
            color: @black;
            border: 2px solid @spaces-plus-dark-blue;
        }
        min-height: 65px;
    }
    .contact-input-form {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 38px;
      transition: all .2s ease;
      // border-bottom: 1px solid @black;
      border: 0;
      border-bottom: 1px solid white;
      &:focus {
        color: @black;
        border-bottom: 1px solid @spaces-plus-dark-blue;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @contact-grey;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: @contact-grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: @contact-grey;
    }
    .error-msg-container {
      display: flex;
      align-items: center;
      min-height: 50px;

      .error-msg {
        color: @spaces-plus-dark-blue;
        line-height: 24px;
        font-size: 16px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        margin-bottom: 0;

      }

    }

    .mirk-address {
      @media @mobile, @large-mobile {
        display: none !important;
      }

      p {
        line-height: 32px;
        font-size: 22px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        color:@font-grey;

        @media @tablet {
          font-size: 1rem;
          line-height: 1.4rem;
        }
        font-size: 1.125rem;
        color: @black;
      }
    }
    .btn-submit {
      position: relative;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 48px;
      cursor: pointer;
      .text {
        position: relative;
        color: @black;
        transition: color 450ms linear 184ms;
        z-index: 1;
        &:before {
          content: "";
          background: @light-green;
          opacity: 0.23;
          display: block;
          width: 100%;
          height: 13px;
          position: absolute;
          bottom: -5px;
          left: -0.15em;
          right: -0.15em;
          transition: 300ms transform cubic-bezier(0.165, 0.84, 0.44, 1);
          transform-origin: 0% 100%;
          transform: scale(1, 0) translateZ(0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background-repeat: repeat-x;
          z-index: -1;
        }
      }

      &:hover {
        a {
          text-decoration: none;
        }
        .text {
          &:before {
            transform: scale(1.15, 0.99999999) translateZ(0);
          }
        }
        .angle-right {
          transition: 0.5s;
          transform: translateX(10px);
        }
      }
    }


}

.custom-row {
  margin: 0px !important;
}

.input-field {
  padding: 10px 0px;
}

.contact-address-container {
  padding-left: 5% !important;
  background-color: @spaces-plus-dark-blue;
  padding-top: 130px;
  padding-bottom: 120px;
  color: white;

  @media @large-mobile, @mobile {
    padding-top: 75px;
  }
}

.custom-button{
  box-sizing: border-box;
  padding: 0px 20px;
  min-width: 150px;
  border-radius: 8px;
  border: 1.5px solid #D9342A;
  color: #D9342A;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  background-color: transparent;

  .text {
    // font-size: 1erm;
    // padding: 12px 0px 0px;
  }

  &:hover {
    a {
      text-decoration: none;
    }
    .text {
      &:before {
        transform: scale(1.15, 0.99999999) translateZ(0);
      }
    }
    .angle-right {
      transition: 0.5s;
      transform: translateX(10px);
    }
  }
}

.booking-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;

   @media @mobile, @large-mobile {
    width: unset;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 42px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: #D9342A;
  }

  .input-container {
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // padding: 0px 30px;
    padding: 70px 30px;

    .contact-input-form {
      width: 100%;
      font-size: 19px;
      // border: none;
      // height: 50px;

    }

  }
}

.booking-close-btn-circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 20px;
    height: 20px;
  }
}

.modal-booking-container {
  height: 98.5vh;
  background-color: black;
  // z-index: 1000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#booking-demo {
  padding-right: 0px !important;
  z-index: 999 !important;
}

.thank-you-message {
  color: #696969;
  font-size: 18px;
  line-height: 24px;
}
#demoBooking {
  padding-right: 0px !important;
  background: black;
  overflow: hidden;
}

.send-container {
  padding: 5vh 0px 2vh;
  display: flex;
  align-items: center;
}

#contact {

  .custom-button{
    height: 58px;
    width: 150px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid @spaces-plus-dark-blue;
    background: @spaces-plus-dark-blue;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    p{
      margin-bottom: 0;
    }
  }
  .book-a-demo{
    height: 58px;
    width: 220px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px !important;
    font-weight: 300;
    border: 1px solid @spaces-plus-light-green;
    background: @spaces-plus-light-green;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    p{
      margin-bottom: 0;
      font-size: 19px !important;
    }

    @media @mobile {
      width: 230px;
    }
  }
}
</style>

