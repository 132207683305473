<template lang="html" class="feat-template">

  <section class="banner-section-container" id="featured-trigger" :style="'background: ' + bannerContent.backgroundColor">
    <div class="banner-wrapper">
      <div v-if="bannerContent.columns === 1" :class="bannerContent.imagePosition === 'right' ? 'div-row div-row-reverse': 'div-row' ">
        <div class="project-info col-12">
          <h1 class="title" :style="'color: ' + bannerContent.textColor + '!important'" v-html="bannerContent.title"></h1>
          <hr class="hr-white">
          <p v-if="bannerContent.heading" class="heading" :style="'color: ' + bannerContent.textColor + '!important'" v-html="bannerContent.heading"></p>
          <p class="description" :style="'color: ' + bannerContent.textColor + '!important'" v-html="bannerContent.description"></p>
          <ul class="list" :style="'color: ' + bannerContent.textColor + '!important'">
            <li v-for="item in bannerContent.list" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>

      </div>
      <div v-else :class="bannerContent.imagePosition === 'right' ? 'div-row div-row-reverse': 'div-row' ">

        <div class="project-image-container col-sm-12 col-md-7">
          <div :class="'auto-ar image-' + bannerContent.id">
            <img v-if="bannerContent.id === 'audience'" class="banner-image" src="../../../static/images/audience.png" alt="Audience Image"/>
            <img v-if="bannerContent.id === 'local'" class="banner-image" src="../../../static/images/local.png" alt="Local Image"/>
            <img v-if="bannerContent.id === 'getting-started'" class="banner-image" src="../../../static/images/getting-started.png" alt="Getting Started Image"/>
          </div>
        </div>

        <div class="project-info col-sm-12 col-md-5">
          <h1 class="title" :style="'color: ' + bannerContent.textColor + '!important'" v-html="bannerContent.title"></h1>
          <hr class="hr-white">
          <p class="description" :style="'color: ' + bannerContent.textColor + '!important'" v-html="bannerContent.description"></p>
          <ul class="list" :style="'color: ' + bannerContent.textColor + '!important'">
            <li v-for="item in bannerContent.list" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
var controller = new ScrollMagic.Controller();
export default {
  name: "BannerSection",
  props: ["bannerContent"],
  mounted() {

    // audience image
    new ScrollMagic.Scene({
      triggerElement: ".image-audience",
      reverse: true,
      triggerHook: 0.9
    })
      .setTween('.image-audience', 1.5, {opacity: 1, transform: 'translateY(0px)'})
      .addTo(controller);

    // // Your local image
    // new ScrollMagic.Scene({
    //   triggerElement: ".image-local",
    //   reverse: true,
    //   triggerHook: 0.9
    // })
    //   .setTween('.image-local', 1.5, {opacity: 1, transform: 'translateY(0px)'})
    //   .addTo(controller);

    // // Getting started image
    // new ScrollMagic.Scene({
    //   triggerElement: ".image-getting-started",
    //   reverse: true,
    //   triggerHook: 0.9
    // })
    //   .setTween('.image-getting-started', 1.5, {opacity: 1, transform: 'translateY(0px)'})
    //   .addTo(controller);

  }
}
</script>

<style lang="less">
@import "../../../static/styles/settings.less";

.image-audience {
  opacity: 0;
  transform: translateY(100px);
}

.image-local {
  opacity: 0;
  transform: translateY(100px);
}

.image-getting-started {
  opacity: 0;
  transform: translateY(100px);
}

.pointer {
  cursor: pointer;
}

.banner-section-container {
  padding: 2vh 0px;
  min-height: 650px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media @iPad-pro {
    //padding-left: 8% !important;
  }
  @media @tablet {
    padding-left: 7.5% !important;
  }

  @media @iPad{
    padding-left: 0 !important;
  }

  @media @mobile, @large-mobile  {
    padding-left: 0 !important;
  }

  @media @iPad, @small-desktop{
    padding-left: 0!important;
  }

  .div-row {
    display: flex;
    flex-direction: row;
    //padding-right: 50px;

    @media @mobile, @large-mobile  {
      display: flex;
      flex-direction: column-reverse;
      padding-right: 0;
    }
  }

  .div-row-reverse {
    display: flex;
    flex-direction: row-reverse;
    //padding-right: 50px;
    padding-left: 5%;
    @media @mobile, @large-mobile  {
      display: flex;
      flex-direction: column-reverse;
      padding-right: 0;
      padding-left: 0;
    }

    @media @iPad, @small-desktop, @iPad-pro{
      padding-left: 0;
    }
  }

  .banner-image {
    width: 100%;
    //max-height: 560px;
    object-fit: cover;
  }

  .title {
    color: white;
    width: 100%;
    font-size: @title-font-size;
    font-family: Roboto, Helvetica, Arial, sans-serif;

    @media @mobile, @large-mobile, @tablet {
      padding-top: 20px;
      font-size: @title-font-size-mobile;
      line-height: @title-line-height-mobile;
    }
  }

  .description {
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    line-height: @content-line-height;
    max-width: 85%;

    @media @large-mobile, @mobile{
      max-width: 90%;
    }
  }

  .list {
    color: black;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16.61px;
    line-height: @content-line-height;


  }

  .project-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    @media @mobile, @large-mobile  {
      padding-right: 10px;
      padding-left: 0;
    }
  }

  .project-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 5px;
    padding: 9vh 0;

    @media @mobile, @large-mobile{
      padding: 5vh 0;
    }

    ul {
      list-style-position: outside;
    }

    li {
      list-style: none;
      text-indent: -25px;

      @media @mobile{
        max-width: 90%;
        margin-bottom: 10px;
      }
    }

    li:before {
      content: '';
      background-color: @spaces-plus-dark-blue;
      border: 1px solid @spaces-plus-dark-blue;
      display: inline-block;
      position: relative;
      height: 10px;
      width: 10px;
      margin-right: 10px;
      bottom: 2px;
      border-radius: 5px;
    }
  }

}

.banner-section-container{
  .title{
    font-weight: 700;
  }
}

.banner-wrapper{
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  //padding-left: 5% !important;
  //height: 100%;
  @media @mobile, @large-mobile  {
    width: 90%;
  }
  @media @iPad, @iPad-pro, @small-desktop{
    width: 90%;
  }
}
</style>
