<template>
  <div v-bind:class="usedOnAboutPage ? 'client-logos-container-new-custom' : 'client-logos-container-new' " v-bind:style="{backgroundColor: bgColor}">
    <div class="logos-wrapper mx-auto">
      <h1 class="section-title">
        <span style="color: #72BF00">An established</span> <br/>and capable partner.
      </h1>
      <p class="trusted-by">The Spaces+ platform is trusted having delivered a number of forward-thinking experiences to brands leading within their prospective industries. Unlike offers currently on the market, clients come to us if they are looking to shake up the competition -  your audiences don't live within a 2D world so why provide them with a flat experience?</p>
      <div class="row align-items-center partner-logos">
        <img
          src="/images/logos-desktop.jpg"
          alt="Partner logos"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
    </div>
    <div class="client-logo-wrapper-mobile">
      <h1 class="section-title">
        <span style="color: #72BF00">An established</span> <br/>and capable partner.
      </h1>
      <p class="trusted-by">The Spaces+ platform is trusted having delivered a number of forward-thinking experiences to brands leading within their prospective industries. Unlike offers currently on the market, clients come to us if they are looking to shake up the competition -  your audiences don't live within a 2D world so why provide them with a flat experience?</p>
    </div>
    <div class="client-logo-wrapper-mobile">
      <div id="client-logo-mobile-1">
        <img class="client-logo-mobile-img" src="/images/logos1.jpg" alt="Clients logos" />
      </div>
      <div id="client-logo-mobile-2">
        <img class="client-logo-mobile-img" src="/images/logos2.jpg" alt="Clients logos"/>
      </div>
    </div>
  </div>
</template>

<script>
import { LARGE_MOBILE_MAX_WIDTH} from '../../responsive.js'
export default {
    name: 'client-logos',
    props: ["bg-color","usedOnAboutPage"],
    data(){
      return{
        icons: true
      }
    },
    mounted () {
      let bottomIcons = document.getElementById('client-logo-mobile-2')
      let topIcons = document.getElementById('client-logo-mobile-1')

      // console.log("bottomIcons: ", bottomIcons);
      // console.log("topIcons: ", topIcons);

      // fade in/out logos on mobile
      const screenWidth = document.documentElement.clientWidth;
      if(screenWidth <= LARGE_MOBILE_MAX_WIDTH) {
        if(bottomIcons && topIcons){
          setInterval(function () {
            this.icons = !this.icons
            if(this.icons){
              setTimeout(function () {
                bottomIcons.style.opacity = 0
                bottomIcons.style.transition = 'opacity 1s'
              },1000)

              setTimeout(function () {
                topIcons.style.opacity = 1
                topIcons.style.transition = 'opacity 1s'
              },1200)
            } else {
              setTimeout(function () {
                topIcons.style.opacity = 0
                topIcons.style.transition = 'opacity 1s'
              },500)

              setTimeout(function () {
                bottomIcons.style.opacity = 1
                bottomIcons.style.transition = 'opacity 1s'
              },600)
            }
          }.bind(this), 5000)
        }
      }
    }

}
</script>

<style lang="less" scoped>
@import "../../../static/styles/settings.less";

.client-logos-container-new {

    .col-6{
      @media @mobile, @large-mobile {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }

    position: relative;
    // display: block;
    width: 100%;
    padding: 100px 0;
    color: @white;
    // background: @black;

    @media @tablet {
        padding: 50px 10px;
        /*padding-bottom: 80px;*/
        min-height: 50vh;
    }
    @media @mobile, @large-mobile {
        padding: 0px 10px;
        /*padding-bottom: 80px;*/
        min-height: 50vh;
    }
    .logos-wrapper{
        width: 90%;
        max-width: 1600px;
        margin: 0px auto;
        padding-left: 5%;

        @media @tablet {
          padding-left: 0px;
          width: 90%;
        }

        @media @mobile, @large-mobile {
          display: none;
          width: 90%;
          padding-left: 3%;
          padding-right: 3%;
        }

        @media @iPad-pro{
          padding-left: 0;
          width: 90%;
        }

        @media @small-desktop{
          width: 90%;
        }
    }
    .logo-item {
        max-height: 140px;
        @media @mobile, @large-mobile, @tablet {
            /*max-width: 80px;*/
            max-height: 100px;
        }
        @media @tablet{
          max-height: 140px;
        }
    }
}

.client-logos-container-new-custom {

    .col-6{
      @media @mobile, @large-mobile {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }

    //position: relative;
    display: block;
    width: 100%;
    padding: 180px 0;
    color: @white;
    // background: @black;
    @media @mobile, @large-mobile, @tablet {
      padding: 0px 10px;
      padding-top: 3rem;
    }
    .logos-wrapper{
        padding-left: 10%;
        padding-right: 5%;
        width: 90%;
        max-width: 1600px;
        @media @mobile, @large-mobile, @tablet {
          width: 90%;
          padding-left: 3%;
          padding-right: 3%;
        }
        @media @tablet {
          width: 90%;
        }
    }
}

.client-logo-wrapper-mobile {
  @media @desktop, @large-desktop, @tablet {
    display: none;
  }
  min-height: 45vh;
  padding-top: 10vh;
}
.client-logo-mobile-img {
  margin-top: 70vh;
  width: 100vw;
  @media @mobile, @large-mobile{
    margin-top: 75vh;
  }
}

#client-logo-mobile-1, #client-logo-mobile-2{
  @media @mobile, @large-mobile{
    position: absolute;
    top: 0;
    left: 0;
    //margin-right: 1vw;

  }
}
#client-logo-mobile-1{
  @media @mobile, @large-mobile{
    opacity: 0;
  }
}

.trusted-by {
  color: @spaces-plus-dark-blue;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  //padding-left: 20px;
  font-size: @content-font-size;
  line-height: @content-line-height;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 500px;
}

.partner-logos{
  //max-width: 1200px;
  img{
    object-fit: contain;
    max-width: 100%;
  }
}

.section-title{
  font-weight: 700 !important;
  font-size: @title-font-size;
  line-height: @title-line-height;
  @media @mobile, @large-mobile, @tablet {
    font-size: @title-font-size-mobile;
    line-height: @title-line-height-mobile;
  }
}
</style>
