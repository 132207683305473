module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _vm._m(0)
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://demo.spacesplus.co",
      "frameBorder": "0",
      "allowFullScreen": "",
      "mozallowfullscreen": "true",
      "webkitallowfullscreen": "true",
      "onmousewheel": ""
    }
  })])
}]}