module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _vm._m(0)
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "feature-wrapper"
  }, [_c('div', {
    staticClass: "feature-content"
  }, [_c('h1', [_vm._v("\n      Features.\n    ")]), _vm._v(" "), _c('p', [_vm._v("\n      Spaces+ is easy to use, priced affordably and supported in-house by an all Australian team. Built to make life fun, our virtual venues offer a gamified experience providing a bold and innovative alternative to the mundane.\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "feature-logos"
  }, [_c('img', {
    attrs: {
      "src": "/images/features.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "feature-logos-mob"
  }, [_c('img', {
    attrs: {
      "src": "/images/features-mob.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })])])])
}]}