<template>
  <div class="privacy-policy" id="privacy-policy">
    <h1 class="section-title">
      Privacy Policy.
    </h1>
    <h2 class="section-title-md">
      We are devoted to your privacy.
    </h2>
    <p class="trusted-by bold">Spaces Interactive Data Protection & Privacy Policy</p>
    <p>Spaces Interactive, including its affiliate (SPACES+), is committed to respecting your privacy and as such complying with applicable data protection and privacy laws. Spaces Interactive (“us”, “we” or “our'') will ensure the protection of all restricted, confidential and sensitive data from loss, avoiding reputational damage and adverse inflictions on our customers.</p>
    <p>
      The protection of personal data in all scopes is viewed as a critical business requirement however flexibility in access to data is essential for Spaces Interactive operations - processing personal data for the sole purpose of developing services and products in compliance with (i) Contracts between Customer and Spaces Interactive; and (ii) all applicable data protection laws and regulations. The terms set out below describe how we collect, use and store personal data as well as detailed data breach prevention protocols whereby we are the data controller or where reference is made to the applicability of this policy (“Policy”). “Personal data” refers to information relating to you or another identifiable individual.
    </p>
    <p>
      If you do not agree with this Policy, do not use our products and services or provide Spaces Interactive and affiliates with your personal data.
    </p>
    <p class="trusted-by bold">Rationale</p>
    <p>
      The use, collection and storage of personal data by Spaces Interactive and associated affiliates is governed by:
      <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:32016R0679" target="_blank">The General Data Protection Regulation </a>(GDPR);
      <a href="https://iclg.com/practice-areas/data-protection-laws-and-regulations/australia" target="_blank">The Data Protection Laws and Regulations 2020</a>, Australia. As such, the Spaces Interactive Data Policy ensures the fulfilment of our duty in complying with the aforementioned Governing Bodies Laws and Regulations, enforcing internal policies and guidelines through an appropriate selection of activities. Appropriate steps will be taken internally by Spaces Interactive to address online and physical security, taking into account the risk associated with the nature of the data that is collected and stored by Spaces Interactive.
    </p>
    <p>Defined in Article 5 of the GDPR, this policy aims to ensure that personal data shall be:</p>
    <ol class="o">
      <li>processed lawfully, fairly and in a transparent manner in relation to individuals;</li>
      <li>collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes; further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes;</li>
      <li>adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;</li>
      <li>accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay;</li>
      <li>kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed; personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes subject to implementation of the appropriate technical and organisational measures required by the GDPR in order to safeguard the rights and freedoms of individuals; and</li>
      <li>processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures.</li>
    </ol>
    <p class="trusted-by bold">What information do we collect?</p>
    <p>We collect your personal data and other information when you use or register for our products and services, take part in our campaigns or otherwise interact with us. This includes the following categories:</p>
    <ol class="number">
      <li>Products and service activations - Events, Product Launches, Showrooms, Training etc.</li>
      <li>Spaces Interactive products and services requiring electronic activation - where your device, application type, network and personal identification information is sent to Spaces Interactive & associated Customers.</li>
    </ol>
    <p>When you access our products and services online, our webs servers automatically create records of your visit - typically limited to the collection of personal data related to access time, pages visited, links and features used, content viewed or requested, language and other such information (Note: This is subject to change pending Customer contracts terms). See also our Cookie Policy for more information on data collection. </p>

    <p class="trusted-by bold">Information you provide us with.</p>
    <p>You as the user provide Spaces Interactive with the majority of the data that we utilise. We collect and process data when you:</p>
    <ol>
      <li>Register online or place an order for any of our products or services</li>
      <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email</li>
      <li>Use or view our website via your browsers cookies</li>
    </ol>
    <p>We may ask for information to be provided to our servers such as your name, email address, phone number, employing company, feedback or information relating to your device, age, gender and language. Our company may also receive data directly from the following sources; commissioning customer databases. Through our third party systems, we also maintain records of your consents, preferences and settings. </p>
    <p class="trusted-by bold">Why do we process Personal Data?</p>
    <p>Spaces Interactive may collect and process your personal data for the following purposes singularly and in some cases simultaneously.</p>

    <p class="trusted-by underline">Providing products and services</p>
    <p>We may use your personal data to provide you with our products and services, to process requests, ensure the functionality and security of products and services, identify you as the user as well as preventing and investigating any breach or misuse of data. Personal data may also be used to develop and manage products, services, customer care, sales and marketing activities.</p>

    <p class="trusted-by underline">Accounts</p>
    <p>Some services and products may require account creation to assist with managing content and fulfilling customer preferences. Depending on the product or service, the creation of such an account may be voluntary or mandatory to proceed with Spaces Interactive. As such, accounts will require the provision of information to our company regarding personal identification details (name, email address, employing company and phone number). </p>

    <p class="trusted-by underline">Communicating with you</p>
    <p>In the case of changes to our services, critical alert notifications or other such news related to products/services, we may use your personal data to communicate with you. If agreed, Spaces Interactive will also in some cases share your personal data with our affiliated companies so that they can offer you their products or services.</p>

    <p class="trusted-by underline">Marketing and Advertising</p>
    <p>We may contact you in the event of a new product and service launch or promotion, using your personal data to tailor offerings and provide highly relevant services. If you have agreed to receive Spaces Interactive marketing assets, you have the right at any time to opt out. If you no longer wish to be contacted simply let us know and you will be removed from any relevant mailing lists.</p>

    <p class="trusted-by bold">How long do we retain personal data?</p>
    <p>We look to collect on personal data necessary to carry out our services. Spaces Interactive retain your personal data until we are asked/required otherwise to. The length of time is very much dependent on the purpose for which it was collected, its purpose as well as customer requirements. To store personal data, Spaces Interactive utilises Amazon RDS - a relational database in cloud that provides performance, high availability and security to the application. Further to this, RDS provides automated database administration tasks such as replications and backups. </p>

    <p class="trusted-by bold">Do we share Personal Data?</p>
    <p>We as a reputable business do not sell, lease, rent, or otherwise disclose our customers personal data to third parties or other users unless otherwise required/stated below.</p>

    <p class="trusted-by underline">Social Sharing Services</p>
    <p>In the case of social sharing services (LinkedIn, Facebook, Instagram, Publications), we may share your personal data if we have consent to do so. Further to this, our website contains links to other websites. The Spaces Interactive Data Protection Policy applies only to our website, if you click on such links it is recommended that you read their data policies. We are not legally culpable for any personal data that is collected from these external links.</p>
    <p class="trusted-by underline">Mergers and Acquisitions</p>
    <p>If we do decide to sell, buy, merge or otherwise restructure Spaces Interactive in certain countries, this may require the disclosure of personal data to prospective stakeholders.</p>
    <p class="trusted-by underline">Mandatory disclosures</p>
    <p>We may be obligated by mandatory law to disclose your personal data to specific authorities and third parties for example credit reference agencies or law enforcement agencies in the countries where we or third parties act on our behalf. We may also disclose personal data in accordance with applicable law to defend Spaces Interactive’s legitimate interests including and not limited to legal proceedings or governmental requests and filings.</p>

    <p class="trusted-by bold">What steps are taken to safeguard personal data?</p>
    <p>Safeguarding privacy and security of all customer personal data is a top of mind when developing our products and services. Spaces Interactive enforces internal policies, guidelines and data leakage protocols through an appropriate assortment of activities including and not limited to proactive vulnerability management and assessments, security and privacy software engineering and monitoring as well as regular, mandatory employee training. To further ensure safety and security, we also limit access to our platforms and databases containing personal data to authorised personnel, approving justifications for access beforehand.</p>

    <p class="trusted-by bold">How do we use cookies?</p>
    <p>
      Spaces Interactive uses cookies to operate and improve the user experience with our website and relevant offerings. In particular our website utilises cookies relating to functionality and advertising. Please see <a href="https://www.allaboutcookies.org/" target="_blank">allaboutcookies.org</a> to find out more about how cookies are used on the Spaces Interactive website and how you can disable cookies within your own browser settings.
    </p>
    <p class="trusted-by bold">Know your rights</p>
    <p>
      Spaces Interactive would like to make it clear that you have the right to know what data we use, collect and hold about you as the customer. As part of complying with Laws & Regulations, every user has the right to:
    </p>
    <ol>
      <li>Have incomplete, incorrect, unnecessary, or outdated personal data updated by Spaces Interactive, under certain conditions</li>
      <li>Object or restrict processing of personal data such as unlawful practice or inaccuracies, under certain conditions </li>
      <li>Request personal data be erased, under certain conditions</li>
      <li>Request the transfer of data collected to another organisation, or directly to you under certain conditions </li>
      <li>Request copies of your personal data in a machine-readable format</li>
    </ol>
    <p>You may exercise your rights by contacting us, with Spaces Interactive legally obligated to respond to requests within the period of one month. Please note, the applicable data protection laws may present restrictions on the extent to which your rights can be exercised. If such a circumstance arises, we will respond with an appropriate explanation of actions to then be taken as well as the extent to which we are required to fulfil such request under applicable law.</p>
    <p class="trusted-by bold">Who is the controller of your personal data?</p>
    <p>
      Spaces Interactive, Suite 73/61 Marlborough Street Surry Hills, Sydney, Australia is the controller of your personal data.
    </p>
    <p>In addition the Spaces Interactive affiliate (SPACES+) providing the product or service may be classified as a controller of your personal data. You will find the identity of the controller and any other contact details by reviewing the terms and conditions as set out in the customer contract.</p>
    <p>In matters relating to Spaces Interactive data privacy practices you may contact the following:</p>
    <p>Name: Michael Dries</p>
    <p>Email: hello@spacesinteractive.co</p>
    <p class="trusted-by bold">Changes to this policy</p>
    <p>Keeping our policy under regular review, Spaces Interactive may change, modify or withdraw access to this site at any time or without notice. If any material changes are substantial or adversely impact customers, Spaces Interactive will advise customers on any changes. We recommend that you regularly review this policy from time to time to learn of any changes.
      <br/>
      This policy was last updated on: 30th March 2021.
    </p>

  </div>
</template>

<script>
export default {
    name: 'privacy-policy',
    props: [],
    data(){
      return{
      }
    },
    mounted () {
      const elmnt = document.getElementById("privacy-policy");
      elmnt.scrollIntoView();
    }

}
</script>

<style lang="less" scoped>
@import "../../static/styles/settings.less";

.privacy-policy {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 3%;
  padding-top: 100px;
  padding-bottom: 100px;
  @media @mobile, @large-mobile {
    padding-left: 0 !important;
    width: 90%;
  }

  .section-title{
    font-weight: 700 !important;
    font-size: @title-font-size;
    line-height: @title-line-height;
    margin-top: 15px;
    margin-bottom: 35px;
    @media @mobile, @large-mobile, @tablet {
      font-size: @title-font-size-mobile;
      line-height: @title-line-height-mobile;
    }
  }
  .section-title-md{
    font-weight: 700 !important;
    font-size: 35px;
    line-height: 45px;
    margin-top: 25px;
    margin-bottom: 25px;
    @media @mobile, @large-mobile, @tablet {
      font-size: @title-font-size-mobile;
      line-height: @title-line-height-mobile;
    }
  }

  p, ol{
    font-size: @content-font-size;
    line-height: @content-line-height;
    color: #212529;
  }
  p{
    margin-top: 10px;
  }
  .bold{
    font-weight: bold;
    margin-top: 50px;
  }
  .underline{
    text-decoration: underline;
    margin-top: 20px;
  }

  ol{
    list-style-type: inherit
  }

  .number{
    list-style-type: decimal;
  }

  .o{
    list-style-type: upper-alpha;
  }
}
</style>
