<template>
  <section class="video-wrapper">
    <div class="video-container">
      <video
        muted=""
        playsinline=""
        crossorigin=""
        :loop="true"
        :autoplay="true"
        preload="auto"
        class="projects-video"
        :data-src="videoContent.videoUrl"
        :src="videoContent.videoUrl"
      >
      </video>
    </div>
    <div class="clearfix"></div>
  </section>
</template>

<script>

export default {
  components:{},
  data() {
    return {}
  },
  props: ['videoContent'],
  computed: {},
  beforeDestroy() {},
  mounted() {},
  methods:{}
}
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: black;

  .ar-text{
    padding-left: 5vw;
    //padding-top: 10vh;
    //padding-bottom: 10vh;
    @media @mobile, @large-mobile, @tablet, @iPad, @iPad-pro {
      padding-left: 0;
    }
    @media @iPad, @iPad-pro {
      padding-top: 0;
      padding-bottom: 10vh !important;
    }
  }

  .video-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .project-one-container-new{
    margin: 0 auto !important;
    width: 90%;
    background: black;
    //padding: 60px;
    padding-bottom: 30px;
    color: white;

    p{
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 34px;
      font-weight: 300;
      line-height: 44px;
      max-width: 50%;

      @media @mobile, @large-mobile, @tablet {
        max-width: 95%;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
  @media @tablet {
    padding-top: 5vh;
    margin-bottom: 0px;
  }

  @media @mobile, @large-mobile {
    padding-top: 0;
    //margin-bottom: 5vh;
  }
  .projects-video {
    width: 100% !important;

    video{
      width: 100% !important;
    }
  }
}
</style>
