
/* styles */
require("!!../../node_modules/extract-text-webpack-plugin/dist/loader.js?{\"omit\":1,\"remove\":true}!vue-style-loader!css-loader?sourceMap&-autoprefixer!../../node_modules/vue-loader/lib/style-compiler/index?{\"id\":\"data-v-0760fb5c\",\"scoped\":true,\"hasInlineConfig\":false}!less-loader?sourceMap!../../node_modules/vue-loader/lib/selector?type=styles&index=0!./PrivacyPolicy.vue")

var Component = require("!../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../node_modules/vue-loader/lib/selector?type=script&index=0!./PrivacyPolicy.vue"),
  /* template */
  require("!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-0760fb5c\"}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./PrivacyPolicy.vue"),
  /* scopeId */
  "data-v-0760fb5c",
  /* cssModules */
  null
)

module.exports = Component.exports
