var Component = require("!../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  null,
  /* template */
  require("!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-fb8a9e3a\"}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./Logo.vue"),
  /* scopeId */
  null,
  /* cssModules */
  null
)

module.exports = Component.exports
