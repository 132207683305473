import Vue from 'vue'
import App from './components/App'
import VuePageTransition from './components/VuePageTransition/VuePageTransition'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'G-VYZKG0FN0Z',
  router
})

// FontAwesome Icons
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

// custom component for page transition
Vue.component('vue-page-transition', VuePageTransition)

import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faArrowRight)
library.add(faStopwatch)
library.add(faChartLine)

Vue.component('font-awesome-icon', FontAwesomeIcon)

//  Animation on scroll
// bug!!!!!
import AOS from 'aos'
// import 'aos/dist/aos.css'
AOS.init({
  offset: 120,
  delay: 50,
  duration: 1900
})

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// smooth scroll
import vueSmoothScroll from 'vue2-smooth-scroll'
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.use(vueSmoothScroll)
Vue.directive('observe-visibility', ObserveVisibility)

import { CarouselPlugin } from 'bootstrap-vue'
Vue.use(CarouselPlugin)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const app = new Vue({
  router,
  store,
  ...App
})

export { app, router, store }
