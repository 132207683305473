<template>
  <div class="configurator" id="content">
    <div class="config-content">
      <div v-if="!displayForm" class="questions">
        <div class="your-event">
          <!--   Your Event Header   -->
          <div class="row-item your-event-title justify-content-start">
            <div @click="goBack()" aria-label="go-back" role="button" class="col-1" style="padding-right: 0">
              <b-icon icon="arrow-left" class="icon"/>
            </div>
            <h2 class="col-11" style="padding-left: 0">Your<span> Event</span></h2>
          </div>
          <div v-if="this.currentQuestionIndex < this.questions.length - 1" class="row-item your-event-title">
            <h3>Q{{this.currentQuestionIndex+1}} of {{this.questions.length - 1}}</h3>
          </div>
          <div v-if="this.currentQuestionIndex < this.questions.length - 1" class="row-item your-event-title">
            <h4>
              {{this.currentQuestion.title}}
            </h4>
          </div>

          <div v-if="this.currentQuestion.imageUrl" class="row-item image-row">
            <img :src="this.currentQuestion.imageUrl" alt="Room image">
          </div>
          <b-form-group v-slot="{ ariaDescribedby }">
            <div v-if="currentQuestion && currentQuestion.options" class="row-item options-row">
              <div class="col-sm-12 col-md-12 col-lg-6" style="padding-right: 30px; padding-left: 0">
                <div>
                  <div
                    v-if="currentQuestion && currentQuestion.options"
                    v-for="item in currentQuestion.options"
                    :key="item.id"
                    class="button-wrapper"
                  >
                    <div
                      class="row-item light-blue-btn"
                      @click="handleSelect(item)"
                    >
                      <b-form-radio
                        v-model="item.id"
                        name="answer"
                        :value="item.value"
                        class="custom-control-radio"
                        :aria-describedby="ariaDescribedby"
                      >
                        <div class="custom-label">
                        <span>
                          {{item.option}}
                        </span>
                          <b-icon v-if="item.icon" :icon="item.icon" class="icon"/>
                        </div>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6" style="padding-right: 0;">
                <div>
                  <div
                    v-if="currentQuestion && currentQuestion.options2"
                    v-for="item in currentQuestion.options2"
                    :key="item.id"
                    class="button-wrapper"
                  >
                    <div
                      class="row-item light-blue-btn"
                      @click="handleSelect(item)"
                    >
                      <b-form-radio
                        v-model="item.id"
                        name="answer"
                        :value="item.value"
                        :aria-describedby="ariaDescribedby"
                        class="custom-control-radio"
                      >
                        <div class="custom-label">
                        <span>
                          {{item.option}}
                        </span>
                          <b-icon v-if="item.icon" :icon="item.icon" class="icon"/>
                        </div>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--  Select theme   -->
            <div v-if="currentQuestion && currentQuestion.images" class="row-item options-row">
              <div class="col-sm-12 col-md-12" style="padding-right: 0">
                <div class="row-item options-row">
                  <div class="col-12" style="padding-right: 0; padding-left: 0">
                    <h5 class="font-weight-bold text-center">Thank you for taking the time to provide this information.</h5>
                    <br/>
                    <h5 class="text-center">
                      Based upon your answers to our questions our recommended Spaces+ layout and inclusions are:
                    </h5>
                    <div v-for="(recommendation, index) in emailData" :key="recommendation.question" class="question-wrapper" style="padding-right: 0">
                      <ul v-if="recommendation.feature" class="row-item justify-content-center">
                        <li>
                          {{recommendation.feature}}
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <h5 class="text-center">
                      Please select a theme that suits your project:
                    </h5>
                  </div>
                </div>
                <div class="form-group-custom row">
                  <div
                    v-if="currentQuestion && currentQuestion.images"
                    v-for="item in currentQuestion.images"
                    :key="item.id"
                    class="button-wrapper col-images col-sm-12 col-md-3"
                  >
                    <img :src="item.imageUrl" alt="theme image" class="theme-image"/>
                    <div
                      class="row-item light-blue-btn"
                      @click="handleSelect(item)"
                    >
                      <b-form-radio
                        v-model="item.id"
                        name="answer"
                        :value="item.value"
                        class="custom-control-radio"
                      >
                        <div class="custom-label">
                          <span>
                            {{item.option}}
                          </span>
                          <b-icon v-if="item.icon" :icon="item.icon" class="icon"/>
                        </div>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div @click="goNext()" aria-label="go-back" role="button" class="btn-next">
              Next
            </div>
          </b-form-group>

        </div>
        <p class="error">{{messageError}}</p>
      </div>

      <!--  contact form    -->
      <div v-else-if="displayForm" class="questions">
        <div class="your-event contact-form">
          <div class="row-item your-event-title justify-content-start">
            <div @click="goBack()" aria-label="go-back" role="button" class="col-1" style="padding-left: 0; padding-right: 0">
              <b-icon icon="arrow-left" class="icon"/>
            </div>
            <h2 class="col-11" style="padding-left: 0">Your<span> Event</span></h2>
          </div>
          <div class="row-item">
            <ContactForm v-bind:emailData="emailData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "./HomeComponents/ContactForm";
export default {
  name: "Configurator",
  components: {ContactForm},
  data() {
    return {
      questions: [
        {
          id: 1,
          title: "What is the main thing you will use Spaces+ for?",
          status: true,
          answer: "",
          feature: "",
          options: [
            {
              id: "op1",
              value: "Virtual Conference",
              option: "Virtual Conference",
              icon: "film"
            },
            {
              id: "op2",
              value: "Virtual Product Launch",
              option: "Virtual Product Launch",
              icon: "shop-window"
            },
            {
              id: "op3",
              value: "Virtual Trade Show",
              option: "Virtual Trade Show",
              icon: "truck"
            },
            {
              id: "op4",
              value: "Virtual Sales Team Meetings",
              option: "Virtual Sales Team Meetings",
              icon: "clipboard-data"
            },
            {
              id: "op5",
              value: "Hybrid Event",
              option: "Hybrid Event",
              icon: "person-badge"
            },
          ],
          options2: [
            {
              id: "op6",
              value: "Virtual Open Day",
              option: "Virtual Open Day",
              icon: "table"
            },
            {
              id: "op7",
              value: "Virtual Worship",
              option: "Virtual Worship",
              icon: "flower2"
            },
            {
              id: "op8",
              value: "Virtual Networking",
              option: "Virtual Networking",
              icon: "people"
            },
            {
              id: "op9",
              value: "Virtual Training",
              option: "Virtual Training",
              icon: "puzzle"
            },
            {
              id: "op10",
              value: "Other",
              option: "Other",
              icon: "chat-left-text"
            },
          ]
        },
        {
          id: 2,
          title: "Do you have either pre-recorded or live video content?",
          status: false,
          answer: "",
          feature: "Auditorium",
          imageUrl: "/images/questions/Q2.png",
          options: [
            {
              id: "2-yes",
              value: "yes",
              option: "Yes, I do have videos",
            },
          ],
          options2:[
            {
              id: "2-no",
              value: "no",
              option: "No, I don’t want videos"
            }
          ]
        },
        {
          id: 3,
          title: "Do you want visitors to the platform to be able to ask live questions?",
          status: false,
          answer: "",
          feature: "Q&A",
          imageUrl: "/images/questions/Q3.png",
          options: [
            {
              id: "3-yes",
              value: "yes",
              option: "Yes, I do."
            },
          ],
          options2:[
            {
              id: "3-no",
              value: "no",
              option: "No, I don't"
            }
          ]
        },
        {
          id: 4,
          title: "Do you have a Product or Products that you would like to showcase?",
          status: false,
          answer: "",
          feature: "Product Gallery",
          imageUrl: "/images/questions/Q4.png",
          options: [
            {
              id: "4-yes",
              value: "yes",
              option: "Yes, I do have product(s)."
            },
          ],
          options2:[
            {
              id: "4-no",
              value: "no",
              option: "No, I don't have product(s)"
            }
          ]
        },
        {
          id: 5,
          title: "Do you want visitors to your experience to be able to network with each other?",
          status: false,
          answer: "",
          feature: "Networking Room",
          imageUrl: "/images/questions/Q5.png",
          options: [
            {
              id: "5-yes",
              value: "yes",
              option: "Yes, I do."
            },
          ],
          options2:[
            {
              id: "5-no",
              value: "no",
              option: "No, I don't"
            }
          ]
        },
        {
          id: 6,
          title: "Please select a theme that suits your project:",
          status: false,
          answer: "",
          images: [
            {
              id: "6-modern-minimalist",
              value: "modern-minimalist",
              option: "Modern/Minimalist",
              imageUrl: "/images/carousel/9.jpg",
            },
            {
              id: "6-traditional",
              value: "traditional",
              option: "Traditional",
              imageUrl: "/images/questions/Q4.png",
            },
            {
              id: "6-real-world",
              value: "real-world",
              option: "Real World",
              imageUrl: "/images/carousel/6.jpg",
            },
            {
              id: "6-other-worldly",
              value: "other-worldly",
              option: "Other-Worldly",
              imageUrl: "/images/carousel/8.jpg",
            },
          ]
        }
      ],
      currentQuestionIndex: 0,
      currentQuestion: [],
      hasSelect: null,
      displayForm: null,
      messageError: "",
      emailData: [{},{},{},{},{},{}]
    }
  },
  mounted() {
    this.currentQuestion = this.questions[0];

    // console.log('email data', this.emailData)
    // console.log('displayRecommendation', this.displayRecommendation)
  },
  methods: {
    goBack(){
      this.displayForm = false
      // console.log('this.currentQuestionIndex', this.currentQuestionIndex)
      // console.log('this.this.questions', this.questions.length)

      if(this.currentQuestionIndex > 0)
        this.currentQuestionIndex--;

      this.setCurrentQuestion();
    },
    goNext(){
      if(!this.hasSelect && this.emailData[this.currentQuestionIndex].question === undefined){
        this.messageError = "Please select an option.";
        return
      }

      // if its not the last question then we move to the next question
      if(this.currentQuestionIndex < this.questions.length){
        this.hasSelect=false
        this.currentQuestionIndex++;
      }

      // if its the last question then we display the form
      if(this.currentQuestionIndex === this.questions.length){
        this.displayForm = true
      }

      this.setCurrentQuestion();
      this.messageError=""

      // console.log(this.currentQuestionIndex)
      // console.log(this.emailData)
      const elmnt = document.getElementById("content");
      elmnt.scrollIntoView();
    },
    handleSelect(item){
      // console.log({item});

      this.hasSelect = true;
      this.messageError=""

      this.emailData[this.currentQuestionIndex].question = this.currentQuestion.title
      this.emailData[this.currentQuestionIndex].answer = item.option

      if(this.currentQuestionIndex === 0)
        this.emailData[this.currentQuestionIndex].feature = item.value
      else
        this.emailData[this.currentQuestionIndex].feature = item.value === 'yes' ? this.currentQuestion.feature : ''
    },
    setCurrentQuestion(){
      this.questions.forEach((question, index) => {
        if (index === this.currentQuestionIndex){
          question.status = true;
          this.currentQuestion = question;
        } else {
          question.status = false;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../static/styles/settings.less";

.configurator{
  background: white;
}
.config-content {
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 5% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media @tablet {
    padding-bottom: 0px;
  }

  @media @mobile, @large-mobile, @tablet, @iPad {
    width: 100%;
    padding-left: 0 !important;
    padding-top: 5vh;
    flex-direction: column;
  }

  @media @small-desktop, @iPad-pro{
    width: 95%;
    padding-left: 0 !important;
  }
  @media @iPad{
    padding-top: 5vh !important;
    padding-bottom: 5vh !important;
  }

  .questions{
    background-color: white;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    width: 80%;

    .your-event{
      @media @mobile, @large-mobile, @tablet, @iPad{
        max-width: 95%;
        margin: 0 auto;
      }
    }

    .your-event-title{
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      h4{
        text-align: center;
      }

      @media @mobile, @large-mobile, @tablet, @iPad{
        margin-bottom: 20px;
      }
    }
    @media @mobile, @large-mobile, @tablet, @iPad{
      width: 100%;
      padding-bottom: 5vh;
      border: none;
    }

    @media @iPad-pro{
      width: 95%;
    }
    .contact-form{
      max-width: 95%;
      margin: 0 auto;
      @media @mobile, @large-mobile, @tablet, @iPad{
        max-width: 90%;
      }

      .col-2{
        padding-left: 0;
        @media @mobile, @large-mobile, @tablet, @iPad{
          padding-left: 15px;
        }
      }
    }
  }

  .need-help{
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    padding-bottom: 15vh;
    width: 50%;
    @media @mobile, @large-mobile, @tablet, @iPad{
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;

      .questions-body{
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    @media @iPad-pro{
      width: 40%;
    }
  }

  .image-row{
    @media @mobile, @large-mobile, @iPad, @iPad-pro{
      max-height: 30vh;
    }
  }
  .row-item{
    display: flex;
    flex-direction: row;
    max-width: 100%;

    @media @mobile, @large-mobile, @tablet, @iPad{
      padding-left: 10px;
      padding-right: 10px;
    }

    img{
      width: 100%;
      max-width: 650px;
      margin: 0 auto 5vh;
      object-fit: contain;
    }
    li{
      margin-left: 10px;
    }
  }

  .mobile-only{
    display: none;

    @media @mobile, @large-mobile, @tablet, @iPad{
      display: block;
      margin-bottom: 20px;
    }
  }

  .options-row{
    justify-content: space-between;
    @media @mobile, @large-mobile, @tablet, @iPad{
      display: flex;
      flex-direction: column;
    }
  }

  .light-blue-btn{
    border: 2px solid #9FCEE2;
    width: 100%;
    border-radius: 10px;
    height: 60px;
    margin-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    P{
      color: @spaces-plus-dark-blue;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .custom-control-radio{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    span{
      font-size: 14px;
      @media @iPad-pro{
        font-size: 13px;
      }
    }

    .custom-label{
      display: flex !important;
      flex-direction: row !important;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-top: auto;
      margin-bottom: auto;
      span{
        height: 100%;
        padding-right: 10px;
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
      }
    }
    .custom-control-input{
      padding-top: 10px;
    }
  }

  .form-group-custom{
    display: flex;
    flex-direction: row;
    justify-content: center;

  }

  .button-wrapper{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
    .theme-image{
      max-width: 100%;
      margin: 2vh auto;
    }
  }

  .col-images{
    display: flex;
    flex-direction: column;
    @media @mobile{
      max-width: 95%;
    }
  }

  h1{
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    margin-left: 30px;
    margin-bottom: 75px;
    max-width: 100%;
  }
  h2{
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    margin-left: 30px;
    margin-bottom: 75px;
    max-width: 100%;

    @media @mobile, @large-mobile, @tablet, @iPad{
      margin-left: 55px;
      margin-bottom: 40px;
    }
  }
  h2{
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: @spaces-plus-dark-blue;
    margin: 0;
    span{
      color: @spaces-plus-light-green;
    }
  }
  h3{
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: @spaces-plus-dark-blue;
    margin: 0;
  }
  p{
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 0;
    max-width: 100%;
  }

  .circle{
    min-width: 26px;
    width: 26px;
    height: 26px;
    background-color: white;
    border: 2px solid white;
    border-radius: 13px;
    margin-right: 20px;

    &.disabled{
      border: 2px solid rgba(255, 255, 255, 0.5);
      background-color: unset;
    }

    @media @mobile, @large-mobile, @tablet, @iPad{
      margin-right: 0px;
      min-width: 20px;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 2px solid white;
      border-radius: 10px;
    }
  }
  .question-text{
    color: white;
    max-width: 95%;
    &.disabled{
      color: rgba(255, 255, 255, 0.5);
    }

    @media @mobile, @large-mobile, @tablet, @iPad {
      display: none;
    }
  }

  .bar{
    background-color: rgba(255, 255, 255, 0.5);
    width: 2px;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 11px;
    @media @mobile, @large-mobile, @tablet, @iPad{
      width: 15px;
      height: 2px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
  .question-wrapper{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 150px;

    ul{
      margin-bottom: 5px!important;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
    }

    @media @mobile, @large-mobile, @tablet, @iPad{
      padding-right: 0px;
      flex-direction: row;
    }

    @media @iPad-pro{
      padding-right: 80px;
    }
  }
  .question-wrapper:last-child{
    .bar{
      display: none;
    }
  }

  .icon{
    width: 25px;
    height: 25px;
  }

  .btn-next {
    height: 45px;
    width: 130px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #72BF00;
    background: #72BF00;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;

    @media @mobile, @large-mobile, @tablet, @iPad{
      margin-top: 5vh;
    }
  }

  .error{
    margin-top: 0;
    margin-right: 10px;
    color: red;
    font-size: 12px;
    text-align: center;

    @media @mobile, @large-mobile{
      margin-right: 10vw;
    }
    @media @tablet, @iPad{
      margin-right: 10vw;
    }
  }

  .small-margin{
    margin-bottom: 50px !important;
  }

  .form-group{
    @media @mobile, @large-mobile, @tablet, @iPad{
      margin-bottom: 0;
    }
  }
  .col-sm-12{
    @media @mobile, @large-mobile, @tablet, @iPad {
      padding-left: 0;
    }
  }

  .contact-form{
    .options-row{
      h5{
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
      }
      @media @mobile, @large-mobile, @tablet, @iPad {
        margin: 0 auto;
      }
    }
  }

  .col-lg-5{
    @media @iPad-pro{
      padding: 0;
    }
  }
  .col-lg-6{
    padding-left: 30px;
    @media @mobile, @large-mobile, @tablet, @iPad {
      padding: 0;
    }
    @media @iPad-pro{
      padding: 0;
    }
  }
  .col-sm-12{
    @media @mobile, @large-mobile, @tablet, @iPad {
      padding: 0 !important;
    }
  }
  .col-1{
    max-width: 50px;
    margin-left: 0;
  }
}
</style>
