module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "modal",
    staticStyle: {
      "padding-right": "0!important"
    },
    attrs: {
      "id": "getStarted",
      "role": "dialog"
    }
  }, [_c('div', {
    staticClass: "row custom-row modal-wrapper"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6 contact-address-container--menu"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "nav-container-menu nav-container-menu--new"
  }), _vm._v(" "), _c('h1', {
    staticClass: "get-started-title"
  }, [_vm._v("\n        Get started.\n      ")]), _vm._v(" "), _c('p', {
    staticClass: "get-started-content"
  }, [_vm._v("\n        The Spaces+ team would be happy to arrange a time with you to discover what you are looking for and to share a range of possibilities.\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "book-a-demo-green",
    attrs: {
      "role": "button",
      "data-toggle": "modal",
      "data-target": "#demoBooking",
      "aria-label": "button"
    },
    on: {
      "click": function($event) {
        return _vm.gtag_report_conversion("https://spacesplus.co/book-a-demo")
      }
    }
  }, [_c('span', [_vm._v("Book a Consultation")])]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_vm._v("hello@spacesplus.co")]), _vm._v(" "), _vm._m(1)]), _vm._v(" "), _c('div', {
    staticClass: "col-sm-12 col-md-6 contact-form-container"
  }, [_vm._m(2), _vm._v(" "), _c('ContactForm')], 1)])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "menu-logo"
  }, [_c('img', {
    staticClass: "little-m hoverable",
    attrs: {
      "src": require("../../../static/images/Spaces+Dark.png"),
      "alt": "Company logo"
    }
  })])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "location-row"
  }, [_c('p', [_vm._v("\n          SYDNEY"), _c('br'), _vm._v("\n          Suite 73, 61 Marlborough Street\n          "), _c('br'), _vm._v("Surry Hills 2010\n          "), _c('br'), _vm._v("+612 8231 6469\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "melb"
  }, [_vm._v("\n          MELBOURNE"), _c('br'), _vm._v("\n          101 Moray Street\n          "), _c('br'), _vm._v("South Melbourne 3205\n          "), _c('br'), _vm._v("+613 7064 0501\n        ")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "close-btn--menu"
  }, [_c('img', {
    staticClass: "cross magnetic-button hoverable",
    attrs: {
      "role": "button",
      "data-dismiss": "modal",
      "offset-hover-max": "2",
      "offset-hover-min": "1",
      "src": "/images/black_cross.png",
      "alt": "close button"
    }
  })])
}]}