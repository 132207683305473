<template>
  <div class="":style="lottieStyle">
    <div :style="loaderStyle" class="lottie-loader-container"></div>
    <div :style="style" class="loading-icon" ref="lavContainer"></div>
  </div>
</template>

<script>
  import lottie from 'lottie-web';
  export default {
    props: {
      lottieStyle: {
        type: Object
      },
      options: {
        type: Object,
        required: true
      },
      arrayData: {
        type: Object,
        required: false
      },
      height: Number,
      width: Number,
      showLoader: Boolean
    },
    data () {
      return {
        style: {
          width: this.width ? `${this.width}px` : '100%',
          height: this.height ? `${this.height}px` : '100%',
          overflow: 'hidden',
          margin: '0 auto'
        }
      }
    },
    watch: {
      // arrayData: function(newVal, oldVal) { // watch it
      //   // THIS WAS A START TO CONTROL LOTTIE USING SCROLL!
      //   // ITS NOT 100% YET
      //
      //   console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      //   console.log('this anim', this.anim)
      //   console.log('currentFrame = ', this.anim.currentFrame)
      //   this.anim.pause()
      //   if(newVal.isMovingDown){
      //     this.anim.setDirection(1)
      //     console.log('playDirection 1 = ', this.anim.playDirection)
      //     // this.anim.goToAndPlay(this.anim.currentFrame, true);
      //     // this.anim.playSegments([this.anim.currentFrame, 500], true)
      //     // this.anim.play()
      //   } else {
      //     this.anim.setDirection(0)
      //     this.anim.playDirection = 0
      //     console.log('playDirection 0 = ', this.anim.playDirection)
      //     this.anim.goToAndPlay(this.anim.currentFrame, true);
      //     // this.anim.playSegments([this.anim.currentFrame,0], true)
      //     // this.anim.play()
      //   }
      //   // this.anim.play()
      //   // console.log('playDirection = ', this.anim.playDirection)
      // }
    },
    computed: {
      loaderStyle() {
        this.showLoader ? "opacity:1" : "opacity:0"
      },
    },
    mounted () {
      this.anim = lottie.loadAnimation({
          container: this.$refs.lavContainer,
          renderer: 'svg',
          loop: this.options.loop !== false,
          autoplay: this.options.autoplay !== false,
          animationData: this.options.animationData,
          rendererSettings: this.options.rendererSettings
        }
      );
      this.$emit('animCreated', this.anim)
    }
  }
</script>

<style lang="less">
.loading-icon {
  position: absolute;
  top: calc(50% - 160px);
  left: calc(50% - 160px);
}
.lottie-loader-container {
  width: 100vw;
  height: 100vh;
}
</style>
