<template lang="html">
  <nav id="navbar">
    <div id="navbar-desktop" class="logo-burger">
      <div class="row justify-content-between align-items-center">
        <div class="col-sm-3 col-md-3 col-lg-3">
          <div @click="navigateTo('home', 'mirk-navbar')" aria-label="mirk-navbar" role="button">
            <img role="button" class="mlogo" src="../../static/images/Logo.png" alt="Spaces+">
          </div>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9">

          <div class="row justify-content-between align-items-center">
            <div>
              <div class="hoverable">
                <div class="nav-button" @click="navigateTo('home', 'features')" aria-label="features" role="button">
                  <span>Features</span>
                </div>
              </div>
            </div>
            <div>
              <div class="hoverable">
                <div class="nav-button" @click="navigateTo('home', 'packages')" aria-label="packages" role="button">
                  <span>Packages</span>
                </div>
              </div>
            </div>
            <div>
              <div class="hoverable">
                <div class="nav-button" @click="navigateTo('home', 'how-it-works')" aria-label="packages" role="button">
                  <span>How It Works</span>
                </div>
              </div>
            </div>
            <div>
              <div class="hoverable">
                <div class="nav-button" @click="navigateTo('home', 'configurator')" aria-label="configurator" role="button">
                  <span>Find your Space</span>
                </div>
              </div>
            </div>
            <div>
              <div class="hoverable">
                <div
                  class="nav-button-get-started"
                  role="button"
                  aria-label="packages"
                  data-toggle="modal"
                  data-target="#demoBooking"
                  @click="gtag_report_conversion(`https://spacesplus.co/book-a-demo`)"
                >
                  <span>Book a Consultation</span>
                </div>
              </div>
            </div>
            <div>
              <div class="hoverable">
                <div
                  class="nav-button-book-demo"
                  aria-label="book-a-demo"
                  role="button"
                >
                  <router-link to="/demo" class="text" target="_blank">
                    Launch Demo
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <logo-burger id="navbar-mobile" mirkSmallLogoColor="#ffffff"></logo-burger>
  </nav>
</template>

<script>

  import LogoBurger from "./LogoBurger";
  export default {
    components: {LogoBurger},
    data() {
      return {
        scrollDown: false,
        lastScrollTop: 0
      }
    },
    mounted() {
      /* CONVERSION - GOOGLE ADS */
      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());
      this.gtag('config', 'AW-654851161');
    },
    methods: {
      gtag(){
        /* CONVERSION - GOOGLE ADS */
        // console.log("gtag ", arguments)
        dataLayer.push(arguments);
      },
      gtag_report_conversion(url) {
        /* CONVERSION - GOOGLE ADS */
        // console.log("gtag_report_conversion ", url)
        var callback = function () {
          // console.log("callback")
          // if (typeof(url) != 'undefined') { window.location = url; }
        };
        this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/fSRZCIq24f8BENn4oLgC', 'event_callback': callback });
        return false;
      },
      toggleMenu() {
        this.$store.state.showMenu = !this.$store.state.showMenu;
      },
      navigateTo(destination, data) {
        // console.log("data ", data)
        // console.log("nav ", this.props)
        this.$router.push({
          name: destination,
          params: {data: data}
        });
        let doc = document.getElementById(data)
        if(doc){
          // wait some time then scroll
          setTimeout(function() {
            const y = doc.getBoundingClientRect().top + window.scrollY;
            let height = window.screen.height;
            // offset 14% of the height
            let offsetHeight = height*.07
            window.scroll({
              top: y-offsetHeight
            });
          }.bind(this), 200)
        }
      },
      handleScroll() {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        // upscroll code

        // console.log("window.pageYOffset ", window.pageYOffset)

        if (window.pageYOffset < 200) {
          document.getElementById('navbar').style.webkitTransform = "translateY(0px)"
          document.getElementById('navbar').style.MozTransform = "translateY(0px)"
          document.getElementById('navbar').style.OTransform = "translateY(0px)"
          document.getElementById('navbar').style.transform = "translateY(0px)"
        } else {
          // console.log("this.lastScrollTop ", this.lastScrollTop)
          // console.log("st ", st)
          if (st > this.lastScrollTop) {
            document.getElementById('navbar').style.webkitTransform = "translateY(100px)"
            document.getElementById('navbar').style.MozTransform = "translateY(100px)"
            document.getElementById('navbar').style.OTransform = "translateY(100px)"
            document.getElementById('navbar').style.transform = "translateY(100px)"
          } else if (window.pageYOffset < 200) {
            document.getElementById('navbar').style.webkitTransform = "translateY(0px)"
            document.getElementById('navbar').style.MozTransform = "translateY(0px)"
            document.getElementById('navbar').style.OTransform = "translateY(0px)"
            document.getElementById('navbar').style.transform = "translateY(0px)"
          }
        }
        this.lastScrollTop = st <= 0 ? 0 : st;
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
  }
</script>

<style lang="less">
  @import "../../static/styles/settings.less";
  nav {
    position: fixed;
    display: block;
    top: -100px;
    left: 0px;
    background-color: @spaces-plus-dark-blue;
    height: 85px;
    width: 100%;
    max-width: 100vw;
    z-index: 999;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    -o-transition: all 0.35s;
    transition: all 0.35s;
    border-radius: 1px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);

    @media @mobile, @large-mobile{
      height: 75px;
    }
    .logo-burger {
      width: 95%;
      margin: 0 auto;
      padding-top: 13px;
      padding-bottom: 13px;
      max-width: 1600px;
      background-color: @spaces-plus-dark-blue;

      @media @mobile, @large-mobile {
        // padding-top: 25px;
        //width: 95%;
        max-width: 100%;
        width: 93%;
      }

      .col-sm-4, .col-sm-3 {
        @media @mobile, @large-mobile {
          max-width: 50%;
        }
      }

      .col-sm-8, .col-sm-9{
        @media @mobile, @large-mobile {
          max-width: 50%;
        }
      }

      .hamburger {
        z-index: 12;
        cursor: pointer;
        display: inline-block;
        float: right;
        margin-top: -3px;
        img {
          @media @large-desktop {
            width: 28px;
          }

          @media @desktop {
            width: 28px;
          }

          @media @tablet {
            width: 25px;
          }

          @media @mobile, @large-mobile, @large-mobile {
            width: 19px;
          }
        }
      }

      g {
        fill: white;
      }

      .mlogo {
        //margin-left: 10px;
        width: auto;
        cursor: pointer;
        display: inline-block;
        @media @large-desktop {
          height: 45px;
        }

        @media @desktop {
          height: 48px;
        }

        @media @tablet {
          height: 41px;
        }

        @media @mobile, @large-mobile, @large-mobile {
          height: 39px;
        }
      }
    }
  }

  .nav-button{
    span{
      color: white;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      font-weight: 300;
      @media @iPad, @iPad-pro, @tablet{
        font-size: 18px;
      }
      @media @iPad-pro{
        font-size: 15px;
      }
    }
    @media @mobile, @large-mobile{
      display: none;
    }

    @media @small-desktop{
      display: block;
    }
  }


  .nav-button-get-started{
    height: 58px;
    width: 190px;
    color: #16A7C9;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #16A7C9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media @mobile, @large-mobile {
      //display: none;
      width: 175px;
      margin-right: 10px;
    }

    @media @iPad, @iPad-pro{
      height: 50px;
    }
    @media @iPad-pro{
      font-size: 16px;
      height: 50px;
      width: 170px;
    }
  }
  .nav-button-book-demo{
    height: 58px;
    width: 170px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid @spaces-plus-light-green;
    background: @spaces-plus-light-green;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;


    @media @mobile, @large-mobile {
      display: none;
    }

    a{
      color: white;
    }
    @media @iPad{
      height: 50px;
      width: 150px;
    }
    @media @iPad-pro{
      font-size: 16px;
      height: 50px;
      width: 150px;
    }
  }
  #navbar-mobile {
    //padding-top: 50px;
    display: none;
    @media @mobile, @large-mobile, @tablet {
      display:block;
    }
  }

  #navbar-desktop {
    display: block;
    @media @mobile, @large-mobile, @tablet {
      display:none;
    }
  }

</style>
