module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _vm._m(0)
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "process-wrapper"
  }, [_c('div', {
    staticClass: "process-content"
  }, [_c('h1', [_vm._v("\n      The Process.\n    ")]), _vm._v(" "), _c('p', [_vm._v("\n      Getting started with Spaces+ is easy!\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "process-logos"
  }, [_c('img', {
    attrs: {
      "src": "/images/process.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "process-logos-mob"
  }, [_c('img', {
    attrs: {
      "src": "/images/process-mob.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })])])])
}]}