module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    staticClass: "package-content"
  }, [_vm._m(0), _vm._v(" "), _c('p', {
    staticClass: "trusted-by"
  }, [_vm._v("Our virtual venues give you the chance to customise and personalise. The chance to prioritise what is important to your brand. Built to resonate Spaces+ helps you to truly hook users, connecting with them well beyond the widely implemented 2D digital experience.")]), _vm._v(" "), _c('b-carousel', {
    staticStyle: {
      "text-shadow": "1px 1px 2px #333"
    },
    attrs: {
      "id": "carousel-1",
      "interval": 5000,
      "controls": "",
      "background": "white",
      "img-width": "1024",
      "img-height": "480"
    },
    on: {
      "sliding-start": _vm.onSlideStart,
      "sliding-end": _vm.onSlideEnd
    },
    model: {
      value: (_vm.slide),
      callback: function($$v) {
        _vm.slide = $$v
      },
      expression: "slide"
    }
  }, _vm._l((_vm.carouselData), function(item) {
    return _c('div', {
      key: item.id
    }, [_c('b-carousel-slide', {
      attrs: {
        "img-src": item.imageUrl,
        "img-alt": "3D environment"
      }
    })], 1)
  }), 0)], 1)])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('h1', {
    staticClass: "section-title"
  }, [_vm._v("\n      Discover a range of "), _c('br'), _vm._v("different\n      "), _c('span', {
    staticStyle: {
      "color": "#72BF00"
    }
  }, [_vm._v("venue styles")])])
}]}