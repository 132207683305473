module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "cursor"
  }, [_c('div', {
    staticClass: "cursor-ball cursor-ball-big"
  }, [_c('svg', {
    attrs: {
      "height": "20",
      "width": "20"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "10",
      "cy": "10",
      "r": "6",
      "stroke-width": "0"
    }
  })])])])
},staticRenderFns: []}