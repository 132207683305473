module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('svg', {
    staticClass: "mirk-logo",
    attrs: {
      "width": "117px",
      "height": "50px",
      "viewBox": "0 0 117 50",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('defs', [_c('polygon', {
    attrs: {
      "id": "path-1",
      "points": "0 0.434972273 42.9165989 0.434972273 42.9165989 30.2951586 0 30.2951586"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Welcome",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "home",
      "transform": "translate(-135.000000, -130.000000)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group-12",
      "transform": "translate(135.000000, 130.000000)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M117,30.0567917 C117,32.1979233 115.197923,34 113.0178,34 C110.803772,34 109,32.1979233 109,29.9821996 C109,27.8020767 110.803772,26 112.980504,26 C115.233524,26 117,27.7647807 117,30.0567917",
      "id": "Fill-1",
      "fill": "#FEFEFE"
    }
  }), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Group-5",
      "transform": "translate(0.000000, 4.000000)"
    }
  }, [_c('mask', {
    attrs: {
      "id": "mask-2",
      "fill": "white"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": "#path-1"
    }
  })]), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Clip-4"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M30.2077939,0.434972273 L42.9165989,0.434972273 L42.9165989,0.862560909 C41.0022766,0.989801818 40.2625149,1.28867 39.6525888,2.05655409 C39.0003907,2.95315864 38.8690453,3.76247 38.8690453,7.72913136 L38.8690453,21.5939518 C38.8690453,25.5606132 39.0003907,26.3714041 39.6525888,27.2680086 C40.2625149,28.0344132 41.0022766,28.3332814 42.9165989,28.4620018 L42.9165989,28.8881109 L26.2462942,28.8881109 L26.2462942,28.4620018 C28.1621261,28.3332814 28.9456697,28.0344132 29.5118139,27.2680086 C30.164012,26.3714041 30.2953575,25.5606132 30.2953575,21.5939518 L30.2953575,1.630445 L18.8033851,30.2951586 L7.31292238,2.26960864 L7.31292238,16.7735927 C7.31292238,21.1234564 7.79150295,23.8117905 8.92379135,25.7322405 C10.0122979,27.4810632 11.1868584,28.1631336 13.7971606,28.4620018 L13.7971606,28.8881109 L-0.000150971787,28.8881109 L-0.000150971787,28.4620018 C2.74300639,27.9929859 4.22252991,27.0105677 5.30952677,25.0057836 C6.35576125,23.0868132 6.74526846,20.7402541 6.74526846,16.0486155 L6.74526846,7.04706091 C6.74526846,3.59232227 6.48559699,2.69571773 5.13741893,1.84349955 C4.13496627,1.24576318 3.09175122,0.989801818 1.00079197,0.862560909 L1.00079197,0.434972273 L15.7567744,0.434972273 L22.9822841,18.393695 L30.2077939,0.434972273 Z",
      "id": "Fill-3",
      "fill": "#FEFEFE",
      "mask": "url(#mask-2)"
    }
  })]), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M56.0172911,4.70069552 C56.0172911,7.18317013 53.8806093,9.27152924 51.2993001,9.27152924 C48.6732194,9.27152924 46.5365377,7.18317013 46.5365377,4.61462433 C46.5365377,2.08986913 48.6732194,0 51.2545286,0 C53.9253808,0 56.0172911,2.04607852 56.0172911,4.70069552 Z M44,14.0175254 C48.0942775,13.1024526 53.2136682,11.622632 55.7502058,10.6652787 L55.7502058,27.4265123 C55.7502058,31.8236936 56.1068341,32.4337421 59,32.5651139 L59,33 L44.5773981,33 L44.5773981,32.5651139 C47.5168794,32.4337421 47.8719638,31.8674842 47.8719638,27.4265123 L47.8719638,20.2871328 C47.8719638,17.6747964 47.6928777,16.6736524 46.9811651,15.7162991 C46.3589955,14.8888075 45.5577398,14.5399927 44.0447715,14.4524115 L44,14.0175254 Z",
      "id": "Fill-6",
      "fill": "#FEFEFE"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M68.8392308,16.8591035 C70.5324476,12.0634846 72.4371329,10 75.1465734,10 C77.3067832,10 79,11.7042796 79,13.9014942 C79,16.0520587 77.5608392,17.6677033 75.7398601,17.6677033 C75.0202797,17.6677033 74.2581119,17.3971334 73.75,16.9041985 C73.4107692,16.5916436 73.4107692,16.5916436 72.5223077,15.3367588 C72.0567832,14.708539 71.6764336,14.439524 71.2534965,14.439524 C70.7027972,14.439524 70.2783916,14.752079 69.9406294,15.3802988 C69.3047552,16.5916436 69.176993,17.6677033 69.176993,21.6562775 L69.176993,27.2604962 C69.176993,31.8337503 69.5588112,32.4168751 72.5223077,32.5521601 L72.5223077,33 L58.2951748,33 L58.2951748,32.5521601 C61.3027273,32.4168751 61.6830769,31.8337503 61.6830769,27.2604962 L61.6830769,19.9084578 C61.6830769,17.2183084 61.5141958,16.1422487 60.8372028,15.2014739 C60.2439161,14.3493341 59.4817483,13.9901291 58.0851748,13.9014942 L58,13.4536542 C62.5304196,12.3324995 63.9695804,11.8831046 68.8392308,10 L68.8392308,16.8591035 Z",
      "id": "Fill-8",
      "fill": "#FEFEFE"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M90.2165586,17.7935514 C92.0865631,15.8646246 93.0864123,14.1561032 93.0864123,12.8853524 C93.0864123,12.3153386 92.8692505,11.8334869 92.4349269,11.5249194 L92.4349269,11.0871488 L101.783441,11.0871488 L101.783441,11.5249194 C99.1729754,12.0082911 95.1298447,13.9797789 92.4786608,16.0394288 L100.739858,28.091801 C103.086412,31.5103639 104.261197,32.5181483 106,32.5622294 L106,33 L94.5220932,33 L87.4779068,21.0813911 L87.4779068,27.391064 C87.4779068,31.8174113 87.8262705,32.4299862 90.6523903,32.5622294 L90.6523903,33 L76.3031217,33 L76.3031217,32.5622294 C79.3916453,32.4299862 79.783743,31.8599724 79.783743,27.391064 L79.783743,9.37862736 C79.783743,6.74896361 79.6088071,5.74117918 78.9135877,4.82155689 C78.3043282,3.94449562 77.5216408,3.59336711 76.0859599,3.54928604 L76,3.06743436 C79.783743,2.27853524 85.3047806,0.788899125 87.4779068,0 L87.4779068,20.6861815 L90.2165586,17.7935514 Z",
      "id": "Fill-10",
      "fill": "#FEFEFE"
    }
  })])])])])
},staticRenderFns: []}