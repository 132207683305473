<template lang="html">
  <div class="logo-burger hoverable">
    <router-link :to="{ name: 'home' }">
      <img class="mlogo" src="../../static/images/Logo.png" alt="Spaces+">
    </router-link>
    <div class="hamburger hoverable" @click="toggleMenu">
      <svg width="26px" height="17px" viewBox="0 0 26 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Welcome" stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
            <g id="home" transform="translate(-1369.000000, -116.000000)" fill="white">
                <g id="Header" transform="translate(-5.000000, -9.000000)">
                    <g id="MENU" transform="translate(1374.000000, 125.000000)">
                        <polygon id="Fill-1" points="0 4 26 4 26 0 0 0"></polygon>
                        <polygon id="Fill-2" points="0 17 26 17 26 13 0 13"></polygon>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["mirkSmallLogoColor"],
    methods: {
      toggleMenu() {
        this.$store.state.showMenu = !this.$store.state.showMenu;
      }
    },
  }
</script>

<style lang="less" scoped>
  @import "../../static/styles/settings.less";
  .logo-burger {
    width: 95%;
    margin: 0 auto;
    padding-top: 3%;
    max-width: 1800px;


    .hamburger {
      margin-right: 10px;
      margin-top: 5px;
      z-index: 12;
      cursor: pointer;
      display: inline-block;
      float: right;
      img {
        @media @large-desktop {
          width: 35px;
        }

        @media @desktop {
          width: 28px;
        }

        @media @tablet {
          width: 25px;
        }

        @media @mobile, @large-mobile, @large-mobile {
          width: 23px;
        }
      }
    }

    .mlogo {
      width: auto;
      cursor: pointer;
      display: inline-block;
      @media @large-desktop {
        max-height: 50px;
      }

      @media @desktop {
        max-height: 50px;
      }

      @media @tablet {
        max-height: 40px;
      }

      @media @mobile, @large-mobile, @large-mobile {
        //width: 140px;
        max-height: 40px;
      }
    }
  }
</style>
