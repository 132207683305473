module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "row custom-row",
    attrs: {
      "id": "contact"
    }
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6 contact-form-container"
  }, [_c('ContactForm')], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-12 col-md-6 contact-address-container"
  }, [_c('p', [_vm._v("hello@spacesplus.co")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_vm._v("\n        SYDNEY\n      ")]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('p', [_vm._v("+612 8231 6469")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_vm._v("\n        SURRY HILLS\n      ")]), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('p', [_vm._v("+612 8231 6469")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_vm._v("\n        MELBOURNE\n      ")]), _vm._v(" "), _vm._m(2), _vm._v(" "), _c('p', [_vm._v("+613 7064 0501")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('br'), _vm._v(" "), _c('button', {
    staticClass: "custom-button book-a-demo",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#demoBooking"
    },
    on: {
      "click": function($event) {
        return _vm.gtag_report_conversion("https://spacesplus.co/book-a-demo")
      }
    }
  }, [_c('p', {
    staticClass: "text"
  }, [_vm._v("Book a Consultation ")])])])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('p', [_vm._v("\n        Level 2, 11 York Street\n        "), _c('br'), _vm._v("Sydney 2000\n      ")])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('p', [_vm._v("\n        Suite 73, 61 Marlborough Street\n        "), _c('br'), _vm._v("Surry Hills 2010\n      ")])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('p', [_vm._v("\n        101 Moray Street\n        "), _c('br'), _vm._v("South Melbourne 3205\n      ")])
}]}