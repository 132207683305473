// Widths of different devices
// took reference from settings.less file

export const MOBILE_MAX_WIDTH = 392

export const LARGE_MOBILE_MIN_WIDTH = 393
export const LARGE_MOBILE_MAX_WIDTH = 659

export const TABLET_MIN_WIDTH = 660
export const TABLET_MAX_WIDTH = 1023

export const DESKTOP_MIN_WIDTH = 1024
export const DESKTOP_MAX_WIDTH = 1799

export const LARGE_DESKTOP_MIN_WIDTH = 1800
