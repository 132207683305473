module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    attrs: {
      "id": "home-cont"
    }
  }, [_c('navigation-bar', {
    attrs: {
      "mirkLogoColor": "black"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "services-listing-container"
  }, [_c('HomeItem', {
    attrs: {
      "homeItem": _vm.firstItem
    }
  })], 1), _vm._v(" "), _c('BannerSection', {
    attrs: {
      "bannerContent": _vm.secondItem
    }
  }), _vm._v(" "), _c('div', {
    attrs: {
      "id": "configurator"
    }
  }, [_c('Configurator')], 1), _vm._v(" "), _c('ClientLogos'), _vm._v(" "), (_vm.video) ? _c('VideoContainer', {
    attrs: {
      "videoContent": _vm.video
    }
  }) : _vm._e(), _vm._v(" "), _c('Carousel'), _vm._v(" "), _c('div', {
    attrs: {
      "id": "features"
    }
  }, [_c('Features')], 1), _vm._v(" "), _c('div', {
    attrs: {
      "id": "packages"
    }
  }, [_c('PackageOptions')], 1), _vm._v(" "), _c('div', {
    attrs: {
      "id": "how-it-works"
    }
  }, [_c('Process')], 1), _vm._v(" "), _c('div', {
    attrs: {
      "id": "book-a-demo"
    }
  }, [_c('Footer'), _vm._v(" "), _c('FooterCopyright')], 1), _vm._v(" "), _c('BookADemoModal')], 1)])
},staticRenderFns: []}