module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('section', {
    staticClass: "banner-section-container",
    style: ('background: ' + _vm.bannerContent.backgroundColor),
    attrs: {
      "id": "featured-trigger"
    }
  }, [_c('div', {
    staticClass: "banner-wrapper"
  }, [(_vm.bannerContent.columns === 1) ? _c('div', {
    class: _vm.bannerContent.imagePosition === 'right' ? 'div-row div-row-reverse' : 'div-row'
  }, [_c('div', {
    staticClass: "project-info col-12"
  }, [_c('h1', {
    staticClass: "title",
    style: ('color: ' + _vm.bannerContent.textColor + '!important'),
    domProps: {
      "innerHTML": _vm._s(_vm.bannerContent.title)
    }
  }), _vm._v(" "), _c('hr', {
    staticClass: "hr-white"
  }), _vm._v(" "), (_vm.bannerContent.heading) ? _c('p', {
    staticClass: "heading",
    style: ('color: ' + _vm.bannerContent.textColor + '!important'),
    domProps: {
      "innerHTML": _vm._s(_vm.bannerContent.heading)
    }
  }) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "description",
    style: ('color: ' + _vm.bannerContent.textColor + '!important'),
    domProps: {
      "innerHTML": _vm._s(_vm.bannerContent.description)
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "list",
    style: ('color: ' + _vm.bannerContent.textColor + '!important')
  }, _vm._l((_vm.bannerContent.list), function(item) {
    return _c('li', {
      key: item
    }, [_vm._v("\n            " + _vm._s(item) + "\n          ")])
  }), 0)])]) : _c('div', {
    class: _vm.bannerContent.imagePosition === 'right' ? 'div-row div-row-reverse' : 'div-row'
  }, [_c('div', {
    staticClass: "project-image-container col-sm-12 col-md-7"
  }, [_c('div', {
    class: 'auto-ar image-' + _vm.bannerContent.id
  }, [(_vm.bannerContent.id === 'audience') ? _c('img', {
    staticClass: "banner-image",
    attrs: {
      "src": require("../../../static/images/audience.png"),
      "alt": "Audience Image"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.bannerContent.id === 'local') ? _c('img', {
    staticClass: "banner-image",
    attrs: {
      "src": require("../../../static/images/local.png"),
      "alt": "Local Image"
    }
  }) : _vm._e(), _vm._v(" "), (_vm.bannerContent.id === 'getting-started') ? _c('img', {
    staticClass: "banner-image",
    attrs: {
      "src": require("../../../static/images/getting-started.png"),
      "alt": "Getting Started Image"
    }
  }) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "project-info col-sm-12 col-md-5"
  }, [_c('h1', {
    staticClass: "title",
    style: ('color: ' + _vm.bannerContent.textColor + '!important'),
    domProps: {
      "innerHTML": _vm._s(_vm.bannerContent.title)
    }
  }), _vm._v(" "), _c('hr', {
    staticClass: "hr-white"
  }), _vm._v(" "), _c('p', {
    staticClass: "description",
    style: ('color: ' + _vm.bannerContent.textColor + '!important'),
    domProps: {
      "innerHTML": _vm._s(_vm.bannerContent.description)
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "list",
    style: ('color: ' + _vm.bannerContent.textColor + '!important')
  }, _vm._l((_vm.bannerContent.list), function(item) {
    return _c('li', {
      key: item
    }, [_vm._v("\n            " + _vm._s(item) + "\n          ")])
  }), 0)])])])])
},staticRenderFns: []}