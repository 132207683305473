<template>
  <div>
    <VuePageTransition :name="$route.meta.transition" mode="out-in" id="page-transition" shouldApply="true">
      <div id="app" :key="$route.path">
        <burger-slider></burger-slider>
        <menu-new></menu-new>
        <router-view id="appy" class="app-container"></router-view>
        <CustomCursor id="cursor" :key="$route.name"></CustomCursor>
      </div>
    </VuePageTransition>
  </div>
</template>

<script>
import BurgerSlider from '../components/BurgerSlider'
import MenuNew from './MenuNew.vue'
import CustomCursor from './CustomCursor.vue'
import { mapState } from 'vuex';
import VuePageTransition from '../components/VuePageTransition/VuePageTransition'

export default {
  components: {
    BurgerSlider,
    MenuNew,
    CustomCursor,
    VuePageTransition
  },
  data() {
    return {
      componentKey: 0,
      isPageShowing: false,
      shouldReRender: false,
      localDeviceType: true
    }
  },
  computed: mapState(['showMenu', 'navigateStatus']),
  created: function () {
    /* CONVERSION - GOOGLE ADS */
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('async', '')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-654851161')
    // recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-877373862')
    document.head.appendChild(recaptchaScript)
  },
  watch: {
    showMenu: function() {
      // watch to see if the menu is open or not
      if(this.$store.state.showMenu){
        // disable the scroll when menu is shown
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
    navigateStatus: function() {
      // watch to see if the menu is open or not
      return this.$store.state.isNavigatingBack
    }
  },
  mounted() {
    //
    // window.addEventListener('load',function(){
    //   var timeInt = setInterval(function () {
    //     if (document.querySelectorAll('[class="error"]').length>0 && document.querySelector('[class="error"]').innerText.indexOf('Thanks, we')!=-1 ) {
    //       gtag('event', 'conversion', {'send_to': 'AW-877373862/yXUdCISKvaYBEKbTrqID'});
    //       clearInterval(timeInt);
    //     }
    //   }, 1000);
    // })
  }
}

</script>
<style lang="less">
@import "../../static/styles/settings.less";

/**{*/
/*  cursor: none!important;*/
/*}*/

.no-padding{
  @media @mobile, @large-mobile{
    padding-left: 0px;
    padding-right: 0px;
  }
}

.fadeInDown{
  background-color: black;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*height: 100vh;*/
  /*width: 100vw;*/
}

.hide-bar{
  overflow: hidden;
}

body {
  margin: 0;
  font-size: 2rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  // Turn off aliasing to prevent text looking bolder when white
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  //background-color: @white;
  cursor: none;
}

h1, h3 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 3.2rem;
  color: @spaces-plus-dark-blue;
}

h3 {
  @media @mobile, @large-mobile {
    font-size: 28px;
  }
}

h2 {
  font-size: 4vw;
  line-height: 4.3vw;
  font-weight: 300;
  font-family: Roboto, Helvetica, Arial, sans-serif;

  @media @large-desktop {
    font-size: 3.3vw;
    line-height: 3.6vw;
  }

  @media @desktop {
    font-size: 3.5vw;
    line-height: 3.8vw;
  }

  @media @tablet {
    font-size: 4.1vw;
    line-height: 4.4vw;
  }

  @media @mobile, @large-mobile {
    font-size: 9vw;
    line-height: 10vw;
  }
}
p {
  font-size: 1.6vw;
  line-height: 2.2vw;
  font-family: Roboto, Helvetica, Arial, sans-serif;

  @media @large-desktop {
    font-size: @content-font-size;
    line-height: @content-line-height;
  }

  @media @desktop {
    font-size: 1.4vw;
    line-height: 1.8vw;
  }

  @media @tablet {
    font-size: 2vw;
    line-height: 2.4vw;
  }

  @media @mobile, @large-mobile {
    font-size: @content-font-size;
    line-height: @content-line-height;
  }
}

a {
  text-decoration: none !important;
}

tspan {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.page {
  text-align: center;
}

.app-container {
  opacity: 1;
  background-color: @white;
  height: 100%;
  min-height: 100vh;
}

.clearfix{
  zoom:1;
  &:before, &:after{ content:""; display:table; }
  &:after{ clear: both; }
}

.services-item-wrapper{
  /*max-width: 100vw !important;*/
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.blocker {
   position:absolute;
   height:100%;
   width:100%;
   z-index:10;
   &:hover .imgbox {
     background-color: blue;
     div {
       transform: scale(1.1);
     }
   }
}

html {
  scroll-behavior: smooth;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 50px;
  height: 50px;
  opacity: 1 !important;
}


.custom-control-label{
  width: 100%;
}
.custom-control-label::before {
  top: 1.25rem !important
}

.custom-control-label::after{
  top: 1.25rem !important
}

</style>
