import config from "config";

export const handleResponse = (response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api

        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export const submitContact = (enquiry) => {
  // console.log("demo ", enquiry)
  const body = {
    name: enquiry.name,
    message: enquiry.message,
    email_address: enquiry.email_address,
    subject: enquiry.subject
  }

  const requestOptions = {
    method:"POST",
    headers:{ "Content-Type": "application/json"},
    body: JSON.stringify({...body})
  }
  return fetch(`${config.apiUrl}/contact`, requestOptions)
    .then(handleResponse)
    .then(res=>{
      // console.log("submitContact ", res)
      return res
    }).catch(e=>{
      // console.log("submitContact ", e)
      throw e;
    })
}
