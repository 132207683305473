import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Home from '../views/Home'
import Demo from "../views/Demo";
import PrivacyPolicy from "../views/PrivacyPolicy";

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   component: Home,
    //   name: 'home',
    //   meta: { transition: 'fade' }
    // },
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: { transition: 'fade' }
    },
    {
      path: '/privacy-policy',
      component: PrivacyPolicy,
      name: 'privacy-policy',
      meta: { transition: 'fade' }
    },
    {
      path: '/demo',
      component: Demo,
      meta: { transition: 'fade' },
    },
    {
      path: '*',
      component: Home,
      meta: { transition: 'fade' },
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // console.log('scrollBehavior')
    // console.log('to', to)
    // console.log('from', from)
    // return { x: 0, y: 0 }
    if(to && to.name !== 'privacy-policy'){
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('SET_LOADING', true)
  // console.log('to',to)
  // console.log('from',from)
  // console.log('beforeEach')

  // otherwise it will will apply overlay effect
  if(from && from.name === "home"){
    to.meta.transition = 'fade'
  }
  // wait to finish the code before go next
  setTimeout(function()  {
    next()
  }.bind(this), 400)

  // let iconBurger = document.getElementById('navbar-desktop')
  // console.log('ICON', iconBurger)
  // if(iconBurger){
  //   iconBurger.style.opacity = 0;
  //   iconBurger.style.transition = "0.5s";
  // }
})

router.afterEach((to, from) => {
  store.commit('SET_LOADING', false)
})

export default router
