module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "mirk-navbar",
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "id": "mirk-navbar"
    }
  }, [_c('div', {
    attrs: {
      "id": "navbar-desktop"
    }
  }, [_c('div', {
    staticClass: "row",
    attrs: {
      "id": "home"
    }
  }, [_c('div', {
    staticClass: "brand-logo col-lg-3 col-md-3"
  }, [_c('div', {
    staticClass: "head-block"
  }, [_c('div', {
    staticClass: "hoverable",
    attrs: {
      "aria-label": "mirk-navbar",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'mirk-navbar')
      }
    }
  }, [(_vm.logoColor === 'black') ? _c('img', {
    staticClass: "spaces-plus-logo",
    attrs: {
      "role": "button",
      "src": require("../../static/images/Logo.png"),
      "alt": "Spaces+"
    }
  }) : _c('img', {
    staticClass: "spaces-plus-logo",
    attrs: {
      "role": "button",
      "src": require("../../static/images/Logo.png"),
      "alt": "Spaces+"
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "brand-logo col-lg-9 col-md-9"
  }, [_c('div', {
    staticClass: "row justify-content-between align-items-center"
  }, [_c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button",
    attrs: {
      "aria-label": "features",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'features')
      }
    }
  }, [_c('span', [_vm._v("Features")])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button",
    attrs: {
      "aria-label": "packages",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'packages')
      }
    }
  }, [_c('span', [_vm._v("Packages")])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button",
    attrs: {
      "aria-label": "how-it-works",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'how-it-works')
      }
    }
  }, [_c('span', [_vm._v("How It Works")])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button",
    attrs: {
      "aria-label": "configurator",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.navigateTo('home', 'configurator')
      }
    }
  }, [_c('span', [_vm._v("Find your Space")])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button-get-started",
    attrs: {
      "role": "button",
      "aria-label": "packages",
      "data-toggle": "modal",
      "data-target": "#demoBooking"
    },
    on: {
      "click": function($event) {
        return _vm.gtag_report_conversion("https://spacesplus.co/book-a-demo")
      }
    }
  }, [_c('span', [_vm._v("Book a Consultation")])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "hoverable"
  }, [_c('div', {
    staticClass: "nav-button-book-demo",
    attrs: {
      "aria-label": "book-a-demo",
      "role": "button"
    }
  }, [_c('router-link', {
    staticClass: "text",
    attrs: {
      "to": "/demo",
      "target": "_blank"
    }
  }, [_vm._v("\n                      Launch Demo\n                    ")])], 1)])])])])])]), _vm._v(" "), _c('logo-burger', {
    attrs: {
      "id": "navbar-mobile",
      "mirkSmallLogoColor": "#ffffff"
    }
  })], 1)
},staticRenderFns: []}