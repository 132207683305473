
/* styles */
require("!!../../../node_modules/extract-text-webpack-plugin/dist/loader.js?{\"omit\":1,\"remove\":true}!vue-style-loader!css-loader?sourceMap&-autoprefixer!../../../node_modules/vue-loader/lib/style-compiler/index?{\"id\":\"data-v-7441e4a6\",\"scoped\":false,\"hasInlineConfig\":false}!less-loader?sourceMap!../../../node_modules/vue-loader/lib/selector?type=styles&index=0!./Footer.vue")

var Component = require("!../../../node_modules/vue-loader/lib/component-normalizer")(
  /* script */
  require("!!babel-loader!../../../node_modules/vue-loader/lib/selector?type=script&index=0!./Footer.vue"),
  /* template */
  require("!!../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-7441e4a6\"}!../../../node_modules/vue-loader/lib/selector?type=template&index=0!./Footer.vue"),
  /* scopeId */
  null,
  /* cssModules */
  null
)

module.exports = Component.exports
