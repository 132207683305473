module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    style: (_vm.lottieStyle)
  }, [_c('div', {
    staticClass: "lottie-loader-container",
    style: (_vm.loaderStyle)
  }), _vm._v(" "), _c('div', {
    ref: "lavContainer",
    staticClass: "loading-icon",
    style: (_vm.style)
  })])
},staticRenderFns: []}