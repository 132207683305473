module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _vm._m(0)
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "package-wrapper"
  }, [_c('div', {
    staticClass: "package-content"
  }, [_c('h1', [_vm._v("\n      Find the "), _c('span', {
    staticStyle: {
      "color": "#72BF00"
    }
  }, [_vm._v("perfect space")]), _c('br'), _vm._v("\n      for your brand.\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "feature-logos"
  }, [_c('img', {
    attrs: {
      "src": "/images/packages.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "feature-logos-mob"
  }, [_c('img', {
    attrs: {
      "src": "/images/packages-mob.png",
      "alt": "Feature icons",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })])])])
}]}