<template>
  <div>
    <div id="home-cont">
      <navigation-bar mirkLogoColor="black" ></navigation-bar>

      <div class="services-listing-container">
        <HomeItem :homeItem="firstItem"></HomeItem>
      </div>

      <BannerSection
        :bannerContent="secondItem"
      />

      <div id="configurator">
        <Configurator/>
      </div>

      <ClientLogos/>

      <VideoContainer
        v-if="video"
        :videoContent="video"
      />

      <Carousel/>

      <!-- Features.-->
      <div id="features">
        <Features/>
      </div>

      <!-- Package Options.-->
      <div id="packages">
        <PackageOptions/>
      </div>

      <div id="how-it-works">
        <Process/>
      </div>

      <div id="book-a-demo">
        <Footer/>
        <FooterCopyright/>
      </div>

      <BookADemoModal/>
    </div>
 </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar';
  import Lottie from '../components/lottie.vue'
  import HomeItem from '../components/HomeComponents/HomeItem'
  import ClientLogos from "../components/HomeComponents/ClientLogos";
  import VideoContainer from "../components/HomeComponents/VideoContainer";
  import BannerSection from "../components/HomeComponents/BannerSection";
  import TextOnlyBanner from "../components/HomeComponents/TextOnlyBanner";
  import PackageOptions from "../components/HomeComponents/PackageOptions";
  import Features from "../components/HomeComponents/Features";
  import Footer from "../components/HomeComponents/Footer";
  import FooterCopyright from "../components/HomeComponents/FooterCopyright";
  import BookADemoModal from "../components/HomeComponents/BookADemoModal";
  import Carousel from "../components/HomeComponents/Carousel";
  import Process from "../components/HomeComponents/Process";
  import Configurator from "../components/Configurator";

  export default {
    components: {
      Configurator,
      Process,
      Carousel,
      BookADemoModal,
      FooterCopyright,
      Footer,
      Features,
      PackageOptions,
      TextOnlyBanner,
      BannerSection,
      VideoContainer,
      ClientLogos,
      NavigationBar,
      Lottie,
      HomeItem
    },
    data() {
      return {
        isLoading:false,
        hideDesktopComponent:false,

        firstItem: {
          id: 'home',
          title: 'The only true all-in-one </br> <span style="color: #72BF00"> 3D Virtual Venue</br></span>',
          description: 'Engage, inform and inspire with confidence - events, brand experiences, product showrooms, interactive networking and so much more. ',
          image: './images/desktop.png',
          hasImage: true,
          imageRight: true,
          hasFeatured: true,
          link: '/demo',
          isFirstItem: true
        },
        secondItem:{
          id: "audience",
          backgroundColor: '#E0F0C7',
          textColor: '#072635',
          title: 'Letting your audiences navigate, explore and <span style="color: #72BF00">engage in true 3D.</span>',
          // title: 'Audiences</br> navigate, explore </br> and <span style="color: #72BF00">truly engage.</span>',
          // description: '<span style="color: #072635; font-weight: 500">A space that brings your brand and messages to life.</span></br></br>  Spaces+ provides a genuine 3D digital interactive environment to explore and be truly engaged. Immersive 3D virtual rooms to communicate with customers, showcase products, host events and facilitate networking.'
          description: '<span style="color: #072635; font-weight: 500">Spaces+ is your go to solution for virtual venues, bringing your brand to life like no other has been able to do before. Providing a genuine 3D environment home to various interactive areas, our venues let users navigate in and out of specific rooms built to communicate, immerse and network. The results? Higher engagement, awareness & a completely captivated audience.',
          list:[
            "100% Australian designed and locally supported",
            "Agile solution ready to present to audiences within just 2 weeks",
            "Versatile and modular to suit a variety of investment levels",
            "Integrated to best reflect and showcase your brands identity",
          ]
        },
        fourthItem:{
          id: "local",
          backgroundColor: '#072635',
          imageURL: "../../../static/images/audience.png",
          // title: '<span style="color: #72BF00">An established</span> and capable partner.',
          title: 'Bring your</br> <span style="color: #72BF00">brand to life.</span> ',
          description: 'Ready made configurations, spaces and environments that align with your brand.',
          // description: 'The Spaces+ platform is trusted having delivered a number of forward-thinking experiences to brands leading within their prospective industries. Unlike offers currently on the market, clients come to us if they are looking to shake up the competition -  your audiences don\'t live within a 2D world so why provide them with a flat experience?',
          imagePosition: 'right'
        },
        fifthItem: {
          id: "getting-started",
          backgroundColor: '#DDE7E8',
          textColor: "#072635",
          imageURL: "../../../static/images/audience.png",
          title: 'Getting started</br>with <span style="color: #72BF00">Spaces+ is easy.</span>',
          description: 'It takes just a few simple steps to bring your virtual experience to life within 2 weeks.',
          imagePosition: 'left',
          list: [
            // "Select the room configuration that best suits your needs",
            // "Choose the user services that you want to be part of your virtual experience",
            // "Select the look and feel that best matches your brand",
            // "Enter some simple information about your brand and product",
            // "Sit back and wait for your stunning customer experience to come to life"
          ]
        },
        video: {
          videoUrl: 'https://storage.spacesinteractive.co/videos/spaces+.mp4',
        },
        dataScroll: {}
      }
    },
    mounted() {
      if (window.innerWidth < 450){
        this.hideDesktopComponent = true;
      } else {
        this.hideDesktopComponent = false;
      }
      let that = this;
      // this.$nextTick( function() {
      //   setTimeout( function () {
      //     document.getElementById('lottie-cont').style.opacity = 0;
      //
      //     setTimeout(function() {
      //       that.isLoading = false
      //       document.getElementById('lottie-cont').style.display = "none";
      //     }, 1200)
      //   }, 1000)
      // })

      if(this.dataScroll) {
        // find element by id and scroll to it
        let doc = document.getElementById(this.dataScroll)
        if(doc){
          // wait some time then scroll
          setTimeout(function() {
            const y = doc.getBoundingClientRect().top + window.scrollY;
            let height = window.screen.height;
            // offset 14% of the height
            let offsetHeight = height*.07
            window.scroll({
              top: y-offsetHeight
            });
          }.bind(this), 200)
        }
      }
    },
    created() {
      window.addEventListener("resize", this.resizeWindow);

      if(this.$router &&
        this.$router.history &&
        this.$router.history.current &&
        this.$router.history.current.params &&
        this.$router.history.current.params.data)
        this.dataScroll = this.$router.history.current.params.data
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeWindow);
    },
    methods: {
      resizeWindow(e) {
        if (window.innerWidth < 450){
          this.hideDesktopComponent = true;
        } else {
          this.hideDesktopComponent = false;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@import "../../static/styles/settings.less";

  #home-cont {
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;

    .services-listing-container{
      background: @spaces-plus-dark-blue;
    }
  }

  #lottie-cont {
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    opacity: 1;
    z-index: 70;
  }

  #configurator{
    max-width: 100vw;
  }
</style>
