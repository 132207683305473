module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "modal book-a-demo",
    attrs: {
      "id": "demoBooking",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "videoModal1Label",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-lg",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-close-btn-container"
  }), _vm._v(" "), _c('div', {
    staticClass: "modal-body modal-booking-container"
  }, [_c('form', {
    staticClass: "section form"
  }, [_c('div', {
    staticClass: "booking-container"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "contact-form-container input-container"
  }, [_c('div', {
    staticClass: "col-md-12 name input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.demo.name),
      expression: "demo.name"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "placeholder": "Name",
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.demo.name)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.demo, "name", $event.target.value)
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "col-md-12 email input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.demo.email),
      expression: "demo.email"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "placeholder": "Email",
      "type": "email",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.demo.email)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.demo, "email", $event.target.value)
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "col-md-12 input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.demo.message),
      expression: "demo.message"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "placeholder": "Preferred day and time",
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.demo.message)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.demo, "message", $event.target.value)
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "row send-container"
  }, [_c('div', {
    staticClass: "col-md-4 col-sm-12"
  }, [_c('button', {
    staticClass: "custom-button book-a-demo hoverable",
    on: {
      "click": function (e) { return _vm.submitFormDemo(e); }
    }
  }, [_c('span', {
    staticClass: "text"
  }, [_vm._v("\n                      SEND\n                    ")])])]), _vm._v(" "), (_vm.demoSnackbarStatus) ? _c('div', {
    staticClass: "snackBar col-md-8"
  }, [_c('p', {
    staticClass: "thank-you-message",
    domProps: {
      "innerHTML": _vm._s(_vm.snackbarText)
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-md-12 col-lg-8 error-msg-container"
  }, [_c('p', {
    staticClass: "error-msg",
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessageDemo)
    }
  })])])])])])])])])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "col-md-12 input-field header"
  }, [_c('div', [_c('h3', {
    staticClass: "title"
  }, [_vm._v("Book a Consultation")])]), _vm._v(" "), _c('div', {
    staticClass: "booking-close-btn-circle",
    attrs: {
      "data-dismiss": "modal"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/black_cross.png",
      "alt": "close icon"
    }
  })])])
}]}