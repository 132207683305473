<template>
  <div class="feature-wrapper">
    <div class="feature-content">
      <h1>
        Features.
      </h1>
      <p>
        Spaces+ is easy to use, priced affordably and supported in-house by an all Australian team. Built to make life fun, our virtual venues offer a gamified experience providing a bold and innovative alternative to the mundane.
      </p>
      <div class="feature-logos">
        <img
          src="/images/features.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
      <div class="feature-logos-mob">
        <img
          src="/images/features-mob.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'features',
    props: [""],
    data(){
      return{}
    },
    mounted () {

    }

}
</script>

<style lang="less" scoped>
@import "../../../static/styles/settings.less";

.feature-wrapper{
  background: @spaces-plus-dark-blue;

  .feature-content{
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 5% !important;

    @media @tablet {
      padding-bottom: 0px;
    }

    @media @mobile, @large-mobile{
      width: 90%;
      padding-left: 0 !important;
      padding-bottom: 0px;
    }

    @media @iPad, @small-desktop, @iPad-pro{
      width: 90%;
      padding-left: 0 !important;
    }
    @media @iPad{
      padding-top: 5vh
    }
    h1{
      color: @spaces-plus-light-green;
      width: 100%;
      font-size: @title-font-size;
      line-height: @title-line-height;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 700;
      margin-top: 5vh;
      @media @mobile, @large-mobile, @tablet {
        font-size: @title-font-size-mobile;
        line-height: @title-line-height-mobile;
      }
    }

    p{
      color: white;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      margin-top: 30px;
      margin-bottom: 10vh;
      @media @mobile, @large-mobile{
        margin-bottom: 5vh;
      }
      max-width: 500px;
    }

    img{
      max-width: 100%;
      object-fit: contain;
      margin-bottom: 10vh;
    }

    .feature-logos{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @mobile, @large-mobile{
        display: none;
      }
    }
    .feature-logos-mob{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @desktop, @large-desktop, @iPad, @tablet, @iPad-pro{
        display: none;
      }
    }
  }
}
</style>
