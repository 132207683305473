module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('div', {
    attrs: {
      "id": "home-cont"
    }
  }, [_c('navigation-bar', {
    attrs: {
      "mirkLogoColor": "black"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "services-listing-container"
  }, [_c('HomeItem', {
    attrs: {
      "homeItem": _vm.firstItem
    }
  })], 1), _vm._v(" "), _c('PrivacyPolicy'), _vm._v(" "), _c('div', {
    attrs: {
      "id": "book-a-demo"
    }
  }, [_c('Footer'), _vm._v(" "), _c('FooterCopyright')], 1), _vm._v(" "), _c('BookADemoModal')], 1)])
},staticRenderFns: []}