<template>
  <div
    class="modal"
    :id="`getStarted`"
    role="dialog"
    style="padding-right: 0!important;"
  >
    <div class="row custom-row modal-wrapper">
      <div class="col-sm-12 col-md-6 contact-address-container--menu">
        <div class="menu-logo">
          <img
            class="little-m hoverable"
            src="../../../static/images/Spaces+Dark.png"
            alt="Company logo"
          />
        </div>
        <div class="nav-container-menu nav-container-menu--new">

        </div>


        <h1 class="get-started-title">
          Get started.
        </h1>

        <p class="get-started-content">
          The Spaces+ team would be happy to arrange a time with you to discover what you are looking for and to share a range of possibilities.
        </p>
        <div
          class="book-a-demo-green"
          role="button"
          data-toggle="modal"
          data-target="#demoBooking"
          aria-label="button"
          @click="gtag_report_conversion(`https://spacesplus.co/book-a-demo`)"
        >
          <span>Book a Consultation</span>
        </div>

        <!-- contact information -->
        <br />
        <p>hello@spacesplus.co</p>

        <div class="location-row">
          <p>
            SYDNEY<br />
            Suite 73, 61 Marlborough Street
            <br />Surry Hills 2010
            <br />+612 8231 6469
          </p>
          <p class="melb">
            MELBOURNE<br />
            101 Moray Street
            <br />South Melbourne 3205
            <br />+613 7064 0501
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 contact-form-container">

        <div class="close-btn--menu">
          <img
            role="button"
            data-dismiss="modal"
            offset-hover-max="2"
            offset-hover-min="1"
            class="cross magnetic-button hoverable"
            src="/images/black_cross.png"
            alt="close button"
          />
        </div>
        <!-- contact form -->
        <ContactForm/>
      </div>
    </div>
  </div>
</template>

<script>
import {submitContact} from "../../api";
import ContactForm from "./ContactForm";

export default {
  name: "GetStarted",
  components: {ContactForm},
  data () {
  return {
      dt: "",
      isMobilePhone: true,
        enquiry: {
          name: null,
          email: null,
          message: null
        },
        emailAddress: null,
        errorMessage: null,
        errorMessageDemo: null,
        errorMessage2: ' ',
        updates: [],
        currentData: {},
        navigate: false,
        snackbarStatus: false,
        demoSnackbarStatus: false,
        snackbarText: "Thanks for your enquiry, someone from the Spaces+ team will be intouch shortly."
    }
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    /* CONVERSION - GOOGLE ADS */
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', 'AW-654851161');
  },
  methods: {
    gtag(){
      /* CONVERSION - GOOGLE ADS */
      // console.log("gtag ", arguments)
      dataLayer.push(arguments);
    },
    gtag_report_conversion(url) {
      /* CONVERSION - GOOGLE ADS */
      // console.log("gtag_report_conversion ", url)
      var callback = function () {
        // console.log("callback")
        // if (typeof(url) != 'undefined') { window.location = url; }
      };
      this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/fSRZCIq24f8BENn4oLgC', 'event_callback': callback });
      return false;
    },
    submitForm(e) {
      try {
        e.preventDefault();
        if (!this.validateEmailFormat(this.enquiry.email)) {
          this.errorMessage = 'Please enter correct email address.'
          return
        }

        if (this.enquiry.name == null || this.enquiry.message == null ) {
            this.errorMessage = 'Please complete all fields'
            return
        }

        const body = {
          name: this.enquiry.name,
          message: this.enquiry.message,
          email_address: this.enquiry.email,
          subject: 'Get Started - Spaces+'
        }

        submitContact(body).then(res => {
          if(res && res.id){
            this.enquiry.name = null
            this.enquiry.email = null
            this.enquiry.message = null
            this.snackbarStatus = true
          } else {
            this.errorMessage = "Ops! Something went wrong, please try again."
          }
        }).catch(e => {
          this.errorMessage = "Ops! Something went wrong, please try again."
        })
      } catch(e) {
        this.errorMessage = "Ops! Something went wrong, please try again."
      }
    },
    validateEmailFormat(email){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
};
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
#getStarted{
  padding-right: 0 !important;
  .custom-col {
    padding-left: 0;
    margin-bottom: 40px;
    @media @mobile {
      margin-bottom: 10px;
    }
  }
  .footer-container-contact {
    @media @mobile {
      padding: 15px !important;
    }
    .col-6 {
      @media @mobile, @large-mobile, @tablet {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    .mobile {
      @media @mobile {
        padding-bottom: 10px;
        flex: 0 0 100% !important;
      }
    }
    .stay-in-loop {
      padding-left: 0;
      @media @mobile {
        padding-top: 35px;
      }
      @media @tablet {
        padding-top: 20px;
      }
    }

    .footer-bottom {
      margin-top: 50px;

      .col-8 {
        @media @mobile, @large-mobile {
          max-width: 100%;
          flex: 0 0 100% !important;
          padding-bottom: 35px;
        }
      }
    }
  }

  .opaque {
    opacity: 0;
    transform: translateY(200px);
  }

  a:hover {
    text-decoration: none;
  }
  .footer-container-new {
    width: 92%;
    background-color: white;
    box-sizing: border-box;
    padding: 30px 0 30px 0;
    margin: 0 auto;

    @media @iPad {
      width: 90%;
    }
    @media @mobile, @large-mobile {
    }
  }

  #addresses {
    margin: 0 auto;
  }

  .address-header {
    font-size: 14px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 23px;
  }

  .address {
    font-size: @content-font-size;
    line-height: 23px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: @font-grey;
    @media @mobile, @large-mobile {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .footer-info-description {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: @black;
    margin-bottom: 5px;
    @media @mobile, @large-mobile {
      font-size: 12px;
    }
  }
  .footer-info-description-text {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: @black;
    @media @mobile, @large-mobile {
      font-size: 12px;
    }
  }
  .footer-info-header {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    line-height: 23px;
    @media @mobile, @large-mobile {
      font-size: 14px;
    }
  }

  #social-media {
    display: flex;
    .item {
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: 13px;
      margin-right: 15px;
      color: @font-grey;
      .link {
        color: @font-grey;
      }
    }
  }

  .copyright {
    color: @font-grey;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;

    @media @mobile, @large-mobile {
      padding-left: 15px !important;
      justify-content: center;
    }
  }
  .upperCase {
    text-transform: uppercase;
  }

  .col-5 {
    @media @mobile, @large-mobile, @tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .mirk-home-link {
    p {
      font-size: 1.2vw;
      font-weight: bold;
      color: black;
      text-decoration: underline;
    }
  }

  .contact-form-container {
    padding-left: 5%;
    background-color: #F3F3F3;
    .wrapper {
      width: 90%;
      max-width: 1600px;
      margin: 0px auto;

      @media @mobile, @large-mobile, @tablet {
        width: 100%;
        margin: 0px auto !important;
      }
    }

    .section {
      margin: 100px auto;
      padding: 150px 0px 50px 0px;
      max-width: 700px;
      @media @mobile, @large-mobile, @tablet {
        margin: 70px auto !important;
        padding: 75px 0px 75px 0px;
      }
      @media @desktop {
        margin: 70px auto !important;
        padding: 70px 0px 70px 0px;
      }
    }

    .form {
      max-width: 700px;
      margin: 0 auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 90%;
        margin: 0px auto !important;
      }
    }
    .contact-input {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @grey;
      border: 2px solid @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      transition: all .2s ease;
      &:focus {
        color: @black;
        border: 2px solid @spaces-plus-dark-blue;
      }
      min-height: 65px;
    }
    .contact-input-form {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 15px;
      background-color: @white;
      color: @grey-projects !important;
      font-family: Roboto, Helvetica, Arial, sans-serif;;
      font-size: 18px;
      line-height: 38px;
      transition: all .2s ease;
      border: 0;
      &:focus {
        color: @black;
        border-bottom: 1px solid @spaces-plus-dark-blue;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @contact-grey;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: @contact-grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: @contact-grey;
    }
    .error-msg-container {
      display: flex;
      align-items: center;
      min-height: 50px;

      .error-msg {
        color: @spaces-plus-dark-blue;
        line-height: 24px;
        font-size: @content-font-size;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
      }
    }
    .mirk-address {
      @media @mobile, @large-mobile {
        display: none !important;
      }

      p {
        line-height: 32px;
        font-family: Roboto, Helvetica, Arial, sans-serif;

        @media @tablet {
          font-size: 1rem;
          line-height: 1.4rem;
        }
        font-size: 1.125rem;
        color: @black;
      }
    }
    .btn-submit {
      position: relative;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: 48px;
      cursor: pointer;
      .text {
        position: relative;
        color: @black;
        transition: color 450ms linear 184ms;
        z-index: 1;
        &:before {
          content: "";
          background: @light-green;
          opacity: 0.23;
          display: block;
          width: 100%;
          height: 13px;
          position: absolute;
          bottom: -5px;
          left: -0.15em;
          right: -0.15em;
          transition: 300ms transform cubic-bezier(0.165, 0.84, 0.44, 1);
          transform-origin: 0% 100%;
          transform: scale(1, 0) translateZ(0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          background-repeat: repeat-x;
          z-index: -1;
        }
      }

      &:hover {
        a {
          text-decoration: none;
        }
        .text {
          &:before {
            transform: scale(1.15, 0.99999999) translateZ(0);
          }
        }
        .angle-right {
          transition: 0.5s;
          transform: translateX(10px);
        }
      }
    }


  }

  .custom-row {
    margin: 0px !important;
    //background: white;
  }

  .input-field {
    padding: 10px 0px;
  }

  .contact-address-container {
    padding-left: 5%;
    background-color: #000000;
    padding-top: 180px;
    padding-bottom: 50px;
    color: white;

    @media @large-mobile, @mobile {
      padding-top: 75px;
    }
  }

  .social-link-container {
    display: flex;
    justify-content: space-between;
    padding-top: 200px;
    width: 90%;
    flex-wrap: wrap;


    a {
      color: #BABABA;
      font-size: @content-font-size;
      line-height: @content-line-height;
      font-family: Roboto, Helvetica, Arial, sans-serif;

      @media @mobile, @large-mobile {
        font-size: 15px;
      }
    }

    @media @iPad-pro {
      padding-top: 230px;
    }

    @media @iPad {
      div {
        width: 100%;
      }
    }

    @media @mobile, @large-mobile {
      padding-top: 0px;
      width: 100%;
      justify-content: space-around;
    }
  }

  .custom-button{
    height: 58px;
    width: 150px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid @spaces-plus-dark-blue;
    background: @spaces-plus-dark-blue;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    .text {
      margin-bottom: 0;
    }

    &:hover {
      a {
        text-decoration: none;
      }
      .text {
        &:before {
          transform: scale(1.15, 0.99999999) translateZ(0);
        }
      }
      .angle-right {
        transition: 0.5s;
        transform: translateX(10px);
      }
    }
  }

  .booking-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 0 auto;

    @media @mobile, @large-mobile {
      width: unset;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-size: 42px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      color: #D9342A;
    }

    .input-container {
      background-color: #F3F3F3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 70px 30px;

      .contact-input-form {
        width: 100%;
        font-size: @content-font-size;
      }
    }
  }

  .booking-close-btn-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }

  .modal-booking-container {
    height: 98.5vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #booking-demo {
    padding-right: 0px !important;
    z-index: 999 !important;
  }

  .thank-you-message {
    color: #696969;
    font-size: 18px;
    line-height: 24px;
  }
  #demoBooking {
    padding-right: 0px !important;
    background: black;
    overflow: hidden !important;
    max-height: 100vh;
    z-index: 99999 !important;
  }

  .send-container {
    padding: 5vh 0px 2vh;
    display: flex;
    align-items: center;
  }

  #contact {
    margin-top: 60px !important;
  }

  .menu-logo {
    padding: 50px 0px 20px;
  }

  .little-m {
    transition: 0.3s;
    width: 200px;
    @media @mobile, @large-mobile {
      width: 220px;
    }
  }

  .contact-address-container--menu{
    background: white;
  }

  .modal-wrapper{
    height: 100vh;
  }

  .close-btn--menu {
    margin: 50px 50px 0px 0px;
  }
  .cross {
    cursor: pointer;
    width: 25px;
    float: right;
    margin-right: 10px;
    margin-top: 15px;
    @media @mobile, @large-mobile {
      width: 15px;
      margin-top: 18px;
    }
  }
  .contact-address-container--menu {
    padding-left: 10vw;

    @media @desktop{
      padding-left: 5vw;
      padding-right: 40px;
    }
    @media @mobile, @large-mobile {
      width: 15px;
      margin-top: 18px;
    }

    @media @iPad{
      padding-left: 20px;
    }
    @media @iPad-pro{
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .location-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 640px;
  }

  .book-a-demo-green{
    height: 58px;
    width: 190px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px !important;
    font-weight: 300 !important;
    border: 1px solid @spaces-plus-light-green;
    background: @spaces-plus-light-green;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 80px;
    @media @desktop{
      margin-bottom: 40px;
    }
    p{
      margin-bottom: 0;
    }
  }

  .get-started-content{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    line-height: @content-line-height;
    margin-top: 30px;
    margin-bottom: 80px;
    max-width: 650px;
    @media @desktop{
      margin-bottom: 40px;
    }
  }

  .get-started-title{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin-top: 50px;
    font-size: @title-font-size;
    line-height: @title-line-height;
    font-weight: bold;

    @media @large-desktop{
      margin-top: 90px;
    }

    @media @iPad-pro{
      margin-top: 30px;
    }
  }


  p{
    font-size: @content-font-size;
    line-height: @content-line-height;

    @media @iPad-pro {
      font-size: 18px;
    }
  }
}
</style>

