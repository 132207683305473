<template>
  <div v-if="homeItem.imageRight" class="home-item-wrapper">
    <div class="home-item-left container">
      <h1 class="title" v-html="homeItem.title"></h1>
      <p class="description" v-html="homeItem.description"></p>
      <div class="demo-button">
        <router-link :to="homeItem.link" v-if="homeItem.link" class="text" target="_blank">
          Launch Demo
        </router-link>
      </div>
    </div>
    <div class="home-item-right image-item" v-if="homeItem.hasImage">
      <img
        :src="homeItem.image"
        v-observe-visibility="{ callback: visibilityChanged, throttle: 0}"
        alt="Spaces+"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "home-item",
  props: ["homeItem"],
  mounted() {
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      // console.log('entry',entry)
      // console.log('isVisible',isVisible)
      // console.log('this.group',this.group)
      if (isVisible && entry) {
        entry.target.classList.add('animate-up')
        entry.target.classList.remove('column')

        setTimeout(function () {
          entry.target.classList.remove('overlay');
          entry.target.style.transition = "0.3s";
        }, 0);
      }
    },
  },
  computed: {
  }
};
</script>



<style lang="less" scoped>
  @import "../../../static/styles/settings.less";

  .animate-up{
    animation: 1.5s down-leave;
  }

  @keyframes down-leave {
    100% { clip-path: inset(0); }
    0% { clip-path: inset(100% 0 0 0); }
  }
  .overlay{
    background: @white !important;
    opacity: 0;
  }

  .left-side {
    margin-right: 10% !important;
    @media @mobile, @large-mobile {
      margin-left: 0;
    }
    .service-description{
      @media @mobile, @large-mobile {
        margin-left: 5%;
      }
    }

    @media @tablet{
      padding-right: 3%;
    }
  }

  .home-item-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vh;
    padding-bottom: 0;

    @media @iPad-pro {
      padding-left: 0% !important;
      padding-bottom: 5vh;
    }

    @media @tablet {
      padding-bottom: 5vh;
      padding-left: 4% !important;
    }

    @media @mobile, @large-mobile {
      margin: 0px auto 0px auto;
      flex-direction: column;
      padding-top: 50px;
    }

    .left-img {
      margin: 0px auto 50px auto;
    }

    .image-item {
      max-width: 100%;
      max-height: 561px;

      @media @mobile, @large-mobile {
        margin-top: 80px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .demo-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: @content-font-size;
        height: 58px;
        border-radius: 8px;
        background-color:  @spaces-plus-light-green;
        width: 170px;
        cursor: pointer;
        text-align: center;
        .text {
          color: @white;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        a{
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @white;
        }

      }

    .home-item-left {
      max-width: 45%;
      padding-left: 10%;
      padding-right: 0;
      @media @large-desktop{
        padding-left: 20%;
      }

      @media @iPad-pro {
        padding-left: 4%;
      }

      @media @tablet{
        padding-left: 0%;
      }
      @media @mobile, @large-mobile {
        padding-left: 0%;
        max-width: 90vw;
      }
      .title {
        font-size: 45px;
        line-height: 55px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        text-align: left;
        color: white;
        font-weight: 900;
        @media @mobile, @large-mobile, @tablet {
          font-size: 37px;
          line-height: 47px;
        }

        @media @iPad{
          font-size: 36px;
          line-height: 46px;
        }

        @media @small-desktop{
          font-size: 40px;
          line-height: 50px;
        }
      }
      .description {
        font-size: @content-font-size;
        line-height: @content-line-height;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 10px;
        color: white;
        font-weight: 500;
        max-width: 450px;
        @media @large-mobile, @mobile{
          max-width: 90%;
        }
      }

      .btn-featured-project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: @content-font-size;
        height: 58px;
        width: 208px;
        border-radius: 20px;
        cursor: pointer;
        text-align: center;
        .text {
          color: @white;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        a{
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

      .home-item-right {
        width: 50%;
        max-height: 100%;
        img{
          object-fit: contain;
          max-height: 100%;
          max-width: 100%;
        }
        @media @mobile, @large-mobile {
          width: 100%;
        }
      }

    }

    .service-description {
      max-width: 50%;
      padding-left: 5%;
      @media @tablet{
        padding-left: 5%;
      }
      @media @mobile, @large-mobile {
        padding-left: 0;
        max-width: 90vw;
      }
      .title {
        font-size: 42px;
        line-height: 54px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        text-align: left;

        @media @mobile, @large-mobile, @tablet {
          font-size: @title-font-size-mobile;
          line-height: 34px;
        }
      }
      .description {
        font-size: @content-font-size;
        line-height: 28px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .btn-featured-project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: @content-font-size;
        height: 58px;
        // width: 208px;
        border-radius: 20px;
        cursor: pointer;
        text-align: center;
        .text {
          color: @white;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        a{
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @white;
        }

    }

    @media @mobile, @large-mobile {
      #learning_experiences_desc{
        order: 2;
      }
    }


    .project-image {
      width: 100%;
      max-height: 655px;

      img {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        /*margin-right: -50%;*/
      }
      @media @mobile, @large-mobile {
        width: 100%;
        height: 230px;
      }
      @media @tablet {
        max-width: 300px;
      }
    }

    .project-image-mobile {
      @media @mobile, @large-mobile {
        img {
          width: 90vw;
        }
      }
      @media @tablet{
        margin-right: 0 !important;
      }

      @media @desktop, @large-desktop, @tablet {
        display: none;
      }
    }
  }

  .logos-container {
    flex-direction: row !important;
    // justify-content: space-between;

    display: flex;

    align-items: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    height: 58px;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    .text {
      color: @white;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    a{
      width: 100%;
      height: 100%;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      color: @white;
    }

    .logo-images {

      .mirk-image {
        width: 190px;

        @media @mobile {
          width: 100%;
        }

        @media @tablet {
          width: 100px;
        }
      }

      .image {
        width: 250px;

        @media @mobile {
          width: 100%;
        }

        @media @tablet {
          width: 100px;
        }
      }
    }

    @media @mobile, @large-mobile {
      // flex-direction: column !important;
      // justify-content: center;

    }

  }

  .video-controller-container {
    display: flex;
    align-items: center;
    padding: 30px 10px;

    .play-video-text {
      color: #6bbb43;
      padding: 0px 20px;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  #videoModalSpaces{
    padding-right: 0!important;
    .modal-dialog{
      max-width: 98% !important;
      min-width: 98% !important;
      height: auto;

      @media @mobile, @large-mobile, @tablet {
        max-width: 100% !important;
        min-width: 100% !important;
        margin: 0!important;
      }
    }
    .modal-body{
      padding: 0!important;
    }
    .modal-content{
      pointer-events: unset;
      background-color: unset;
      background-clip: padding-box;
      border: unset !important;
      outline: 0;
      min-height: 90vh !important;
    }

  }
  .seo-header {
    display: none;
  }

  .mirk-description {
    width: 40%;
    margin: 40px auto 40px auto;

    @media @mobile, @large-mobile {
      padding-bottom: 60px;
      width: 70%;
    }

    @media @desktop{
      width: 50%;
    }
  }
  .mirk-description .head-text{
    color: @black;
    text-align: center;
    font-weight: 100;
    font-size: 82px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 5.5vw;
    padding-bottom: 2%;
    margin-top: 150px;

    @media @desktop{
      font-size: 5vw;
    }

    @media @mobile, @large-mobile, @tablet {
      font-size: 42px;
      line-height: 53px;
      margin-top: 100px;
    }

  }

  .hr{
    width: 70px;
    text-align: center;
    display: block;
    height: 7px;
    background-color: @light-green;
    border: 0;

    @media @mobile, @large-mobile, @tablet {
      width: 61px;
      height: 6px;
    }
  }
  .hero-video {
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    @media @mobile, @large-mobile, @tablet  {
      display: none;
    }
    @media @small-desktop{
      width: 90%;
    }
  }

  .mobile-hero {
    display: none;
    @media @mobile, @large-mobile, @tablet  {
      display: block;
    }
  }

.video-container {
  video {
    margin-bottom: -12px;
  }

  .services-video {
    width: 100%;
  }

  .book-a-demo-container {
    display: flex;
    justify-content: center;
    background-color: #6cbb43;
    padding: 34px;
  }

  .demo-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: @content-font-size;
    height: 58px;
    width: 208px;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
    .text {
      color: #6cbb43;;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    a{
      width: 100%;
      height: 100%;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
