<template>
  <div style="overflow: hidden">
    <iframe
      width="100%"
      height="100%"
      src="https://demo.spacesplus.co"
      frameBorder="0"
      allowFullScreen
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      onmousewheel=""
      style="border: none"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "SpacesPlus"
}
</script>

<style>
#appy{
  width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*body{*/
/*  overflow: hidden;*/
/*}*/
</style>
