<template>
  <div>
    <div class="package-content">
      <h1 class="section-title">
        Discover a range of </br>different
        <span style="color: #72BF00">venue styles</span>
      </h1>
      <p class="trusted-by">Our virtual venues give you the chance to customise and personalise. The chance to prioritise what is important to your brand. Built to resonate Spaces+ helps you to truly hook users, connecting with them well beyond the widely implemented 2D digital experience.</p>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="5000"
        controls
        background="white"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <div v-for="item in carouselData" :key="item.id">
          <b-carousel-slide :img-src="item.imageUrl" img-alt="3D environment"></b-carousel-slide>
        </div>
      </b-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "carousel",
  data() {
    return {
      slide: 0,
      sliding: null,
      carouselData: [
        {
          id: 0,
          imageUrl: "/images/carousel/0.jpg",
        },
        {
          id: 1,
          imageUrl: "/images/carousel/1.jpg",
        },
        {
          id: 2,
          imageUrl: "/images/carousel/2.jpg",
        },
        {
          id: 3,
          imageUrl: "/images/carousel/3.jpg",
        },
        {
          id: 4,
          imageUrl: "/images/carousel/4.jpg",
        },
        {
          id: 5,
          imageUrl: "/images/carousel/5.jpg",
        },
        {
          id: 6,
          imageUrl: "/images/carousel/6.jpg",
        },
        {
          id: 7,
          imageUrl: "/images/carousel/7.jpg",
        },
        {
          id: 8,
          imageUrl: "/images/carousel/8.jpg",
        },
        {
          id: 9,
          imageUrl: "/images/carousel/9.jpg",
        },
        // {
        //   id: 10,
        //   imageUrl: "/images/carousel/10.jpg",
        // },
        // {
        //   id: 11,
        //   imageUrl: "/images/carousel/11.jpg",
        // },
        // {
        //   id: 12,
        //   imageUrl: "/images/carousel/12.jpg",
        // },
        // {
        //   id: 13,
        //   imageUrl: "/images/carousel/13.jpg",
        // },
        // {
        //   id: 14,
        //   imageUrl: "/images/carousel/14.jpg",
        // },
      ]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../static/styles/settings.less";

.package-content {
  padding-top: 10vh;
  padding-bottom: 15vh;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 5% !important;

  @media @tablet {
    padding-bottom: 0px;
  }

  @media @mobile, @large-mobile {
    width: 95%;
    padding-left: 0 !important;
    padding-bottom: 5vh;
    padding-top: 5vh;
  }

  @media @iPad, @small-desktop, @iPad-pro{
    width: 90%;
    padding-left: 0 !important;
  }
  @media @iPad{
    padding-top: 5vh !important;
    padding-bottom: 5vh !important;
  }
  h1 {
    color: @spaces-plus-dark-blue;
    width: 100%;
    font-size: @title-font-size;
    line-height: @title-line-height;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin-top: 5vh;
    margin-bottom: 5vh;
    @media @mobile, @large-mobile, @tablet {
      font-size: @title-font-size-mobile;
      line-height: @title-line-height-mobile;
      margin-bottom: 5vh;
    }
  }
  .trusted-by {
    color: @spaces-plus-dark-blue;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    //padding-left: 20px;
    font-size: @content-font-size;
    line-height: @content-line-height;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 550px;
  }

}
</style>
