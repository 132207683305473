<template lang="html">
  <footer class="footer-container-new">
    <div class="row footer-bottom">
      <div class="logos-container col-sm-12 col-md-10 col-lg-10">
        <div class="logos-images">
          <a href="/">
            <img
              class="spaces-image"
              src="/images/Spaces+Dark.png"
              alt="Spaces Interactive"
            />
          </a>
          <div v-if="dt" class="item copyright">
            <span class="item" style="font-size: 13px;">© {{dt.getFullYear()}} Spaces+</span>
          </div>
          <div v-else class="item copyright">
            <span class="item" style="font-size: 13px;">© Spaces+</span>
          </div>
        </div>
      </div>

      <router-link :to="{ name: 'privacy-policy' }" class="privacy-policy-link col-sm-12 col-md-2 col-lg-2 align-content-end">
        <span class="item" style="font-size: 14px;">Privacy Policy</span>
      </router-link>

    </div>

  </footer>

</template>

<script>

export default {
  name: "FooterCopyright",
  data() {
    return {
      dt: "",
    };
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    this.dt = new Date();

  },
  methods: {
  },
};
</script>

<style lang="less">
@import "../../../static/styles/settings.less";
.custom-col {
  // max-width: 70%;
  padding-left: 0;
  margin-bottom: 40px;
  @media @mobile {
    margin-bottom: 10px;
  }
}
.footer-container-new {
  width: 88.5%;
  max-width: 1800px;
  @media @mobile {
    width: 90% !important;
    padding: 5px !important;
  }
  .col-6 {
    @media @mobile, @large-mobile, @tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .mobile {
    @media @mobile {
      padding-bottom: 10px;
      flex: 0 0 100% !important;
    }
  }
  .stay-in-loop {
    padding-left: 0;
    @media @mobile {
      padding-top: 35px;
    }
    @media @tablet {
      padding-top: 20px;
    }
  }

  .footer-bottom {
    // padding-left: 15px;

    @media @mobile {
      margin-top: 0px;
    }

    .col-8 {
      @media @mobile, @large-mobile {
        max-width: 100%;
        flex: 0 0 100% !important;
        padding-bottom: 35px;
      }
    }
  }
}

.opaque {
  opacity: 0;
  transform: translateY(200px);
}

a:hover {
  text-decoration: none;
}
// .footer-container-new {
//   width: 90%;
//   max-width: 1600px;
//   // width: 64%;
//   // margin: 25%;
//   background-color: white;
//   box-sizing: border-box;
//   padding: 90px 0 90px 0;
//   margin: 0 auto;
//   @media @mobile, @large-mobile {
//     margin: 100px auto;
//   }
// }

#addresses {
  margin: 0 auto;
}

.address-header {
  font-size: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 23px;
}

.address {
  font-size: 19px;
  line-height: 23px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: @font-grey;
  @media @mobile, @large-mobile {
    font-size: 14px;
    line-height: 21px;
  }
}

.footer-info-description {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: @black;
  margin-bottom: 5px;
  @media @mobile, @large-mobile {
    font-size: 12px;
  }
}
.footer-info-description-text {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: @black;
  @media @mobile, @large-mobile {
    font-size: 12px;
  }
}
.footer-info-header {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 23px;
  @media @mobile, @large-mobile {
    font-size: 14px;
  }
}

#social-media {
  display: flex;
  .item {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    margin-right: 15px;
    color: @font-grey;
    .link {
      color: @font-grey;
    }
  }
}

.copyright {
  color: @font-grey;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;


  @media @mobile, @large-mobile {
    /*padding-left: 15px !important;*/
    justify-content: center;
  }
}
.upperCase {
  text-transform: uppercase;
}

.col-5 {
  @media @mobile, @large-mobile, @tablet {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.mirk-home-link {
  p {
    font-size: 1.2vw;
    font-weight: bold;
    color: black;
    text-decoration: underline;
  }
}


.logos-container {

  @media @tablet {
    flex-direction: row !important;
  }

  @media @mobile, @large-mobile {
    flex-direction: column !important;
    justify-content: center;
  }


  .logos-images {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px !important;

    @media @mobile, @large-mobile {
      padding: 20px 0px;
      justify-content: center;
      // align-items: center;
      // align-content: center;
      // justify-items: center;
      flex-direction: column;
    }

    .footer-logo-container {
      display: flex;
      justify-content: center;
    }

    .spaces-image {
      width: 200px;

      @media @mobile, @large-mobile {
        width: 200px;
        margin-bottom: 20px;
      }
    }

    .image {
      width: 250px;

      @media @mobile {
        width: 100%;
      }
    }
  }
}

.privacy-policy-link{
  color: @spaces-plus-dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: revert;
  line-height: 30px;
  .item{
    border-bottom: 1px solid @spaces-plus-dark-blue;
  }
}
</style>

