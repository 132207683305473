module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('form', {
    staticClass: "section section--menu form contact-form-only"
  }, [_c('div', {
    class: _vm.emailData ? 'row custom-row configurator-form' : 'row custom-row'
  }, [(!_vm.emailData) ? _c('div', {
    staticClass: "col-md-12 input-field"
  }, [_c('h1', [_vm._v("Contact.")])]) : _vm._e(), _vm._v(" "), (_vm.emailData) ? _c('div', {
    staticClass: "col-md-12 input-field configurator-form-msg"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Thank you for answering these questions! If you would like to provide your name and email address we'll be in touch as soon as possible to arrange a time to discuss your project and relevant pricing information.")])]) : _c('div', {
    staticClass: "col-md-12 input-field"
  }, [_vm._m(0)]), _vm._v(" "), _c('div', {
    staticClass: "col-md-12 col-lg-8 name input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.enquiry.name),
      expression: "enquiry.name"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "placeholder": "Name",
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.enquiry.name)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.enquiry, "name", $event.target.value)
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "col-md-12 col-lg-8 email input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.enquiry.email),
      expression: "enquiry.email"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "placeholder": "Email",
      "type": "email",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.enquiry.email)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.enquiry, "email", $event.target.value)
      }
    }
  })]), _vm._v(" "), (!_vm.emailData) ? _c('div', {
    staticClass: "col-md-12 col-lg-8 input-field"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: (_vm.enquiry.message),
      expression: "enquiry.message"
    }],
    staticClass: "contact-input-form",
    attrs: {
      "rows": "4",
      "placeholder": "Message",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": (_vm.enquiry.message)
    },
    on: {
      "input": function($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.enquiry, "message", $event.target.value)
      }
    }
  })]) : _vm._e()]), _vm._v(" "), (_vm.emailData) ? _c('div', {
    staticClass: "col-12 send-container--menu justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "row justify-content-center align-items-center"
  }, [_c('button', {
    staticClass: "btn-take-a-look custom-button hoverable",
    on: {
      "click": function (e) { return _vm.submitForm(e); }
    }
  }, [_c('span', {
    staticClass: "text"
  }, [_vm._v("\n          SEND\n        ")])])]), _vm._v(" "), (_vm.snackbarStatus) ? _c('div', {
    staticClass: "snackBar row justify-content-center align-items-center"
  }, [_c('p', {
    staticClass: "thank-you-message",
    staticStyle: {
      "margin-top": "20px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.snackbarText)
    }
  })]) : _vm._e(), _vm._v(" "), (_vm.errorMessage) ? _c('div', {
    staticClass: "row error-msg-container error-msg-container--menu justify-content-center align-items-center"
  }, [_c('p', {
    staticClass: "error-msg",
    staticStyle: {
      "margin-top": "20px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  })]) : _vm._e()]) : _c('div', {
    staticClass: "row send-container--menu"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-4"
  }, [_c('button', {
    staticClass: "btn-take-a-look custom-button hoverable",
    on: {
      "click": function (e) { return _vm.submitForm(e); }
    }
  }, [_c('span', {
    staticClass: "text"
  }, [_vm._v("\n          SEND\n        ")])])]), _vm._v(" "), (_vm.snackbarStatus) ? _c('div', {
    staticClass: "snackBar col-md-12 col-lg-8"
  }, [_c('p', {
    staticClass: "thank-you-message",
    domProps: {
      "innerHTML": _vm._s(_vm.snackbarText)
    }
  })]) : _vm._e(), _vm._v(" "), (_vm.errorMessage) ? _c('div', {
    staticClass: "col-md-12 col-lg-8 error-msg-container error-msg-container--menu"
  }, [_c('p', {
    staticClass: "error-msg",
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  })]) : _vm._e()])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('p', [_vm._v("Tell us about your project "), _c('br'), _vm._v("and we’ll be in touch.")])
}]}