module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [(_vm.shouldApply) ? _c('transition', {
    attrs: {
      "name": _vm.transition,
      "mode": _vm.mode
    }
  }, [_vm._t("default")], 2) : _vm._e(), _vm._v(" "), (_vm.shouldApply) ? _c('div', {
    staticClass: "overlay-top"
  }) : _vm._e(), _vm._v(" "), (_vm.shouldApply) ? _c('div', {
    staticClass: "overlay-right"
  }) : _vm._e(), _vm._v(" "), (_vm.shouldApply) ? _c('div', {
    staticClass: "overlay-bottom"
  }) : _vm._e(), _vm._v(" "), (_vm.shouldApply) ? _c('div', {
    staticClass: "overlay-left"
  }) : _vm._e()], 1)
},staticRenderFns: []}