module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return (_vm.homeItem.imageRight) ? _c('div', {
    staticClass: "home-item-wrapper"
  }, [_c('div', {
    staticClass: "home-item-left container"
  }, [_c('h1', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.homeItem.title)
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.homeItem.description)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "demo-button"
  }, [(_vm.homeItem.link) ? _c('router-link', {
    staticClass: "text",
    attrs: {
      "to": _vm.homeItem.link,
      "target": "_blank"
    }
  }, [_vm._v("\n        Launch Demo\n      ")]) : _vm._e()], 1)]), _vm._v(" "), (_vm.homeItem.hasImage) ? _c('div', {
    staticClass: "home-item-right image-item"
  }, [_c('img', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: ({
        callback: _vm.visibilityChanged,
        throttle: 0
      }),
      expression: "{ callback: visibilityChanged, throttle: 0}"
    }],
    attrs: {
      "src": _vm.homeItem.image,
      "alt": "Spaces+"
    }
  })]) : _vm._e()]) : _vm._e()
},staticRenderFns: []}