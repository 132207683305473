<template>
    <div class="mirk-navbar" id="mirk-navbar" style="opacity: 0">
        <div id="navbar-desktop">
            <div class="row" id="home">
              <div class="brand-logo col-lg-3 col-md-3">
                  <div class="head-block">
                  <div class="hoverable" @click="navigateTo('home', 'mirk-navbar')" aria-label="mirk-navbar" role="button">
                      <img v-if="logoColor==='black'" role="button" class="spaces-plus-logo" src="../../static/images/Logo.png" alt="Spaces+">
                      <img v-else role="button" class="spaces-plus-logo" src="../../static/images/Logo.png" alt="Spaces+">
                  </div>
                  </div>
              </div>
              <div class="brand-logo col-lg-9 col-md-9">
                <div class="row justify-content-between align-items-center">
                  <div>
                    <div class="hoverable">
                      <div class="nav-button" @click="navigateTo('home', 'features')" aria-label="features" role="button">
                        <span>Features</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hoverable">
                      <div class="nav-button" @click="navigateTo('home', 'packages')" aria-label="packages" role="button">
                        <span>Packages</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hoverable">
                      <div class="nav-button" @click="navigateTo('home', 'how-it-works')" aria-label="how-it-works" role="button">
                        <span>How It Works</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hoverable">
                      <div class="nav-button" @click="navigateTo('home', 'configurator')" aria-label="configurator" role="button">
                        <span>Find your Space</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hoverable">
                      <div
                        class="nav-button-get-started"
                        role="button"
                        aria-label="packages"
                        data-toggle="modal"
                        data-target="#demoBooking"
                        @click="gtag_report_conversion(`https://spacesplus.co/book-a-demo`)"
                      >
                        <span>Book a Consultation</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hoverable">
                      <div
                        class="nav-button-book-demo"
                        aria-label="book-a-demo"
                        role="button"
                      >
                        <router-link to="/demo" class="text" target="_blank">
                          Launch Demo
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <logo-burger id="navbar-mobile" mirkSmallLogoColor="#ffffff"></logo-burger>
    </div>
</template>

<script>
import Logo from "./Logo.vue"
import Hamburger from "./Hamburger.vue"
import BlackHamburger from "./BlackHamburger.vue"
import LogoBurger from "./LogoBurger.vue"
import GetStarted from "./HomeComponents/GetStarted";
    export default {
        name: 'navigation-bar',
        props: ["mirkLogoColor"],
        components:{
          GetStarted,
            Logo,
            Hamburger,
            BlackHamburger,
            LogoBurger
        },
        mounted(){
          /* CONVERSION - GOOGLE ADS */
          window.dataLayer = window.dataLayer || [];
          this.gtag('js', new Date());
          this.gtag('config', 'AW-654851161');

          // console.log('this.$router', this.$router)
          if(this.$router && this.$router.currentRoute && this.$router.currentRoute.name === "news"){
            setTimeout( function () {
              document.getElementById('mirk-navbar').style.opacity = 1;
              document.getElementById('mirk-navbar').style.transition = "0.5s";
              document.getElementById('navbar-desktop').style.opacity = 1;
              document.getElementById('navbar-desktop').style.transition = "0.5s";
              // document.getElementById('bburger').style.opacity = 1;
              // document.getElementById('bburger').style.transition = "0.5s";
            }.bind(this), 10);
          } else {
            // load navbar then menu icon
            setTimeout( function () {
              document.getElementById('mirk-navbar').style.opacity = 1;
              document.getElementById('mirk-navbar').style.transition = "0.5s";
              setTimeout( function () {
                document.getElementById('navbar-desktop').style.opacity = 1;
                document.getElementById('navbar-desktop').style.transition = "0.5s";
                // setTimeout( function () {
                //   document.getElementById('bburger').style.opacity = 1;
                //   document.getElementById('bburger').style.transition = "0.5s";
                // }.bind(this), 1000);
              }.bind(this), 30);
            }.bind(this), 10);
          }
        },
        methods: {
            gtag(){
              /* CONVERSION - GOOGLE ADS */
              // console.log("gtag ", arguments)
              dataLayer.push(arguments);
            },
            gtag_report_conversion(url) {
              /* CONVERSION - GOOGLE ADS */
              // console.log("gtag_report_conversion ", url)
              var callback = function () {
                // console.log("callback")
                // if (typeof(url) != 'undefined') { window.location = url; }
              };
              this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/fSRZCIq24f8BENn4oLgC', 'event_callback': callback });
              return false;
            },
            gtag_report_conversion_get_started(url) {
              /* CONVERSION - GOOGLE ADS */
              // console.log("gtag_report_conversion ", url)
              var callback = function () {
                // console.log("callback")
                // if (typeof(url) != 'undefined') { window.location = url; }
              };
              this.gtag('event', 'conversion', { 'send_to': 'AW-654851161/o70BCMHG-P8BENn4oLgC', 'event_callback': callback });
              return false;
            },
            toggleMenu() {
              this.$store.state.showMenu = !this.$store.state.showMenu;
            },
            navigateTo(destination, data) {
              // console.log("destination ", destination)
              // console.log("data ", data)
              this.$router.push({
                name: destination,
                params: {data: data}
              });
              let doc = document.getElementById(data)
              // console.log("doc ", doc)

              if(doc){
                // wait some time then scroll
                setTimeout(function() {
                  const y = doc.getBoundingClientRect().top + window.scrollY;
                  let height = window.screen.height;
                  // offset % of the height
                  let offsetHeight = height*.07
                  window.scroll({
                    top: y-offsetHeight
                  });
                }.bind(this), 200)
              }
            }
        },
        data() {
            return {
                logoColor: this.mirkLogoColor,
            };
        },
    }
</script>

<style lang="less" scoped>
@import "../../static/styles/settings.less";

#navbar-mobile {
    //padding-top: 50px;
    display: none;
    @media @mobile, @large-mobile, @tablet {
        display:block;
    }
}

#navbar-desktop {
    display: block;
    @media @mobile, @large-mobile, @tablet {
        display:none;
    }
}

.mirk-navbar {
    padding-bottom: 20px;
    position: relative;
    top:0;
    left:0;
    width: 100%;
    background: @spaces-plus-dark-blue;
    max-width: 100vw;
    #navbar-desktop{
        width: 90%;
        max-width: 1600px;
        padding-top: 120px;
        margin: 0px auto;

      @media @mobile, @large-mobile, @tablet {
        padding-top: 10px;
        width: 90%;
      }
      @media @iPad-pro {
        padding-top: 20px;
        width: 95%;
      }

      @media @small-desktop{
        padding-top: 10px;
        width: 95%;
      }
    }
}

  .spaces-plus-logo{
    max-height: 64px;
    @media @mobile, @large-mobile, @tablet{
     max-width: 40vw;
    }
    @media @iPad-pro, @iPad{
     max-height: 50px;
    }
  }

  .nav-button{
    span{
      color: white;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      font-weight: 300;

      @media @iPad, @iPad-pro, @tablet{
        font-size: 18px;
      }
      @media @iPad-pro{
        font-size: 15px;
      }
    }
    @media @mobile, @large-mobile{
      display: none;
    }

    @media @small-desktop{
      display: block;
    }
  }

  .nav-button-get-started{
    height: 58px;
    width: 190px;
    color: #16A7C9;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #16A7C9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media @mobile, @large-mobile {
      //display: none;
      width: 175px;
      margin-right: 10px;
    }

    @media @iPad{
      height: 50px;
    }
    @media @iPad-pro{
      font-size: 16px;
      height: 50px;
      width: 170px;
    }
  }
  .nav-button-book-demo{
    height: 58px;
    width: 170px;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid @spaces-plus-light-green;
    background: @spaces-plus-light-green;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    @media @iPad-pro{
      font-size: 16px;
      height: 50px;
      width: 170px;
    }

    @media @mobile, @large-mobile {
      display: none;
    }

    a{
      color: white;
    }
    @media @iPad, @iPad-pro{
      height: 50px;
      width: 150px;
    }
  }

  .brand-logo{
    //padding-right: 40px;
    //padding-left: 40px;
    @media @mobile, @large-mobile{
      padding-right: 10px;
      padding-left: 10px;
      width: 49% !important;
      max-width: 49% !important;
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      .justify-content-between{
        justify-content: center !important;
        align-items: center;
      }
    }
    @media @tablet{
      width: 60% !important;
      max-width: 60% !important;
      padding-right: 20px;
      padding-left: 20px;
    }

    @media @small-desktop{
      padding-right: 10px;
      padding-left: 10px;
    }
  }
</style>
