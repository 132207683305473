module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "hamburger magnetic-button",
    attrs: {
      "offset-hover-max": "2",
      "offset-hover-min": "1"
    }
  }, [_c('svg', {
    attrs: {
      "width": "26px",
      "height": "17px",
      "viewBox": "0 0 26 17",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('defs'), _vm._v(" "), _c('g', {
    attrs: {
      "id": "Welcome",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "home",
      "transform": "translate(-1369.000000, -116.000000)",
      "fill": "#333333"
    }
  }, [_c('g', {
    attrs: {
      "id": "Header",
      "transform": "translate(-5.000000, -9.000000)"
    }
  }, [_c('g', {
    attrs: {
      "id": "MENU",
      "transform": "translate(1374.000000, 125.000000)"
    }
  }, [_c('polygon', {
    attrs: {
      "id": "Fill-1",
      "points": "0 4 26 4 26 0 0 0"
    }
  }), _vm._v(" "), _c('polygon', {
    attrs: {
      "id": "Fill-2",
      "points": "0 17 26 17 26 13 0 13"
    }
  })])])])])])])
},staticRenderFns: []}