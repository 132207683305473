module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', [_c('VuePageTransition', {
    attrs: {
      "name": _vm.$route.meta.transition,
      "mode": "out-in",
      "id": "page-transition",
      "shouldApply": "true"
    }
  }, [_c('div', {
    key: _vm.$route.path,
    attrs: {
      "id": "app"
    }
  }, [_c('burger-slider'), _vm._v(" "), _c('menu-new'), _vm._v(" "), _c('router-view', {
    staticClass: "app-container",
    attrs: {
      "id": "appy"
    }
  }), _vm._v(" "), _c('CustomCursor', {
    key: _vm.$route.name,
    attrs: {
      "id": "cursor"
    }
  })], 1)])], 1)
},staticRenderFns: []}