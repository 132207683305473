module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "configurator",
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticClass: "config-content"
  }, [(!_vm.displayForm) ? _c('div', {
    staticClass: "questions"
  }, [_c('div', {
    staticClass: "your-event"
  }, [_c('div', {
    staticClass: "row-item your-event-title justify-content-start"
  }, [_c('div', {
    staticClass: "col-1",
    staticStyle: {
      "padding-right": "0"
    },
    attrs: {
      "aria-label": "go-back",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.goBack()
      }
    }
  }, [_c('b-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "arrow-left"
    }
  })], 1), _vm._v(" "), _vm._m(0)]), _vm._v(" "), (this.currentQuestionIndex < this.questions.length - 1) ? _c('div', {
    staticClass: "row-item your-event-title"
  }, [_c('h3', [_vm._v("Q" + _vm._s(this.currentQuestionIndex + 1) + " of " + _vm._s(this.questions.length - 1))])]) : _vm._e(), _vm._v(" "), (this.currentQuestionIndex < this.questions.length - 1) ? _c('div', {
    staticClass: "row-item your-event-title"
  }, [_c('h4', [_vm._v("\n            " + _vm._s(this.currentQuestion.title) + "\n          ")])]) : _vm._e(), _vm._v(" "), (this.currentQuestion.imageUrl) ? _c('div', {
    staticClass: "row-item image-row"
  }, [_c('img', {
    attrs: {
      "src": this.currentQuestion.imageUrl,
      "alt": "Room image"
    }
  })]) : _vm._e(), _vm._v(" "), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(ref) {
        var ariaDescribedby = ref.ariaDescribedby;

        return [(_vm.currentQuestion && _vm.currentQuestion.options) ? _c('div', {
          staticClass: "row-item options-row"
        }, [_c('div', {
          staticClass: "col-sm-12 col-md-12 col-lg-6",
          staticStyle: {
            "padding-right": "30px",
            "padding-left": "0"
          }
        }, [_c('div', _vm._l((_vm.currentQuestion.options), function(item) {
          return (_vm.currentQuestion && _vm.currentQuestion.options) ? _c('div', {
            key: item.id,
            staticClass: "button-wrapper"
          }, [_c('div', {
            staticClass: "row-item light-blue-btn",
            on: {
              "click": function($event) {
                return _vm.handleSelect(item)
              }
            }
          }, [_c('b-form-radio', {
            staticClass: "custom-control-radio",
            attrs: {
              "name": "answer",
              "value": item.value,
              "aria-describedby": ariaDescribedby
            },
            model: {
              value: (item.id),
              callback: function($$v) {
                _vm.$set(item, "id", $$v)
              },
              expression: "item.id"
            }
          }, [_c('div', {
            staticClass: "custom-label"
          }, [_c('span', [_vm._v("\n                        " + _vm._s(item.option) + "\n                      ")]), _vm._v(" "), (item.icon) ? _c('b-icon', {
            staticClass: "icon",
            attrs: {
              "icon": item.icon
            }
          }) : _vm._e()], 1)])], 1)]) : _vm._e()
        }), 0)]), _vm._v(" "), _c('div', {
          staticClass: "col-sm-12 col-md-12 col-lg-6",
          staticStyle: {
            "padding-right": "0"
          }
        }, [_c('div', _vm._l((_vm.currentQuestion.options2), function(item) {
          return (_vm.currentQuestion && _vm.currentQuestion.options2) ? _c('div', {
            key: item.id,
            staticClass: "button-wrapper"
          }, [_c('div', {
            staticClass: "row-item light-blue-btn",
            on: {
              "click": function($event) {
                return _vm.handleSelect(item)
              }
            }
          }, [_c('b-form-radio', {
            staticClass: "custom-control-radio",
            attrs: {
              "name": "answer",
              "value": item.value,
              "aria-describedby": ariaDescribedby
            },
            model: {
              value: (item.id),
              callback: function($$v) {
                _vm.$set(item, "id", $$v)
              },
              expression: "item.id"
            }
          }, [_c('div', {
            staticClass: "custom-label"
          }, [_c('span', [_vm._v("\n                        " + _vm._s(item.option) + "\n                      ")]), _vm._v(" "), (item.icon) ? _c('b-icon', {
            staticClass: "icon",
            attrs: {
              "icon": item.icon
            }
          }) : _vm._e()], 1)])], 1)]) : _vm._e()
        }), 0)])]) : _vm._e(), _vm._v(" "), (_vm.currentQuestion && _vm.currentQuestion.images) ? _c('div', {
          staticClass: "row-item options-row"
        }, [_c('div', {
          staticClass: "col-sm-12 col-md-12",
          staticStyle: {
            "padding-right": "0"
          }
        }, [_c('div', {
          staticClass: "row-item options-row"
        }, [_c('div', {
          staticClass: "col-12",
          staticStyle: {
            "padding-right": "0",
            "padding-left": "0"
          }
        }, [_c('h5', {
          staticClass: "font-weight-bold text-center"
        }, [_vm._v("Thank you for taking the time to provide this information.")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('h5', {
          staticClass: "text-center"
        }, [_vm._v("\n                    Based upon your answers to our questions our recommended Spaces+ layout and inclusions are:\n                  ")]), _vm._v(" "), _vm._l((_vm.emailData), function(recommendation, index) {
          return _c('div', {
            key: recommendation.question,
            staticClass: "question-wrapper",
            staticStyle: {
              "padding-right": "0"
            }
          }, [(recommendation.feature) ? _c('ul', {
            staticClass: "row-item justify-content-center"
          }, [_c('li', [_vm._v("\n                        " + _vm._s(recommendation.feature) + "\n                      ")])]) : _vm._e()])
        }), _vm._v(" "), _c('br'), _vm._v(" "), _c('h5', {
          staticClass: "text-center"
        }, [_vm._v("\n                    Please select a theme that suits your project:\n                  ")])], 2)]), _vm._v(" "), _c('div', {
          staticClass: "form-group-custom row"
        }, _vm._l((_vm.currentQuestion.images), function(item) {
          return (_vm.currentQuestion && _vm.currentQuestion.images) ? _c('div', {
            key: item.id,
            staticClass: "button-wrapper col-images col-sm-12 col-md-3"
          }, [_c('img', {
            staticClass: "theme-image",
            attrs: {
              "src": item.imageUrl,
              "alt": "theme image"
            }
          }), _vm._v(" "), _c('div', {
            staticClass: "row-item light-blue-btn",
            on: {
              "click": function($event) {
                return _vm.handleSelect(item)
              }
            }
          }, [_c('b-form-radio', {
            staticClass: "custom-control-radio",
            attrs: {
              "name": "answer",
              "value": item.value
            },
            model: {
              value: (item.id),
              callback: function($$v) {
                _vm.$set(item, "id", $$v)
              },
              expression: "item.id"
            }
          }, [_c('div', {
            staticClass: "custom-label"
          }, [_c('span', [_vm._v("\n                          " + _vm._s(item.option) + "\n                        ")]), _vm._v(" "), (item.icon) ? _c('b-icon', {
            staticClass: "icon",
            attrs: {
              "icon": item.icon
            }
          }) : _vm._e()], 1)])], 1)]) : _vm._e()
        }), 0)])]) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "btn-next",
          attrs: {
            "aria-label": "go-back",
            "role": "button"
          },
          on: {
            "click": function($event) {
              return _vm.goNext()
            }
          }
        }, [_vm._v("\n            Next\n          ")])]
      }
    }], null, false, 1854045955)
  })], 1), _vm._v(" "), _c('p', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.messageError))])]) : (_vm.displayForm) ? _c('div', {
    staticClass: "questions"
  }, [_c('div', {
    staticClass: "your-event contact-form"
  }, [_c('div', {
    staticClass: "row-item your-event-title justify-content-start"
  }, [_c('div', {
    staticClass: "col-1",
    staticStyle: {
      "padding-left": "0",
      "padding-right": "0"
    },
    attrs: {
      "aria-label": "go-back",
      "role": "button"
    },
    on: {
      "click": function($event) {
        return _vm.goBack()
      }
    }
  }, [_c('b-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "arrow-left"
    }
  })], 1), _vm._v(" "), _vm._m(1)]), _vm._v(" "), _c('div', {
    staticClass: "row-item"
  }, [_c('ContactForm', {
    attrs: {
      "emailData": _vm.emailData
    }
  })], 1)])]) : _vm._e()])])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('h2', {
    staticClass: "col-11",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_vm._v("Your"), _c('span', [_vm._v(" Event")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('h2', {
    staticClass: "col-11",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_vm._v("Your"), _c('span', [_vm._v(" Event")])])
}]}