<template>
  <div>
<!--    <lottie id="lottie-cont" style="z-index:999999; background-color: white; position: fixed; overflow: hidden; width: 100%; height: 100%;" :showLoader="isLoading" :options="defaultOptions" :height="320" :width="320"/>-->
    <div id="home-cont">
      <navigation-bar mirkLogoColor="black" ></navigation-bar>

      <div class="services-listing-container">
        <HomeItem :homeItem="firstItem"></HomeItem>
      </div>

      <PrivacyPolicy/>

      <div id="book-a-demo">
        <Footer/>
        <FooterCopyright/>
      </div>

      <BookADemoModal/>
    </div>
 </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar';
  import Lottie from '../components/lottie.vue'
  import HomeItem from '../components/HomeComponents/HomeItem'
  import ClientLogos from "../components/HomeComponents/ClientLogos";
  import VideoContainer from "../components/HomeComponents/VideoContainer";
  import BannerSection from "../components/HomeComponents/BannerSection";
  import TextOnlyBanner from "../components/HomeComponents/TextOnlyBanner";
  import PackageOptions from "../components/HomeComponents/PackageOptions";
  import Features from "../components/HomeComponents/Features";
  import Footer from "../components/HomeComponents/Footer";
  import FooterCopyright from "../components/HomeComponents/FooterCopyright";
  import BookADemoModal from "../components/HomeComponents/BookADemoModal";
  import PrivacyPolicy from "../components/PrivacyPolicy";

  export default {
    components: {
      PrivacyPolicy,
      BookADemoModal,
      FooterCopyright,
      Footer,
      Features,
      PackageOptions,
      TextOnlyBanner,
      BannerSection,
      VideoContainer,
      ClientLogos,
      NavigationBar,
      Lottie,
      HomeItem
    },
    data() {
      return {
        isLoading:false,
        hideDesktopComponent:false,

        firstItem: {
          id: 'home',
          title: 'Engage, inform </br> and inspire with </br> <span style="color: #72BF00">confidence.</span>',
          description: 'Your virtual venue - Events, Brand Experiences, Product Showcases and Interactive Networking.',
          image: './images/desktop.png',
          hasImage: true,
          imageRight: true,
          hasFeatured: true,
          link: '/demo',
          isFirstItem: true
        },
        secondItem:{
          id: "audience",
          backgroundColor: '#E0F0C7',
          textColor: '#072635',
          title: 'Audiences</br> navigate, explore </br> and <span style="color: #72BF00">truly engage.</span>',
          description: '<span style="color: #072635; font-weight: 500">A space that brings your brand and messages to life.</span></br></br>  Spaces+ provides a genuine 3D digital interactive environment to explore and be truly engaged. Immersive 3D virtual rooms to communicate with customers, showcase products, host events and facilitate networking.'
        },
        fourthItem:{
          id: "local",
          backgroundColor: '#072635',
          imageURL: "../../../static/images/audience.png",
          title: 'Bring your</br> <span style="color: #72BF00">brand to life.</span> ',
          description: 'Our entire development and project support team is based right here in Australia. Trusted by global brands.',
          imagePosition: 'right'
        },
        fifthItem: {
          id: "getting-started",
          backgroundColor: '#DDE7E8',
          textColor: "#072635",
          imageURL: "../../../static/images/audience.png",
          title: 'Getting started</br>with <span style="color: #72BF00">Spaces+ is easy.</span>',
          description: 'It takes just a few simple steps to bring your virtual experience to life within 2 weeks.',
          imagePosition: 'left',
          list: [
            // "Select the room configuration that best suits your needs",
            // "Choose the user services that you want to be part of your virtual experience",
            // "Select the look and feel that best matches your brand",
            // "Enter some simple information about your brand and product",
            // "Sit back and wait for your stunning customer experience to come to life"
          ]
        },
        video: {
          videoUrl: 'https://player.vimeo.com/external/530099353.hd.mp4?s=2fd51f54e687051d715a32d46be01db6d3fac564&profile_id=169',
        }
      }
    },
    mounted() {
      if (window.innerWidth < 450){
        this.hideDesktopComponent = true;
      } else {
        this.hideDesktopComponent = false;
      }
      let that = this;
      // this.$nextTick( function() {
      //   setTimeout( function () {
      //     document.getElementById('lottie-cont').style.opacity = 0;
      //
      //     setTimeout(function() {
      //       that.isLoading = false
      //       document.getElementById('lottie-cont').style.display = "none";
      //     }, 1200)
      //   }, 1000)
      // })
    },
    created() {
      window.addEventListener("resize", this.resizeWindow);
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeWindow);
    },
    methods: {
      resizeWindow(e) {
        if (window.innerWidth < 450){
          this.hideDesktopComponent = true;
        } else {
          this.hideDesktopComponent = false;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@import "../../static/styles/settings.less";

  #home-cont {
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;

    .services-listing-container{
      background: @spaces-plus-dark-blue;
    }
  }

  #lottie-cont {
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    opacity: 1;
    z-index: 70;
  }
</style>
