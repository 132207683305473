<template>
  <div class="package-wrapper">
    <div class="package-content">
      <h1>
        Find the <span style="color: #72BF00">perfect space</span></br>
        for your brand.
      </h1>
      <div class="feature-logos">
        <img
          src="/images/packages.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
      <div class="feature-logos-mob">
        <img
          src="/images/packages-mob.png"
          alt="Feature icons"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'features',
  props: [""],
  data(){
    return{}
  },
  mounted () {

  }

}
</script>

<style lang="less" scoped>
@import "../../../static/styles/settings.less";

.package-wrapper{
  background: #E0F0C7;

  .package-content{
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 5% !important;

    @media @tablet {
      padding-bottom: 0px;
    }

    @media @mobile, @large-mobile{
      width: 92%;
      padding-left: 0 !important;
      padding-bottom: 0px;
    }
    @media @iPad, @small-desktop, @iPad-pro{
      width: 90%;
      padding-left: 0 !important;
    }

    h1{
      color: @spaces-plus-dark-blue;
      width: 100%;
      font-size: @title-font-size;
      line-height: @title-line-height;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 700;
      margin-top: 5vh;
      margin-bottom: 15vh;
      @media @mobile, @large-mobile, @tablet {
        font-size: @title-font-size-mobile;
        line-height: @title-line-height-mobile;
        margin-bottom: 10vh;
      }
    }

    p{
      color: white;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-size: @content-font-size;
      line-height: @content-line-height;
      margin-top: 30px;
      margin-bottom: 10vh;
      @media @mobile, @large-mobile{
        margin-bottom: 5vh;
      }
    }

    img{
      max-width: 100%;
      object-fit: contain;
      margin-bottom: 10vh;
    }

    .feature-logos{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @mobile, @large-mobile{
        display: none;
      }
    }
    .feature-logos-mob{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media @desktop, @large-desktop, @iPad, @tablet, @iPad-pro{
        display: none;
      }
    }
  }
}
</style>
