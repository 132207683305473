module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    class: _vm.usedOnAboutPage ? 'client-logos-container-new-custom' : 'client-logos-container-new',
    style: ({
      backgroundColor: _vm.bgColor
    })
  }, [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2)])
},staticRenderFns: [function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "logos-wrapper mx-auto"
  }, [_c('h1', {
    staticClass: "section-title"
  }, [_c('span', {
    staticStyle: {
      "color": "#72BF00"
    }
  }, [_vm._v("An established")]), _vm._v(" "), _c('br'), _vm._v("and capable partner.\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "trusted-by"
  }, [_vm._v("The Spaces+ platform is trusted having delivered a number of forward-thinking experiences to brands leading within their prospective industries. Unlike offers currently on the market, clients come to us if they are looking to shake up the competition -  your audiences don't live within a 2D world so why provide them with a flat experience?")]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-center partner-logos"
  }, [_c('img', {
    attrs: {
      "src": "/images/logos-desktop.jpg",
      "alt": "Partner logos",
      "data-aos": "fade-up",
      "data-aos-duration": "1000"
    }
  })])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "client-logo-wrapper-mobile"
  }, [_c('h1', {
    staticClass: "section-title"
  }, [_c('span', {
    staticStyle: {
      "color": "#72BF00"
    }
  }, [_vm._v("An established")]), _vm._v(" "), _c('br'), _vm._v("and capable partner.\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "trusted-by"
  }, [_vm._v("The Spaces+ platform is trusted having delivered a number of forward-thinking experiences to brands leading within their prospective industries. Unlike offers currently on the market, clients come to us if they are looking to shake up the competition -  your audiences don't live within a 2D world so why provide them with a flat experience?")])])
},function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('div', {
    staticClass: "client-logo-wrapper-mobile"
  }, [_c('div', {
    attrs: {
      "id": "client-logo-mobile-1"
    }
  }, [_c('img', {
    staticClass: "client-logo-mobile-img",
    attrs: {
      "src": "/images/logos1.jpg",
      "alt": "Clients logos"
    }
  })]), _vm._v(" "), _c('div', {
    attrs: {
      "id": "client-logo-mobile-2"
    }
  }, [_c('img', {
    staticClass: "client-logo-mobile-img",
    attrs: {
      "src": "/images/logos2.jpg",
      "alt": "Clients logos"
    }
  })])])
}]}