module.exports={render:function (){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;
  return _c('section', {
    staticClass: "video-wrapper"
  }, [_c('div', {
    staticClass: "video-container"
  }, [_c('video', {
    staticClass: "projects-video",
    attrs: {
      "muted": "",
      "playsinline": "",
      "crossorigin": "",
      "loop": true,
      "autoplay": true,
      "preload": "auto",
      "data-src": _vm.videoContent.videoUrl,
      "src": _vm.videoContent.videoUrl
    },
    domProps: {
      "muted": true
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "clearfix"
  })])
},staticRenderFns: []}